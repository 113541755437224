import { useEffect, useState } from "react";

import Input from "../../UI/Input";
import TextArea from "../../UI/TextArea";
import DividerWithLabel from "../../UI/DividerWIthLabel";
import CancelButton from "../../UI/CancelButton";
import SubmitButton from "../../UI/SubmitButton";
import SelectBox from "../../UI/SelectBox";
import DeleteButton from "../../UI/DeleteButton";
import { classNames } from "../../../utils/cssUtils";

const availableStatuses = {
  active: { id: 0, name: "Active", value: "active" },
  inactive: { id: 1, name: "Inactive", value: "inactive" },
  inactive_relevant: { id: 2, name: "Inactive (relevant)", value: "inactive_relevant" },
  negative: { id: 3, name: "Negative", value: "negative" },
};

const HealthGoalsDetails = (props) => {
  const { problem, onClose, onSubmit, onDelete } = props;

  const [form, setForm] = useState({
    name: { value: problem?.name || "", touched: false, valid: true },
    notes: { value: problem?.notes || "", touched: false, valid: true },
    status: { value: problem?.status || availableStatuses["active"].value, touched: false, valid: true },
    diagnosticCode: { value: problem?.diagnostic_code || "", touched: false, valid: true },
  });
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    if (!form.name.touched) return;

    setForm((prev) => ({ ...prev, name: { ...prev.name, valid: prev.name.value.trim().length >= 1 } }));
  }, [form.name.touched, form.name.value]);

  useEffect(() => {
    setIsFormValid(form.name.valid);
  }, [form.name.valid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: problem?.id,
      name: form.name.value,
      status: form.status.value,
      notes: form.notes.value,
      diagnostic_code: form.diagnosticCode.value,
    };

    onSubmit(data);
  };

  return (
    <div className="mx-5 mt-6 space-y-5">
      <Input
        type="text"
        name="name"
        placeholder="Type to search..."
        label="Problem Name (required)"
        value={form.name.value}
        isValid={form.name.valid}
        onChange={(val) => setForm((prev) => ({ ...prev, name: { ...prev.name, value: val, touched: true } }))}
        onBlur={() => setForm((prev) => ({ ...prev, name: { ...prev.name, touched: true } }))}
        errorMessage="Problem name cannot be blank!"
      />

      <Input
        type="text"
        name="diagnostic-code"
        placeholder="ICD-10 code"
        label="ICD-10 Code"
        value={form.diagnosticCode.value}
        onChange={(val) =>
          setForm((prev) => ({ ...prev, diagnosticCode: { ...prev.diagnosticCode, value: val, touched: true } }))
        }
        onBlur={() => setForm((prev) => ({ ...prev, diagnosticCode: { ...prev.diagnosticCode, touched: true } }))}
      />

      <div className="mt-2">
        <SelectBox
          activeValue={form.status.value ? availableStatuses[form.status.value] : availableStatuses["active"].value}
          values={Object.values(availableStatuses)}
          onChange={(val) =>
            setForm((prev) => ({ ...prev, status: { ...prev.status, value: val.value, touched: true } }))
          }
          onBlue={() => setForm((prev) => ({ ...prev, status: { ...prev.status, touched: true } }))}
        />
      </div>

      <TextArea
        name="notes"
        label="Notes"
        value={form.notes.value}
        rows={5}
        onChange={(val) => setForm((prev) => ({ ...prev, notes: { ...prev.notes, value: val, touched: true } }))}
      />

      <DividerWithLabel />

      <div className={classNames("grid grid-flow-col gap-x-3", !!problem?.id ? "grid-cols-3" : "grid-cols-2")}>
        {!!problem?.id && <DeleteButton onClick={() => onDelete(problem.id)} />}
        <CancelButton onClick={onClose} />
        <SubmitButton isValid={isFormValid} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default HealthGoalsDetails;
