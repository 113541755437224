import React from "react";

import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { PostHogProvider } from "posthog-js/react";

import "react-tooltip/dist/react-tooltip.css";
import "./index.css";

import { ApiProvider } from "./providers/ApiProvider";
import { AuthProvider } from "./providers/AuthProvider";

import { buildRoutes } from "./App";

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (!!dsn) {
  Sentry.init({
    dsn: `https://${dsn}.ingest.us.sentry.io/4506390624075776`,
    tunnel: "/sentry",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
      }),
      // Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.propatienttech\.com\/physician/],

    reactComponentAnnotation: { enabled: true },

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: "https://us.posthog.com",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <ApiProvider baseURL={process.env.REACT_APP_API_URL}>
        <AuthProvider>
          <RouterProvider router={buildRoutes()} />
        </AuthProvider>
      </ApiProvider>
    </PostHogProvider>
  </React.StrictMode>
);
