/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/20/solid";

import { ApiContext } from "../../../providers/ApiProvider";

import { calculateFutureDate, formatDate } from "../../../utils/date";

import DateInput from "../../UI/DateInput";
import LoadingSpinner from "../../UI/LoadingSpinner";

const items = [
  {
    name: "Medical History",
    description: "",
    id: "S40",
  },
  {
    name: "Lifestyle Intake Questionnaire",
    description: "",
    id: "S02",
  },
  {
    name: "Dietary Screener",
    description: "",
    id: "S31",
  },
  {
    name: "Please schedule your physical",
    description: "",
    id: "S36",
  },
  {
    name: "PHQ-9",
    description: "",
    id: "S01",
  },
  {
    name: "End of Life Questionnaire",
    description: "",
    id: "S35",
  },
];

const OnboardingSuggestions = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const [addLoading, setAddLoading] = useState({ S02: false, S35: false });
  const [assignedTools, setAssignedTools] = useState({});
  const [dueDate, setDueDate] = useState({});

  const handleAddScreeningTool = (item) => {
    setAddLoading((prev) => ({ ...prev, [item.id]: true }));

    const dueBy = dueDate[item.id] || formatDate(calculateFutureDate(2, "weeks"));

    api.client
      .post(`/patients/${patientId}/screening_tools`, { tool_id: item.id, due_by: dueBy })
      .then((resp) => {
        setAddLoading((prev) => ({ ...prev, [item.id]: false }));
        setAssignedTools((prev) => ({ ...prev, [item.id]: true }));
      })
      .catch((resp) => {
        setAddLoading((prev) => ({ ...prev, [item.id]: false }));
      });
  };

  const handleDueByChange = (e, item) => {
    setDueDate((prev) => ({ ...prev, [item.id]: e.target.value }));
  };

  return (
    <div className="mx-auto max-w-2xl">
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Consider adding the following questionnaires to begin with:
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        Your patient will get regular prompts to complete the questions, and you will get notified when a questionnaire
        is complete.
      </p>
      <ul role="list" className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
        {items.map((item) => (
          <li key={item.id}>
            <div className="relative flex items-start space-x-3 py-4">
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">{item.name}</div>
                <p className="text-sm text-gray-500">{item.description}</p>
              </div>
              <div>
                <DateInput
                  value={dueDate[item.id] || formatDate(calculateFutureDate(2, "weeks"))}
                  onChange={(e) => handleDueByChange(e, item)}
                />
              </div>
              <div className="flex-shrink-0 self-center">
                {addLoading[item.id] ? (
                  <LoadingSpinner small={true} showLoadingText={false} />
                ) : assignedTools[item.id] ? (
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-600 hover:bg-green-800">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                ) : (
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    onClick={() => handleAddScreeningTool(item)}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="mt-6 flex">
        <a
          onClick={() => setSearchParams((prev) => ({ ...prev, tab: 1, subTab: 1 }))}
          className="cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-500"
        >
          Or check out the full list
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </div>
  );
};

export default OnboardingSuggestions;
