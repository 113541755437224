import { classNames } from "../../utils/cssUtils";

const SaveButton = (props) => {
  const { onClick, disabled = false, label = "Save" } = props;

  return (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={classNames(
        "inline-flex px-4 py-2 text-sm items-center justify-center font-medium shadow-sm rounded-md text-white bg-indigo-600 ring-1 ring-indigo-400",
        "focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
        disabled ? "hover:bg-indigo-700" : "disabled:opacity-50"
      )}
    >
      {label}
    </button>
  );
};

export default SaveButton;
