/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useRef } from "react";

import { startOfWeek, eachDayOfInterval, endOfWeek, isSameDay, format } from "date-fns";

import { classNames } from "../../utils/cssUtils";
import { calculateGridRow } from "../../utils/date";

const hours = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
];

const WeeklyCalendar = (props) => {
  const { today, appointments, showAppointment } = props;

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  let firstDayOfCurrentWeek = startOfWeek(today);
  let days = eachDayOfInterval({
    start: startOfWeek(firstDayOfCurrentWeek),
    end: endOfWeek(firstDayOfCurrentWeek),
  }).map((date) => ({
    date,
  }));

  useEffect(() => {
    // Set the container scroll position based on the current time.
    // const currentMinute = new Date().getHours() * 60;

    // set container to 7am
    const currentMinute = 7 * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <>
      <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div className="flex max-w-full flex-none flex-col">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            <div className="-mr-px grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
              <div className="col-end-1 w-14" />
              {days.map((day) => (
                <div key={day.date} className="flex items-center justify-center py-3">
                  <span className={classNames(isSameDay(day.date, today) ? "flex items-baseline" : "")}>
                    {format(day.date, "EEEEEE")}{" "}
                    <span
                      className={classNames(
                        "items-center justify-center font-semibold",
                        isSameDay(day.date, today)
                          ? "flex ml-1.5 h-8 w-8 rounded-full text-white bg-indigo-600"
                          : "text-gray-900"
                      )}
                    >
                      {format(day.date, "d")}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                {hours.map((hour) => (
                  <Fragment key={hour}>
                    <div>
                      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {hour}
                      </div>
                    </div>
                    <div />
                  </Fragment>
                ))}
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{ gridTemplateRows: "1.75rem repeat(96, minmax(0, 1fr)) auto" }}
              >
                {appointments.map((appointment) => (
                  <li
                    key={appointment.id}
                    className={classNames(
                      "relative mt-px flex",
                      `col-start-${new Date(appointment.datetime).getDay() + 1}`
                    )}
                    style={{ gridRow: calculateGridRow(appointment.datetime, appointment.duration) }}
                    onClick={() => showAppointment(appointment)}
                  >
                    <a
                      href="#"
                      className={classNames(
                        "group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 px-2 text-xs leading-5 hover:bg-blue-100",
                        appointment.duration > 15 ? "pt-3" : ""
                      )}
                    >
                      <p className="font-semibold text-blue-500 group-hover:text-blue-700">
                        <time dateTime={appointment.time}>{appointment.time}</time> {appointment.type} with{" "}
                        {appointment.first_name} {appointment.last_name}
                      </p>
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyCalendar;
