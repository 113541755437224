// Strip non numbers from input
// Expect phone to be 10 digits exactly
function isPhoneValid(phone) {
  if (phone.length < 10) {
    return false;
  }

  let tmp = phone.replaceAll(" ", "");
  tmp = tmp.replaceAll(".", "");
  tmp = tmp.replaceAll("(", "");
  tmp = tmp.replaceAll(")", "");
  tmp = tmp.replaceAll("-", "");

  if (tmp.length > 13) {
    return false;
  }

  return RegExp("[1]{0,1}[0-9]{10}").test(tmp);
}

// Minimum valid email a@b.c
function isEmailValid(email) {
  if (email.length < 5) {
    return false;
  }

  const EMAIL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return RegExp(EMAIL_REGEX).test(email);
}

function isDobValid(dob) {
  if (dob === null || dob === undefined || dob.trim().length === 0) {
    return true;
  }

  if (dob.length < 10) {
    return false;
  }

  const DOB_REGEX = /^[1-2][0-9]{3}-[0-9]{2}-[0-9]{2}$/;

  return RegExp(DOB_REGEX).test(dob);
}

function isWebsiteValid(url) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

export { isPhoneValid, isEmailValid, isDobValid, isWebsiteValid };
