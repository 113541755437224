import { XMarkIcon } from "@heroicons/react/20/solid";

const Banner = (props) => {
  const { children, onDismiss } = props;

  return (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">{children}</p>
      <div className="flex flex-1 justify-end">
        <button type="button" onClick={onDismiss} className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
