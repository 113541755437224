import { useEffect, useState, useContext } from "react";

import { EnvelopeOpenIcon, UsersIcon } from "@heroicons/react/24/outline";

import { classNames } from "../utils/cssUtils";

import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";

const defaultStats = {
  total_patients: { id: 1, name: "Total Patients", stat: 0, icon: UsersIcon },
  outstanding_questionnaires: {
    id: 2,
    name: "Patients With Outstanding Questionnaires",
    stat: 0,
    icon: EnvelopeOpenIcon,
  },
  to_review: { id: 3, name: "Questionnaires To Review", stat: 0, icon: EnvelopeOpenIcon },
};

const DashboardPage = () => {
  const api = useContext(ApiContext);

  const [, setUser] = useState();
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(defaultStats);

  useEffect(() => {
    api.client
      .get("/users/current")
      .then((resp) => {
        setUser(resp.data.user);
        setOrganization(resp.data.user.organization);
      })
      .catch((resp) => {});
  }, [api.client]);

  useEffect(() => {
    api.client
      .get("/dashboard")
      .then((resp) => {
        setStats((prev) => {
          return {
            ...prev,
            total_patients: { ...prev.total_patients, stat: resp.data.total_patients },
            outstanding_questionnaires: {
              ...prev.outstanding_questionnaires,
              stat: resp.data.patients_with_outstanding_questionnaires,
            },
            to_review: { ...prev.to_review, stat: resp.data.questions_to_review_count },
          };
        });

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full bg-white shadow">
        <div className="flex justify-start px-6">
          <div className="py-6 flex items-center justify-between border-t border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <div>
                  {organization?.photo ? (
                    <div className="w-16 h-16">
                      <img className="h-16 w-16 rounded-full" src={organization?.photo} alt="" />
                    </div>
                  ) : (
                    <span className="inline-block overflow-hidden h-16 w-16 rounded-full bg-gray-100">
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>
                        </svg>
                      </svg>
                    </span>
                  )}
                </div>
                <div className="flex flex-col items-start w-full ml-5 space-y-2">
                  <h1 className="text-2xl font-bold leading-7 text-gray-900">{organization?.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mx-10">
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            Object.values(stats).map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-indigo-500 p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  <p
                    className={classNames(
                      item.changeType === "increase" ? "text-green-600" : "text-red-600",
                      "ml-2 flex items-baseline text-sm font-semibold"
                    )}
                  ></p>
                </dd>
              </div>
            ))
          )}
        </dl>
      </div>
    </div>
  );
};

export default DashboardPage;
