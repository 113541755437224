/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { addMonths, parse, startOfToday, subMonths, format, subWeeks, subDays, addDays, addWeeks } from "date-fns";

import { ApiContext } from "../providers/ApiProvider";

import DetailsSlideOver from "../components/UI/DetailsSlideOver";
import AppointmentDetails from "../components/Calendar/AppointmentDetails";

import MonthlyCalendar from "../components/Calendar/MonthlyCalendar";
import WeeklyCalendar from "../components/Calendar/WeeklyCalendar";
import DailyCalendar from "../components/Calendar/DailyCalendar";
import LoadingSpinner from "../components/UI/LoadingSpinner";

const menus = [
  { id: 0, name: "Month view", query: "monthly" },
  { id: 1, name: "Week view", query: "weekly" },
  { id: 2, name: "Day view", query: "daily" },
];

const AppointmentsPage = () => {
  const api = useContext(ApiContext);

  const [searchParams, setSearchParams] = useSearchParams();

  let startDate = "";
  if (searchParams.get("date")) {
    startDate = searchParams.get("date");
  } else {
    startDate = format(startOfToday(), "yyyy-MM-dd");
  }

  const [appointments, setAppointments] = useState([]);
  const [curView, setCurView] = useState(menus.find((m) => m.query === searchParams.get("view")) || menus[0]);
  const [curDate, setCurDate] = useState(parse(startDate, "yyyy-MM-dd", new Date()));
  const [isLoading, setIsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState({ open: false, appointment: null });

  useEffect(() => {
    setSearchParams((params) => {
      params.set("view", curView.query);
      return params;
    });
  }, [curView, setSearchParams]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set("date", format(curDate, "yyyy-MM-dd"));
      return params;
    });
  }, [curDate, setSearchParams]);

  useEffect(() => {
    setIsLoading(true);

    api.client
      .get("/appointments", {
        params: { type: curView.query, today: format(curDate, "yyyy-MM-dd") },
      })
      .then((resp) => {
        setAppointments(resp.data || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, curDate, curView]);

  const handleViewChange = (menu) => {
    setCurView(menu);
  };

  const handleSetToday = () => {
    const d = new Date();
    setCurDate(d);
  };

  const handlePrev = () => {
    setCurDate((prev) => {
      if (curView.id === 0) {
        return subMonths(prev, 1);
      } else if (curView.id === 1) {
        return subWeeks(prev, 1);
      } else if (curView.id === 2) {
        return subDays(prev, 1);
      }
    });
  };

  const handleNext = () => {
    setCurDate((prev) => {
      if (curView.id === 0) {
        return addMonths(prev, 1);
      } else if (curView.id === 1) {
        return addWeeks(prev, 1);
      } else if (curView.id === 2) {
        return addDays(prev, 1);
      }
    });
  };

  const handleNextMonth = () => {
    setCurDate((prev) => addMonths(prev, 1));
  };

  const handlePrevMonth = () => {
    setCurDate((prev) => subMonths(prev, 1));
  };

  const handleDateSelect = (date) => {
    setCurDate(date);
  };

  const appointmentTitle = (appointment) => {
    if (!appointment) return "";

    let title = appointment.type;

    if (!!appointment.patient) {
      title += ` with ${appointment.patient.first_name}`;

      if (!!appointment.patient.preferred_name) {
        title += ` "${appointment.patient.preferred_name}"`;
      }

      title += ` ${appointment.patient.last_name}`;
    } else if (!!appointment.first_name && !!appointment.last_name) {
      title += ` with ${appointment.first_name} ${appointment.last_name}`;
    }

    return title;
  };

  const handleShowAppointment = (appointment) => {
    setShowDetails({ open: true, appointment });
  };

  const handleChangeAppointment = (appointment) => {
    setAppointments((prev) => {
      const res = prev.filter((a) => a.id !== appointment.id);
      return [...res, appointment];
    });
    setShowDetails({ open: false, appointment: null });
  };

  const handleAppointmentCancel = (appointment) => {
    setAppointments((prev) => {
      const res = prev.filter((a) => a.id !== appointment.id);
      return [...res];
    });
    setShowDetails({ open: false, appointment: null });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex w-full min-h-[768px] mx-auto">
          <div className="w-full mt-6 mx-auto px-8 flex flex-col">
            <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                <time dateTime={curDate.date}>{format(curDate, "MMMM yyyy")}</time>
              </h1>
              <div className="flex items-center">
                <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                  <button
                    type="button"
                    onClick={handlePrev}
                    className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={handleSetToday}
                    className="border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                  >
                    Today
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="md:ml-4 md:flex md:items-center">
                  <Menu as="div" className="relative">
                    <MenuButton
                      type="button"
                      className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {curView.name}
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </MenuButton>

                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <div className="py-1">
                        {menus.map((menu) => (
                          <MenuItem key={menu.id}>
                            <a
                              onClick={() => handleViewChange(menu)}
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              {menu.name}
                            </a>
                          </MenuItem>
                        ))}
                      </div>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </header>

            {curView.id === 0 && (
              <MonthlyCalendar today={curDate} appointments={appointments} showAppointment={handleShowAppointment} />
            )}
            {curView.id === 1 && (
              <WeeklyCalendar today={curDate} appointments={appointments} showAppointment={handleShowAppointment} />
            )}
            {curView.id === 2 && (
              <DailyCalendar
                selectedDay={curDate}
                onNextMonth={handleNextMonth}
                onPrevMonth={handlePrevMonth}
                onDateSelect={handleDateSelect}
                appointments={appointments}
                showAppointment={handleShowAppointment}
              />
            )}
          </div>

          <DetailsSlideOver
            open={showDetails.open}
            onClose={() => setShowDetails({ open: false, appointment: null })}
            title={appointmentTitle(showDetails.appointment)}
          >
            <AppointmentDetails
              appointment={showDetails.appointment}
              onChange={handleChangeAppointment}
              onCancel={handleAppointmentCancel}
            />
          </DetailsSlideOver>
        </div>
      )}
    </>
  );
};

export default AppointmentsPage;
