import { useEffect, useState } from "react";

import { useDebounce } from "@uidotdev/usehooks";

import { EditorContent, useEditor } from "@tiptap/react";

import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import ListKeymap from "@tiptap/extension-list-keymap";
import StarterKit from "@tiptap/starter-kit";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";

import TopMenu from "./TopMenu";
import { TextSelection } from "@tiptap/pm/state";

const RichEditor = (props) => {
  const { onChange } = props;

  const [content, setContent] = useState(props.content);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!editor) return;

    const { from, to } = editor.state.selection;

    if (props.content === null || (typeof props.content === "object" && Object.keys(props.content).length === 0)) {
      editor.commands.setContent("");
    } else {
      editor.commands.setContent(props.content);
    }

    const newFrom = Math.min(from, editor.state.doc.content.size);
    const newTo = Math.min(to, editor.state.doc.content.size);
    const textSelection = new TextSelection(editor.state.doc.resolve(newFrom), editor.state.doc.resolve(newTo));
    editor.view.dispatch(editor.state.tr.setSelection(textSelection));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);

  const debounce = useDebounce(content, 500);

  useEffect(() => {
    if (firstRender || content === props.content) {
      setFirstRender(false);
      return;
    }

    if (debounce) {
      onChange(debounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const extensions = [
    Color,
    Highlight.configure({
      multicolor: true,
    }),
    Image,
    Link.configure({
      protocols: ["mailto"],
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
    }),
    ListKeymap,
    StarterKit,
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    TextStyle,
    Underline,
  ];

  const editorProps = {
    attributes: {
      class: "m-5 focus:outline-none max-h-72 prose max-w-none overflow-x-auto",
    },
  };

  const handleUpdate = (editor) => {
    setContent(editor.getJSON());
  };

  const editor = useEditor({
    extensions,
    content,
    editorProps,
    onUpdate: ({ editor }) => handleUpdate(editor),
    enableContentCheck: true,
    onContentError: (args) => {
      console.error(args.error);
      args.disableCollaboration();
    },
  });

  return (
    <div>
      <TopMenu editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default RichEditor;
