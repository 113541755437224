/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState } from "react";

import {
  RiArticleFill,
  RiArticleLine,
  RiDeleteBinFill,
  RiDeleteBinLine,
  RiEdit2Fill,
  RiEdit2Line,
  RiExternalLinkLine,
} from "@remixicon/react";

import DetailsSlideOver from "../../UI/DetailsSlideOver";

const RecommendationListItem = (props) => {
  const { recommendation, onShowEdit, onDelete } = props;

  const [hoverArticle, setHoverArticle] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);
  const [hoverEdit, setHoverEdit] = useState(false);
  const [showResources, setShowResources] = useState({ show: false, resources: [] });

  const handleEdit = (e) => {
    e.preventDefault();
    onShowEdit();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete(recommendation.id);
  };

  return (
    <>
      <li className="relative flex items-center justify-between px-2 py-2 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200">
        <div className="min-w-0 mr-2">
          <div className="flex items-start gap-x-3">
            <div className="flex text-sm leading-6 text-slate-900 items-center space-x-2">
              <div>{recommendation?.content || recommendation?.recommendation?.content}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <span
            onMouseEnter={() => setHoverArticle(true)}
            onMouseLeave={() => setHoverArticle(false)}
            onClick={() => setShowResources({ show: true, resources: recommendation.recommendation?.resources || [] })}
            className="cursor-pointer text-slate-800"
          >
            {hoverArticle ? <RiArticleFill className="h-5 w-5" /> : <RiArticleLine className="h-5 w-5" />}
          </span>
          <span
            onMouseEnter={() => setHoverDelete(true)}
            onMouseLeave={() => setHoverDelete(false)}
            onClick={handleDelete}
            className="cursor-pointer text-red-800"
          >
            {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
          </span>
          <span
            onMouseEnter={() => setHoverEdit(true)}
            onMouseLeave={() => setHoverEdit(false)}
            onClick={handleEdit}
            className="cursor-pointer text-indigo-800"
          >
            {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
          </span>
        </div>
      </li>

      {showResources.show && (
        <DetailsSlideOver
          open={showResources.show}
          onClose={() => setShowResources({ show: false, resources: null })}
          title="Action Resources"
        >
          <ul
            role="list"
            className="mx-5 my-5 divide-y divide-gray-100 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
          >
            {showResources.resources.map((r) => (
              <li key={r.content} className="flex justify-between px-4 py-5 cursor-pointer">
                <div className="flex min-w-0 gap-x-4">
                  <RiExternalLinkLine className="w-5 h-5" />
                  <a href={r.link} className="cursor-pointer" target="_blank" rel="noreferrer">
                    {r.content}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </DetailsSlideOver>
      )}
    </>
  );
};

export default RecommendationListItem;
