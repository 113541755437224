import { useContext, useState } from "react";

import { format } from "date-fns";

import { ApiContext } from "../../providers/ApiProvider";

import LoadingSpinner from "../UI/LoadingSpinner";
import RescheduleAppointment from "./RescheduleAppointment";
import CancelAppointment from "./CancelAppointment";

const AppointmentDetails = (props) => {
  const { onCancel, onChange } = props;

  const api = useContext(ApiContext);

  const [appointment] = useState(props.appointment || {});
  const [isLoading, setIsLoading] = useState(false);
  const [patient] = useState(appointment?.patient || {});
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);

  const formatDuration = (duration) => {
    const h = Math.floor(duration / 60);
    const m = duration % 60;

    if (h === 0) return `${m} minutes`;

    return `${h} hours ${m} minutes`;
  };

  const formatPatientName = () => {
    if (!!appointment.patient) {
      return `${appointment.patient.first_name} "${appointment.patient.preferred_name}" ${appointment.patient.last_name}`;
    } else {
      return `New Patient ${appointment.first_name || ""} ${appointment.last_name || ""}`;
    }
  };

  const handleSubmitCancel = (note) => {
    setIsLoading(true);

    api.client
      .delete(`/appointments/${appointment.id}`, { data: { note } })
      .then((resp) => {
        setShowCancel(false);
        setIsLoading(false);
        onCancel(appointment);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleReschedule = (datetime) => {
    setIsLoading(true);

    api.client
      .put(`/appointments/${appointment.id}`, { datetime })
      .then((resp) => {
        setShowReschedule(false);
        setIsLoading(false);
        onChange(resp.data);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="divide-y divide-gray-200">
          <div className="pb-6">
            <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
              <div className="-m-1 flex">
                <div className="group inline-flex overflow-hidden rounded-lg border-4 border-white">
                  <div className="relative">
                    {patient.photo_url ? (
                      <img
                        className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                        src={patient.photo_url}
                        alt={`${patient.first_name} ${patient.last_name}`}
                      />
                    ) : (
                      <span className="inline-block overflow-hidden h-24 w-24 sm:h-40 sm:w-40 lg:h-48 lg:w-48 bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:ml-6 sm:flex-1 sm:flex sm:flex-col sm:justify-between sm:mt-0">
                {!showCancel && !showReschedule && (
                  <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                    <button
                      type="button"
                      onClick={() => setShowCancel(true)}
                      className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                    >
                      Cancel Appointment
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowReschedule(true)}
                      className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
                    >
                      Reschedule
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 py-5 sm:px-0 sm:py-0">
              <div className="mx-5 my-5 space-y-8">
                <div>
                  {showReschedule && (
                    <div className="my-5 py-3">
                      <RescheduleAppointment
                        appointment={appointment}
                        onClose={() => setShowReschedule(false)}
                        onReschedule={handleReschedule}
                      />
                    </div>
                  )}
                  {showCancel && (
                    <div className="my-5 py-3">
                      <CancelAppointment onCancel={() => setShowCancel(false)} onSubmit={handleSubmitCancel} />
                    </div>
                  )}
                  {!showCancel && !showReschedule && (
                    <>
                      <h3 className="font-medium text-gray-900">Appointment Details</h3>
                      <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Date</dt>
                          <dd className="text-gray-900">{format(appointment?.datetime, "MMMM d, yyyy")}</dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Time</dt>
                          <dd className="text-gray-900">{format(appointment?.datetime, "h:mm a")} </dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Duration</dt>
                          <dd className="text-gray-900">{formatDuration(appointment?.duration)}</dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Appointment Type</dt>
                          <dd className="text-gray-900">{appointment?.type}</dd>
                        </div>
                      </dl>
                    </>
                  )}
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Patient Info</h3>
                  <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Name</dt>
                      <dd className="text-gray-900">{formatPatientName()}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Email</dt>
                      <dd className="text-gray-900">{appointment?.patient?.email || appointment?.email || ""}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Phone</dt>
                      <dd className="text-gray-900">{appointment?.patient?.phone || appointment?.phone || ""}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentDetails;
