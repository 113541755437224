/* eslint-disable jsx-a11y/no-redundant-roles */

import { useContext, useEffect, useState } from "react";

import { Tooltip } from "react-tooltip";

import { toast } from "sonner";

import { RiAddCircleLine, RiArrowDownSLine, RiArrowRightSLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";
import { MedicalProblemContext } from "../../../providers/MedicalProblemProvider";

import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";

import MedicalProblemDetails from "./MedicalProblemDetails";
import MedicalProblemItem from "./MedicalProblemItem";

const MedicalProblemList = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const [showDetails, setShowDetails] = useState({ show: false, problem: null });
  const [showList, setShowList] = useState(true);
  const [isDeleting, setIsDeleting] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { problems, addProblem, removeProblem, updateProblem, bulkAddProblems } = useContext(MedicalProblemContext);

  useEffect(() => {
    setIsLoading(true);

    api.client
      .get(`/patients/${patientId}/medical_history`)
      .then((resp) => {
        bulkAddProblems(resp.data.medical_problems || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleSubmit = (data) => {
    if (data.id) {
      api.client
        .put(`/patients/${patientId}/medical_history/${data.id}`, { medical_problem: data })
        .then((resp) => {
          updateProblem(resp.data.medical_problem);
          toast.success("Medical Problem updated successfully.");
          setShowDetails({ show: false, problem: null });
        })
        .catch((resp) => {
          toast.error("Failed to update medical condition.");
        });
    } else {
      api.client
        .post(`/patients/${patientId}/medical_history`, { medical_problem: data })
        .then((resp) => {
          addProblem(resp.data.medical_problem);
          toast.success("Medical Problem added successfully.");
          setShowDetails({ show: false, problem: null });
        })
        .catch((resp) => {
          toast.error("Failed to add medical condition.");
        });
    }
  };

  const handleDelete = (id) => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));
    api.client
      .delete(`/patients/${patientId}/medical_history/${id}`)
      .then((resp) => {
        removeProblem(id);
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
        toast.success("Medical Problem deleted successfully.");
      })
      .catch((resp) => {
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
        toast.error("Failed to delete medical condition.");
      });
  };

  const toggleShowList = () => {
    setShowList((prev) => !prev);
  };

  return (
    <>
      <div className="rounded-lg overflow-hidden shadow">
        <div className="border-b border-slate-200 bg-slate-50 py-3 px-5">
          <div className="flex items-center justify-between flex-nowrap">
            <div className="flex gap-x-3 items-center">
              <h3 className="text-lg font-medium leading-6 text-slate-900">Medical Problem List</h3>
              <span onClick={toggleShowList} className="cursor-pointer text-indigo-800">
                {showList ? <RiArrowDownSLine size={20} /> : <RiArrowRightSLine size={20} />}
              </span>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowDetails({ show: true, problem: null })}
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset hover:ring-2 ring-indigo-300 hover:bg-indigo-50"
              >
                <RiAddCircleLine className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
        {showList && (
          <>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <ul role="list" className="bg-white p-5 gap-x-2 gap-y-2 grid grid-cols-3 overflow-y-auto">
                {problems.map((problem) => (
                  <MedicalProblemItem
                    patientId={patientId}
                    problem={problem}
                    key={problem.id}
                    onDelete={handleDelete}
                    onShowEdit={() => setShowDetails({ show: true, problem })}
                    onUpdate={updateProblem}
                    isDeleting={isDeleting[problem.id]}
                  />
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {showDetails.show && (
        <DetailsSlideOver
          open={showDetails.show}
          onClose={() => setShowDetails({ show: false, problem: null })}
          title={`${!!showDetails.problem ? "Edit" : "Add"} Medical Problem`}
        >
          <MedicalProblemDetails
            problem={showDetails.problem}
            onSubmit={handleSubmit}
            onClose={() => setShowDetails({ show: false, problem: null })}
            onDelete={handleDelete}
          />
        </DetailsSlideOver>
      )}
      <Tooltip id="med-problem" />
    </>
  );
};

export default MedicalProblemList;
