/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { ApiContext } from "../../../providers/ApiProvider";
import { RecommendationContext } from "../../../providers/RecommendationProvider";

import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";

import RecommendationDetails from "./RecommendationDetails";
import RecommendationListItem from "./RecommendationListItem";
import { RiEditBoxLine } from "@remixicon/react";

const RecommendationList = (props) => {
  const api = useContext(ApiContext);
  const { patientId } = props;

  const { recommendations, addRecommendation, removeRecommendation, updateRecommendation, bulkAddRecommendations } =
    useContext(RecommendationContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showAddRecommendation, setShowAddRecommendation] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/recommendations`)
      .then((resp) => {
        bulkAddRecommendations(resp.data.patients_recommendations);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleAdd = (data) => {
    const params = {};
    if (data.recommendation_id) {
      params.recommendation_id = data.recommendation_id;
    } else {
      params.recommendation_id = data.id;
      params.content = data.content;
    }

    api.client.post(`/patients/${patientId}/recommendations`, params).then((resp) => {
      if (resp.status === 204) {
        toast.warning("Action already added to patient.");
      } else {
        addRecommendation(resp.data.patients_recommendation);
        // NOTE don't hide the details screen to allow editing multiple
      }
    });
  };

  const handleUpdate = (data) => {
    api.client
      .put(`/patients/${patientId}/recommendations/${data.id}`, {
        content: data.content,
      })
      .then((resp) => {
        updateRecommendation(resp.data.patients_recommendation);
        // NOTE don't hide the details screen to allow editing multiple
      });
  };

  const handleDelete = (id) => {
    api.client.delete(`/patients/${patientId}/recommendations/${id}`).then((resp) => {
      removeRecommendation(id);
      // NOTE don't hide the details screen to allow editing multiple
    });
  };

  return (
    <>
      <div className="flex flex-wrap px-5 items-center justify-between border-b border-slate-200">
        <div className="flex items-center space-x-3">
          <h2 className="text-lg font-medium text-slate-900 border-none">Action Plan</h2>
        </div>
        <div className="flex items-center my-3">
          <button
            type="button"
            onClick={() => setShowAddRecommendation(true)}
            className="relative inline-flex items-center gap-x-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-indigo-300 hover:bg-indigo-50"
          >
            <RiEditBoxLine className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="m-3">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ul className="divide-y divide-slate-100 max-h-[300px] overflow-y-auto overflow-x-hidden">
            {recommendations.map((recommendation, idx) => (
              <RecommendationListItem
                key={recommendation.id}
                recommendation={recommendation}
                onShowEdit={() => setShowAddRecommendation(true)}
                onDelete={handleDelete}
              />
            ))}
          </ul>
        )}
      </div>
      {showAddRecommendation && (
        <DetailsSlideOver
          open={showAddRecommendation}
          onClose={() => setShowAddRecommendation(false)}
          title="Edit Actions"
        >
          <RecommendationDetails
            recommendations={recommendations}
            onClose={() => setShowAddRecommendation(false)}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            patientId={patientId}
          />
        </DetailsSlideOver>
      )}
    </>
  );
};

export default RecommendationList;
