function getAnswer(question, toCopy = false) {
  const answer = question.answer;

  if (answer === undefined || answer === null) {
    return "";
  }

  if (question.typeof === 0) {
    // multi
    if (question.multiple) {
      if (toCopy) {
        return answer.options.map((opt) => `* ${opt.content}`).join("\n");
      } else {
        return (
          <ul className="list-disc">
            {answer.options.map((opt) => (
              <li key={opt.id}>{opt.content}</li>
            ))}
          </ul>
        );
      }
    } else {
      return answer.options[0]?.content;
    }
  } else if (question.typeof === 4) {
    // date
    const d = new Date(answer.value);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return d.toLocaleDateString("en-US", options);
  } else if (question.typeof === 2) {
    // numeric
    if (question.external_id === "Q103") {
      const total = Number.parseInt(answer.value);
      const feet = Math.floor(total / 12);
      const inches = total % 12;

      return `${feet} ft ${inches} in`;
    } else if (question.external_id === "Q107") {
      return `${Number.parseFloat(answer.value)} lb`;
    } else {
      return `${Number.parseFloat(answer.value).toFixed(2)} ${question.units}`;
    }
  } else {
    return answer.value;
  }
}

function copyQuestionsToClipboard(tool, questions) {
  let content = `${tool.name}\n\n`;

  questions.forEach((question) => {
    const answer = getAnswer(question, true);

    // Skip questions with no answer, assuming it's a conditional question
    // that was not triggered.
    if (answer !== "") {
      content = content.concat(`${question.content}\n`);
      content = content.concat(`${answer}\n`);
      content = content.concat("--------------------------------------------------------------------------------\n");
    }
  });

  content = content.concat("\npowered by Pro-Patient Tech");

  navigator.clipboard.writeText(content);
}

export { copyQuestionsToClipboard, getAnswer };
