import { useEffect, useState } from "react";

import CancelButton from "../../UI/CancelButton";
import DeleteButton from "../../UI/DeleteButton";
import Input from "../../UI/Input";
import SaveButton from "../../UI/SaveButton";

const EditRecommendationForm = (props) => {
  const { recommendation, onChange, onClose, onDelete } = props;

  const [content, setContent] = useState({
    value: recommendation?.content || recommendation?.recommendation?.content || "",
    error: false,
    touched: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!content.touched) return;

    setContent((prev) => {
      return {
        ...prev,
        error: prev.value.trim().length < 2,
      };
    });
  }, [content.touched, content.value]);

  useEffect(() => {
    setIsFormValid(!content.error);
  }, [content.error]);

  const handleSubmit = () => {
    if (content.value?.length > 0) {
      onChange({ ...recommendation, content: content.value });
    } else {
      onChange({ ...recommendation });
    }

    if (recommendation == null) {
      setContent((prev) => ({ ...prev, value: "", touched: false }));
    }
  };

  const handleDelete = () => {
    onDelete(recommendation.id);
  };

  return (
    <div className="px-4">
      <div className="mx-5 my-5">
        <div className="mt-3">
          <Input
            type="text"
            name="content"
            value={content.value}
            placeholder="Recommendation content"
            onChange={(val) => setContent((prev) => ({ ...prev, value: val, touched: true }))}
            onBlur={() => setContent((prev) => ({ ...prev, touched: true }))}
            isValid={!content.error}
            errorMessage="Recommendation content is required!"
          />
        </div>
        <div className="px-4 py-4">
          <div className="flex justify-end space-x-3">
            {onDelete !== undefined && onDelete !== null && <DeleteButton onClick={handleDelete} />}
            <CancelButton onClick={onClose} />
            <SaveButton onClick={handleSubmit} disabled={!isFormValid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRecommendationForm;
