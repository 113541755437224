import { createContext, useCallback, useState } from "react";

const MedicalProblemContext = createContext({
  recommendations: [],
  addProblem: (recommendation) => {},
  removeProblem: (recommendationId) => {},
  updateProblem: (recommendation) => {},
  bulkAddProblems: (data) => {},
});

const MedicalProblemProvider = ({ children }) => {
  const [problems, setProblems] = useState([]);

  const addProblem = useCallback((problem) => {
    setProblems((prev) => {
      return [...prev, problem];
    });
  }, []);

  const removeProblem = useCallback((problemId) => {
    setProblems((prev) => {
      const res = prev.filter((problem) => problem.id !== problemId);
      return [...res];
    });
  }, []);

  const updateProblem = useCallback((problem) => {
    setProblems((prev) => {
      const res = prev.map((g) => {
        if (g.id === problem.id) {
          return problem;
        } else {
          return g;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAddProblems = useCallback((data) => {
    setProblems(data);
  }, []);

  const contextValue = {
    problems,
    addProblem: useCallback((problem) => addProblem(problem), [addProblem]),
    removeProblem: useCallback((problemId) => removeProblem(problemId), [removeProblem]),
    updateProblem: useCallback((problem) => updateProblem(problem), [updateProblem]),
    bulkAddProblems: useCallback((data) => bulkAddProblems(data), [bulkAddProblems]),
  };

  return <MedicalProblemContext.Provider value={contextValue}>{children}</MedicalProblemContext.Provider>;
};

export { MedicalProblemContext, MedicalProblemProvider };
