/* eslint-disable jsx-a11y/no-redundant-roles */
import AnonTopNav from "../components/Layout/AnonTopNav";
import Footer from "../components/UI/Footer";

const PrivacyPage = () => {
  return (
    <div className="bg-white">
      <AnonTopNav />
      <main>
        <div className="mx-10">
          <h1 className="text-3xl font-semibold w-full text-center my-5">PRIVACY POLICY</h1>
          <p className="px-5 py-6 text-sm">Last modified: February 20, 2024</p>

          <p className="px-5 py-6">
            Pro-Patient Tech, Inc. ("Company" or "We") respect your privacy and are committed to protecting it through
            our compliance with this policy.
          </p>

          <p className="px-5 py-6">
            This policy describes the types of information we may collect from you or that you may provide when you
            visit the website <a href="https://propatienttech.com">propatienttech.com</a> (our "Website") and our
            practices for collecting, using, maintaining, protecting, and disclosing that information.
          </p>

          <p className="px-5 py-6">This policy applies to information we collect:</p>
          <ul role="list" className="list-disc px-5 py-3">
            <li>On this Website.</li>
            <li>In email, text, and other electronic messages between you and this Website.</li>
          </ul>

          <p className="px-5 py-6">It does not apply to information collected by:</p>
          <ul role="list" className="list-disc px-5 py-3">
            <li>
              us offline or through any other means, including on any other website operated by Company or any third
              party; or
            </li>
            <li>
              any third party, including through any application or content (including advertising) that may link to or
              be accessible from or on the Website.
            </li>
          </ul>

          <p className="px-5 py-6">
            Please read this policy carefully to understand our policies and practices regarding your information and
            how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
            Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from
            time to time (see "Changes to Our Privacy Policy"). Your continued use of this Website after we make changes
            is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </p>

          <h2 className="text-lg font-medium w-full">Children Under the Age of 13</h2>

          <p className="px-5 py-6">
            Our Website is not intended for children under 13 years of age. No one under age 13 may provide any
            information to the Website. We do not knowingly collect personal information from children under 13. If you
            are under 13, do not use the Website. If we learn we have collected or received personal information from a
            child under 13 without verification of parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 13, please contact us at
            <a href="mailto:info@propatienttech.com">info@propatienttech.com</a>.
          </p>

          <h2 className="text-lg font-medium w-full">Information We Collect About You and How We Collect It</h2>

          <p className="px-5 py-6">
            We collect several types of information from and about users of our Website, including information about
            your internet connection, the equipment you use to access our Website and usage details. We collect this
            information automatically as you navigate through the site. Information collected automatically may include
            usage details, IP addresses, and information collected through cookies, web beacons, and other tracking
            technologies.
          </p>

          <h3>Information We Collect Through Automatic Data Collection Technologies</h3>

          <p className="px-5 py-6">
            As you navigate through and interact with our Website, we may use automatic data collection technologies to
            collect certain information about your equipment, browsing actions, and patterns, including:
          </p>
          <ul role="list" className="list-disc px-5 py-3">
            <li>
              Details of your visits to our Website, including traffic data, location data, logs, and other
              communication data and the resources that you access and use on the Website.
            </li>
            <li>
              Information about your computer and internet connection, including your IP address, operating system, and
              browser type.
            </li>
          </ul>

          <p className="px-5 py-6">
            The information we collect automatically is statistical data and does not include personal information.
          </p>

          <p className="px-5 py-6">The technologies we use for this automatic data collection may include:</p>
          <ul role="list" className="list-disc px-5 py-3">
            <li>
              Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may
              refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you
              select this setting you may be unable to access certain parts of our Website. Unless you have adjusted
              your browser setting so that it will refuse cookies, our system will issue cookies when you direct your
              browser to our Website.
            </li>
            <li>
              Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect
              and store information about your preferences and navigation to, from, and on our Website. Flash cookies
              are not managed by the same browser settings as are used for browser cookies.
            </li>
            <li>
              Web Beacons. Pages of our Website may contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users
              who have visited those pages or and for other related website statistics (for example, recording the
              popularity of certain website content and verifying system and server integrity).
            </li>
          </ul>

          <h2 className="text-lg font-medium w-full">How We Use Your Information</h2>

          <p className="px-5 py-6">
            We use information that we collect about you or that you provide to us, including any personal information
            to present our Website and its contents to you.
          </p>

          <h2 className="text-lg font-medium w-full">Disclosure of Your Information</h2>

          <p className="px-5 py-6">We may disclose aggregated information about our users without restriction.</p>

          <p className="px-5 py-6">
            We may disclose your personal information to comply with any court order, law, or legal process, including
            to respond to any government or regulatory request.
          </p>

          <h2 className="text-lg font-medium w-full">Choices About How We Use and Disclose Your Information</h2>

          <p className="px-5 py-6">
            We strive to provide you with choices regarding the personal information you provide to us. We have created
            mechanisms to provide you with the following control over your information:
          </p>

          <p className="px-5 py-6">
            Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to
            alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the
            Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts
            of this site may then be inaccessible or not function properly.
          </p>

          <p className="px-5 py-6">
            We do not control third parties' collection or use of your information to serve interest-based advertising.
            However, these third parties may provide you with ways to choose not to have your information collected or
            used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
            Initiative ("NAI") on the NAI's website.
          </p>

          <h2 className="text-lg font-medium w-full">Your Delaware Privacy Rights</h2>

          <h2 className="text-lg font-medium w-full">Data Security</h2>

          <p className="px-5 py-6">
            We have implemented measures designed to secure your personal information from accidental loss and from
            unauthorized access, use, alteration, and disclosure. Unfortunately, the transmission of information via the
            internet is not completely secure. Although we do our best to protect your personal information, we cannot
            guarantee the security of your personal information transmitted to our Website. Any transmission of personal
            information is at your own risk. We are not responsible for circumvention of any privacy settings or
            security measures contained on the Website.
          </p>

          <h2 className="text-lg font-medium w-full">Changes to Our Privacy Policy</h2>

          <p className="px-5 py-6">
            It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy
            was last revised is identified at the top of the page. You are responsible for periodically visiting our
            Website and this privacy policy to check for any changes.
          </p>

          <h2 className="text-lg font-medium w-full">Contact Information</h2>

          <p className="px-5 py-6">
            To ask questions or comment about this privacy policy and our privacy practices, contact us at:
          </p>

          <p className="px-5 py-6">
            <a href="mailto:info@propatienttech.com">info@propatienttech.com</a>
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
