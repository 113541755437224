/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../../providers/ApiProvider";

import LoadingSpinner from "../../UI/LoadingSpinner";
import Paginator from "../../UI/Paginator";
import SearchCardHeader from "../../UI/SearchCardHeader";
import StackedList from "../../UI/StackedList";

import CustomizeTemplateRecommendation from "./CustomizeTemplateRecommendation";

const AddRecommendationFromTemplate = (props) => {
  const { onAdd, patientId } = props;

  const api = useContext(ApiContext);

  const perPage = 10;

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    api.client
      .get("/recommendations", {
        params: { page: page, per_page: perPage, query: searchQuery, patient_id: patientId },
      })
      .then((resp) => {
        setRecommendations(resp.data.recommendations);
        setTotalItems(resp.data.meta.total);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, page, patientId, searchQuery]);

  const handleGoToPage = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (val) => {
    setPage(1);
    setSearchQuery(val);
  };

  const buildItemElement = (recommendation) => {
    return <CustomizeTemplateRecommendation recommendation={recommendation} onChange={onAdd} />;
  };

  return (
    <div className="mx-auto max-w-7xl px-2">
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6 flex">
          <SearchCardHeader onSearch={handleSearch} query={searchQuery} showButton={false} />
        </div>
        <div className="px-4 py-5">
          {isLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <StackedList items={recommendations} buildItem={buildItemElement} />
          )}
        </div>
        <div className="px-4 py-4 sm:px-6">
          <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} pageSize={perPage} />
        </div>
      </div>
    </div>
  );
};

export default AddRecommendationFromTemplate;
