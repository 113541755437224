import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../utils/cssUtils";

const navigation = [
  {
    id: 0,
    name: "For Patients",
    href: "https://caspiahealth.com/",
    target: "_blank",
  },
  {
    id: 1,
    name: "Pricing",
    href: "#pricing-block",
    target: "_self",
  },
  {
    id: 2,
    name: "For Clinicians",
    href: "#clinicians-block",
    target: "_self",
  },
  { id: 3, name: "Sign In", href: "/login", target: "_self" },
  { id: 4, name: "Register", href: "/register", target: "_self" },
];

const AnonTopNav = () => {
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="w-full flex flex-1 justify-center md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img
                      className="h-auto w-64"
                      src="/images/logo_text_white.png"
                      alt="Pro-Patient Tech"
                    />
                  </a>
                </div>
                <div className="w-full hidden md:ml-6 md:block items-center">
                  <div className="mt-4 flex space-x-4 max-h-full place-content-end">
                    {navigation.map((item) => (
                      <div key={item.id}>
                        <a
                          href={item.href}
                          className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                          target={item.target}
                        >
                          {item.name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.id}
                  as="a"
                  href={item.href}
                  target={item.target}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AnonTopNav;
