import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../../providers/ApiProvider";
import { RecommendationContext } from "../../../providers/RecommendationProvider";

import { formatDate, calculateFutureDate } from "../../../utils/date";

import DateInput from "../../UI/DateInput";
import LoadingSpinner from "../../UI/LoadingSpinner";

function buildRecommendations(added, available) {
  if (added === null || added.length === 0) return available;
  if (!available || available.length === 0) return [];

  const ids = added.map((r) => r.recommendation?.id).filter((id) => id !== null);
  return available.filter((r) => !ids.includes(r.id));
}

const Recommendations = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const { recommendations: addedRecommendations, addRecommendation } = useContext(RecommendationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [tools, setTools] = useState(props.tools);
  const [recommendations, setRecommendations] = useState([]);
  const [toolDueBy, setToolDueBy] = useState();

  useEffect(() => {
    setRecommendations(buildRecommendations(addedRecommendations, props.recommendations));
  }, [addedRecommendations, props.recommendations]);

  const handleAssign = (item) => {
    if (item.content) {
      handleAssignRecommendation(item);
    } else {
      handleAssignScreeningTool(item);
    }
  };

  const handleAssignRecommendation = (recommendation) => {
    setIsLoading(true);
    api.client
      .post(`/patients/${patientId}/recommendations`, {
        recommendation_id: recommendation.id,
      })
      .then((resp) => {
        const pr = resp.data.patients_recommendation;
        const id = pr.recommendation.id;
        setRecommendations((prev) => {
          const res = prev.filter((r) => r.id !== id);
          return [...res];
        });
        addRecommendation(pr);

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleAssignScreeningTool = (tool) => {
    setIsLoading(true);

    const dueBy = toolDueBy || formatDate(calculateFutureDate(2, "weeks"));

    api.client
      .post(`/patients/${patientId}/screening_tools`, {
        screening_tool_id: tool.id,
        due_by: dueBy,
      })
      .then((resp) => {
        const id = resp.data.patient_screening_tool.screening_tool.id;
        setTools((prev) => {
          const res = prev.filter((t) => t.id !== id);
          return [...res];
        });

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const buildScreeningTool = (tool) => {
    return (
      <div className="flex items-center justify-between">
        <div>{tool.name}</div>
        <DateInput
          value={formatDate(toolDueBy || calculateFutureDate(2, "weeks"))}
          onChange={(e) => setToolDueBy(e.target.value)}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ul className="divide-y divide-slate-200 ">
          {recommendations.concat(tools).map((item, idx) => (
            <li key={idx} className="relative flex items-center justify-between px-2 py-2">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <div className="flex text-sm font-semibold leading-6 text-slate-900 items-center space-x-2">
                    <div>{item?.content ? item.content : buildScreeningTool(item)}</div>
                  </div>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-slate-500"></div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  type="button"
                  onClick={() => handleAssign(item)}
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-800 shadow-sm ring-1 ring-slate-300 hover:bg-indigo-50 hover:ring-slate-500 focus:outline-none"
                >
                  Assign
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Recommendations;
