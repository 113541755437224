import { useState } from "react";

import { RiDeleteBinLine, RiEdit2Line, RiEdit2Fill, RiDeleteBinFill } from "@remixicon/react";

const HealthGoalListItem = (props) => {
  const { goal, onDelete, onShowEdit, isDeleting } = props;

  const [hoverEdit, setHoverEdit] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);

  return (
    <li className="relative flex flex-col px-2 py-2 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200">
      <div className="flex items-center justify-between w-full">
        {isDeleting && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div className="flex items-center">
              <span className="text-base mr-4 -ml-24">Loading</span>
              <svg
                className="animate-spin h-8 w-8 text-slate-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        )}
        <div className="min-w-0">
          <div className="flex items-start gap-x-3">
            <div className="flex text-lg font-semibold leading-6 text-slate-900 items-center space-x-2">
              <div>{goal.content || goal.health_goal.content}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <span
            onMouseEnter={() => setHoverDelete(true)}
            onMouseLeave={() => setHoverDelete(false)}
            onClick={() => onDelete(goal.id)}
            className="cursor-pointer text-red-800"
          >
            {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
          </span>
          <span
            onMouseEnter={() => setHoverEdit(true)}
            onMouseLeave={() => setHoverEdit(false)}
            onClick={() => onShowEdit(goal)}
            className="cursor-pointer text-indigo-800"
          >
            {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
          </span>
        </div>{" "}
      </div>
    </li>
  );
};

export default HealthGoalListItem;
