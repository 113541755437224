import { CheckCircleIcon } from "@heroicons/react/24/outline";

const Confirmation = (props) => {
  const { firstName, lastName, onSubmit } = props;

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6 flex flex-col space-y-5 items-center place-content-center">
        <div>
          <CheckCircleIcon className="h-36 w-36 text-green-700" />
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            Welcome {firstName} {lastName}!
          </h1>
        </div>
        <div className="w-full h-12">
          <button
            type="button"
            onClick={onSubmit}
            className="flex w-full h-full justify-center place-items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
