import { useContext, useState } from "react";

import { toast } from "sonner";

import {
  RiAddCircleLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiDeleteBinFill,
  RiDeleteBinLine,
  RiEditCircleLine,
} from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";

import { classNames } from "../../../utils/cssUtils";
import { toSnakeCase } from "../../../utils/string";
import { itemType, itemId } from "../../../utils/noteUtils";

const statuses = {
  added: "text-emerald-700 bg-emerald-50 ring-emerald-600/20",
  edited: "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  removed: "text-rose-800 bg-rose-50 ring-rose-600/20",
  completed: "text-indigo-700 bg-indigo-50 ring-indigo-600/20",
};

const statusIcons = {
  added: <RiAddCircleLine size={15} />,
  edited: <RiEditCircleLine size={15} />,
  removed: <RiCloseCircleLine size={15} />,
  completed: <RiCheckboxCircleLine size={15} />,
};

const ActionListItem = (props) => {
  const { patientId, noteId, item, onDelete } = props;

  const api = useContext(ApiContext);

  const [hoverDelete, setHoverDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const itemContent = () => {
    return item.screening_tool_name || item.content || item.name;
  };

  const handleDelete = (e) => {
    e.preventDefault();

    setIsDeleting(true);

    const type = itemType(item);

    if (type === null) {
      toast.error("Failed to delete action.", { description: "Invalid action type." });
      console.error("Invalid action type.", item);
      return;
    }

    api.client
      .delete(`/patients/${patientId}/notes/${noteId}/actions/${itemId(item)}`, {
        params: { item: toSnakeCase(type) },
      })
      .then((resp) => {
        onDelete(item);
        setIsDeleting(false);
      })
      .catch((resp) => {
        setIsDeleting(false);
      });
  };

  return (
    <li
      data-tooltip-id="action-list-item"
      data-tooltip-content={itemContent()}
      className="relative flex flex-col px-2 py-1 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200"
    >
      {isDeleting && (
        <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
          <div className="flex items-center">
            <span className="text-base mr-4 -ml-24">Loading</span>
            <svg
              className="animate-spin h-6 w-6 text-slate-800"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between w-full gap-x-2">
        <div className="min-w-0">
          <div className="flex items-start gap-x-2">
            <p
              className={classNames(
                statuses[item.state],
                "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
              )}
            >
              {statusIcons[item.state]}
            </p>
            <div className="flex text-sm font-semibold text-slate-900 items-center space-x-2 truncate">
              {itemContent()}
            </div>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <span
            data-tooltip-id="action-list-item"
            data-tooltip-content="Remove from note"
            onClick={handleDelete}
            className="cursor-pointer text-red-500"
            onMouseEnter={() => setHoverDelete(true)}
            onMouseLeave={() => setHoverDelete(false)}
          >
            {hoverDelete ? <RiDeleteBinFill size={18} /> : <RiDeleteBinLine size={18} />}
          </span>
        </div>
      </div>
    </li>
  );
};

export default ActionListItem;
