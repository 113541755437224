import { createContext, useCallback, useState } from "react";

const RecommendationContext = createContext({
  recommendations: [],
  addRecommendation: (recommendation) => {},
  removeRecommendation: (recommendationId) => {},
  updateRecommendation: (recommendation) => {},
  bulkAddRecommendations: (data) => {},
});

const RecommendationProvider = ({ children }) => {
  const [recommendations, setRecommendations] = useState([]);

  const addRecommendation = useCallback((recommendation) => {
    setRecommendations((prev) => {
      return [...prev, recommendation];
    });
  }, []);

  const removeRecommendation = useCallback((recommendationId) => {
    setRecommendations((prev) => {
      const res = prev.filter((recommendation) => recommendation.id !== recommendationId);
      return [...res];
    });
  }, []);

  const updateRecommendation = useCallback((recommendation) => {
    setRecommendations((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === recommendation.id) {
          return recommendation;
        } else {
          return rec;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAddRecommendations = useCallback((data) => {
    setRecommendations(data);
  }, []);

  const contextValue = {
    recommendations,
    addRecommendation: useCallback((recommendation) => addRecommendation(recommendation), [addRecommendation]),
    removeRecommendation: useCallback(
      (recommendationId) => removeRecommendation(recommendationId),
      [removeRecommendation]
    ),
    updateRecommendation: useCallback((recommendation) => updateRecommendation(recommendation), [updateRecommendation]),
    bulkAddRecommendations: useCallback((data) => bulkAddRecommendations(data), [bulkAddRecommendations]),
  };

  return <RecommendationContext.Provider value={contextValue}>{children}</RecommendationContext.Provider>;
};

export { RecommendationContext, RecommendationProvider };
