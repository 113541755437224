import { Fragment, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

import { BellIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../utils/cssUtils";

import { ApiContext } from "../../providers/ApiProvider";

const NotificationDropDown = (props) => {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    api.client
      .get("/notifications")
      .then((resp) => {
        setAlerts(resp.data.patients);
      })
      .catch((resp) => {});
  }, [api.client]);

  const handleSelect = (alert) => {
    navigate(`/patients/${alert.id}`);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">View notifications</span>
          <BellIcon className="h-6 w-6" aria-hidden="true" />
          {alerts.length > 0 && (
            <span className="absolute inset-0 object-right-bottom -mr-4 -mt-1.5">
              <div className="inline-flex items-center px-1 py-1 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white"></div>
            </span>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {alerts.map((alert) => (
            <Menu.Item key={alert.id}>
              {({ active }) => (
                <div
                  onClick={() => handleSelect(alert)}
                  className={classNames(
                    "group flex items-center px-4 py-2 text-sm",
                    active ? "bg-indigo-600 text-white rounded-md" : "text-gray-900"
                  )}
                >
                  {alert.photo ? (
                    <img src={alert.photo} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                  ) : (
                    <span className="inline-block overflow-hidden h-6 w-6 rounded-full bg-gray-100">
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                  <span className="ml-3 truncate">{alert.name}</span>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationDropDown;
