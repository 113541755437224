import { useContext, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { UserPlusIcon } from "@heroicons/react/24/outline";

import { classNames } from "../utils/cssUtils";
import { patientPronouns } from "../utils/patientUtils";

import { ApiContext } from "../providers/ApiProvider";

import DetailsSlideOver from "../components/UI/DetailsSlideOver";
import Paginator from "../components/UI/Paginator";
import PatientDetails from "../components/Patients/Details/PatientDetails";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import SearchCardHeader from "../components/UI/SearchCardHeader";
import Table from "../components/UI/Table";

import "./PatientsPage.css";

const PatientsPage = () => {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const tableHeaders = ["name", "sex at birth", "date of birth", "subscription status"];

  const [searchParams, setSearchParams] = useSearchParams();

  const [patientAlerts, setPatientAlerts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") || "");
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    api.client
      .get(`/patients`, { params: { page: page, query: searchQuery } })
      .then((resp) => {
        setPatients(resp.data.patients);
        setTotalItems(resp.data?.meta?.total || 0);
        if (!!resp.data?.meta?.alerts) {
          setPatientAlerts(() => {
            const res = {};
            resp.data.meta.alerts.forEach((a) => (res[a] = true));

            return res;
          });
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, page, searchQuery]);

  const handleGoToPage = (newPage) => {
    setPage(newPage);
    setSearchParams((prev) => {
      return { ...prev, page: newPage };
    });
  };

  const handleAdd = (data) => {
    api.client
      .post(
        "/patients",
        { patient: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setPatients((prev) => [...prev, resp.data.patient]);
        setShowAddPatient(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleSearch = (val) => {
    setSearchParams((prev) => {
      return { ...prev, page: 1, query: val };
    });
    setPage(1);
    setSearchQuery(val);
  };

  const patientName = (patient) => {
    if (patient.preferred_name) {
      return `${patient.first_name} "${patient.preferred_name}" ${patient.last_name}`;
    } else {
      return patient.name;
    }
  };

  const handleViewPatient = (patient) => {
    navigate(`./${patient.id}`);
  };

  const buildPatientRow = (patient) => {
    const classes = "capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell";

    return (
      <>
        <td className={classNames("text-left", classes)}>
          <div className="flex flex-row gap-3 items-center">
            {patientAlerts[patient.id] && (
              <div className="flex-none text-rose-400 bg-rose-400/10 rounded-full p-1">
                <div className="h-2 w-2 rounded-full bg-current" />
              </div>
            )}
            <span className="text-indigo-600 hover:text-indigo-900">
              {patientName(patient)} <span className="lowercase">{patientPronouns(patient.pronouns)}</span>
            </span>
          </div>
        </td>
        <td className={classNames("text-center", classes)}>
          {patient.sex === "female" && "Female"}
          {patient.sex === "male" && "Male"}
          {(patient.sex === undefined || patient.sex === null || patient.sex.length === 0) && ""}
        </td>
        <td className={classNames("text-center", classes)}>{patient.dob}</td>
        <td className={classNames("text-center", classes, patient.subscription_status || "unknown")}>
          <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {patient.subscription_status || "No Subscription"}
          </span>
        </td>
      </>
    );
  };

  return (
    <div className="flex w-full">
      <div className="mt-6 w-full mx-auto max-w-7xl px-8">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6 flex">
            <SearchCardHeader
              onSearch={handleSearch}
              query={searchQuery}
              onButtonClick={() => setShowAddPatient(true)}
              buttonTitle="Add a New Patient"
            />
          </div>
          <div className="px-4 py-5 sm:p-6">
            {isLoading ? (
              <LoadingSpinner />
            ) : patients.length === 0 ? (
              <button
                type="button"
                onClick={() => setShowAddPatient(true)}
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-semibold text-gray-900">Get Started by Adding a Patient</span>
              </button>
            ) : (
              <Table headers={tableHeaders} items={patients} buildRow={buildPatientRow} onClick={handleViewPatient} />
            )}
          </div>
          <div className="px-4 py-4 sm:px-6">
            <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} />
          </div>
        </div>
      </div>

      {showAddPatient && (
        <DetailsSlideOver open={showAddPatient} onClose={() => setShowAddPatient(false)} title="Add a New Patient">
          <PatientDetails onClose={() => setShowAddPatient(false)} onChange={handleAdd} />
        </DetailsSlideOver>
      )}
    </div>
  );
};

export default PatientsPage;
