const CancelButton = (props) => {
  const { onClick, label = "Cancel" } = props;

  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex px-4 py-2 text-sm items-center justify-center font-medium shadow-sm rounded-md text-indigo-800 ring-1 ring-indigo-400 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-100"
    >
      {label}
    </button>
  );
};

export default CancelButton;
