import { Fragment, useState } from "react";

import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { Tooltip } from "react-tooltip";

import { classNames } from "../../../utils/cssUtils";

import Table from "../../UI/Table";

const LifestyleScores = (props) => {
  const { scores } = props;

  const [showDetails, setShowDetails] = useState({ show: false, score: null });

  const calcProgress = (score) => {
    return (score.score / score.max_score) * 100;
  };

  const handleShowScoreDetails = (score) => {
    if (showDetails.show && showDetails.score.external_id === score.external_id) {
      setShowDetails({ show: false, score: null });
    } else if (showDetails.show && showDetails.score.external_id !== score.external_id) {
      setShowDetails({ show: true, score });
    } else {
      setShowDetails({ show: true, score });
    }
  };

  const tableHeaders = ["name", "previous", "current", "max"];

  const buildRow = (item) => {
    const classes = "capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell";

    return (
      <>
        <td className={classNames("text-left", classes)}>{item.name}</td>
        <td className={classNames("text-center", classes)}>{item.previous_score || "-"}</td>
        <td className={classNames("text-center", classes)}>{item.current_score || "-"}</td>
        <td className={classNames("text-center space-x-2", classes)}>{item.max_score}</td>
      </>
    );
  };

  return (
    <>
      <div className="rounded-lg overflow-hidden shadow">
        <div className="border-b border-slate-200 bg-slate-50 py-5 px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-nowrap">
            <h3 className="text-lg font-medium leading-6 text-slate-900">Lifestyle Scores</h3>
          </div>
        </div>
        <div className="bg-white p-5">
          <div className="grid grid-cols-6 items-end justify-evenly gap-x-5 px-4 mx-auto">
            {scores.map((item) => (
              <Fragment key={item.name}>
                <div className="text-center w-full" data-tooltip-id={item.name} data-tooltip-content={item.name}>
                  <p onClick={() => handleShowScoreDetails(item)} className="text-sm font-bold cursor-pointer">
                    {item.label}
                    {showDetails.show && showDetails.score.external_id === item.external_id ? (
                      <ChevronDownIcon className="h-5 w-5 inline-block ml-1 text-slate-500" />
                    ) : (
                      <ChevronRightIcon className="h-5 w-5 inline-block ml-1 text-slate-500" />
                    )}
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2 sm:h-2.5 mt-2">
                    <div
                      className="h-2 sm:h-2.5 rounded-full bg-indigo-600"
                      style={{ width: `${calcProgress(item)}%` }}
                    ></div>
                  </div>
                </div>
                <Tooltip id={item.name} />
              </Fragment>
            ))}
          </div>
        </div>
        {showDetails.show && (
          <div className="bg-white p-5">
            <Table headers={tableHeaders} items={showDetails.score.detailed_scores} buildRow={buildRow} />
          </div>
        )}
      </div>
    </>
  );
};

export default LifestyleScores;
