const DateInput = (props) => {
  const { name, value, onChange } = props;

  return (
    <input
      type="date"
      name={name}
      id={name}
      className={`
        block text-sm rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset
        text-gray-900 ring-gray-300 focus:ring-indigo-600
      `}
      value={value}
      onChange={onChange}
    />
  );
};

export default DateInput;
