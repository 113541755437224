import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { RiAddCircleLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";
import { MedicationContext } from "../../../providers/MedicationProvider";

import { classNames } from "../../../utils/cssUtils";

import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";

import MedicationDetails from "./MedicationDetails";
import MedicationListItem from "./MedicationListItem";

const MedicationList = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const { medications, addMedication, removeMedication, updateMedication, bulkAddMedications } =
    useContext(MedicationContext);

  const tabs = ["active", "inactive", "both"];

  const [curTab, setCurTab] = useState(tabs[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState({});
  const [showEditMedications, setShowEditMedications] = useState({ show: false, medication: null });

  useEffect(() => {
    setIsLoading(true);

    api.client
      .get(`/patients/${patientId}/medications`, { params: { status: curTab } })
      .then((resp) => {
        bulkAddMedications(resp.data.patients_medications || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, curTab]);

  const handleSubmit = (data) => {
    setIsSubmitting(true);

    if (data.id) {
      api.client
        .put(`/patients/${patientId}/medications/${data.id}`, data)
        .then((resp) => {
          updateMedication(resp.data.patients_medication);
          setIsSubmitting(false);
          toast.success("Medication updated successfully.");
          setShowEditMedications(false);
        })
        .catch((resp) => {
          setIsSubmitting(false);
          toast.error("Failed to update medication.");
        });
    } else {
      api.client
        .post(`/patients/${patientId}/medications`, data)
        .then((resp) => {
          addMedication(resp.data.patients_medication);
          setIsSubmitting(false);
          toast.success("Medication added successfully.");
          setShowEditMedications(false);
        })
        .catch((resp) => {
          setIsSubmitting(false);
          toast.error("Failed to add medication.");
        });
    }
  };
  const handleDelete = (id) => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));

    api.client
      .delete(`/patients/${patientId}/medications/${id}`)
      .then((resp) => {
        removeMedication(id);
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
        toast.success("Medication deleted successfully.");
      })
      .catch((resp) => {
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
        toast.error("Failed to delete medication.");
      });
  };

  return (
    <>
      <div className="mt-5 border-t border-slate-200">
        <div className="flex flex-wrap px-5 py-3 items-center justify-between border-b border-slate-200">
          <div className="flex items-center space-x-3">
            <h2 className="text-lg font-medium text-slate-900 border-none">Medications</h2>
            <nav aria-label="Tabs" className="flex space-x-2">
              {tabs.map((tab) => (
                <span
                  key={tab}
                  onClick={() => setCurTab(tab)}
                  className={classNames(
                    curTab === tab
                      ? "ring-1 ring-indigo-300 text-indigo-700"
                      : "text-gray-500 hover:ring-1 hover:ring-slate-300",
                    "cursor-pointer rounded-md px-2 py-1 text-sm"
                  )}
                >
                  {tab}
                </span>
              ))}
            </nav>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setShowEditMedications({ show: true, medication: null })}
              className="relative inline-flex items-center gap-x-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
            >
              <RiAddCircleLine className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="m-3">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <ul className="divide-y divide-slate-100 max-h-[300px] overflow-y-scroll">
                {medications.map((medication) => (
                  <MedicationListItem
                    key={medication.id}
                    medication={medication}
                    onDelete={handleDelete}
                    onShowEdit={setShowEditMedications}
                    onLoading={isDeleting[medication.id]}
                  />
                ))}
              </ul>
            </>
          )}
        </div>
      </div>

      {showEditMedications.show && (
        <DetailsSlideOver
          open={showEditMedications.show}
          onClose={() => setShowEditMedications(false)}
          title={!!showEditMedications.medication ? "Edit Medication" : "Add Medication"}
        >
          {isSubmitting ? (
            <LoadingSpinner />
          ) : (
            <MedicationDetails
              medication={showEditMedications.medication}
              onClose={() => setShowEditMedications(false)}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          )}
        </DetailsSlideOver>
      )}
    </>
  );
};

export default MedicationList;
