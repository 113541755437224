function formatDate(d, forDisplay = false) {
  if (d === null || d === undefined || d === "") {
    return "";
  }

  return forDisplay
    ? `${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}-${d.getFullYear()}`
    : `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
}

function calculateFutureDate(duration, unit) {
  const today = new Date();

  switch (unit.toLowerCase()) {
    case "weeks":
      today.setDate(today.getDate() + duration * 7);
      break;
    case "months":
      today.setMonth(today.getMonth() + duration);
      break;
    case "years":
      today.setFullYear(today.getFullYear() + duration);
      break;
    default:
      return null;
  }

  const futureDateUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

  return futureDateUTC;
}

/*
  How to calculate grid row:

  col-start: - which day, 1 == sunday

  gridRow: <start "time"> / span <"duration">
    start:
      2: 12AM
      3: 12:15AM
      4: 12:30AM
      5: 12:45AM
      6: 1AM
      7: 1:15AM
      8: 1:30AM
      9: 1:45AM
      10: 2AM
      11: 2:15AM
      12: 2:30AM
      13: 2:45AM
      14: 3AM
      15: 3:15AM
      16: 3:30AM
      17: 3:45AM
      18: 4AM
      19: 4:15AM
      20: 4:30AM
      21: 4:45AM
      22: 5AM
      23: 5:15AM
      24: 5:30AM
      25: 5:45AM
      26: 6AM
      27: 6:15AM
      28: 6:30AM
      29: 6:45AM
      30: 7AM


    span:
      1: 15 minutes
      2: 30 minutes
      3: 45 minutes
      4: 1 hour
*/

function convertTimeToGrid(time) {
  const hour = time.getHours();
  const minute = time.getMinutes();
  let start = 0;

  // Get the starting grid row based on the hour. +2 to account for header
  start = hour * 4 + 2;

  start += Math.floor(minute / 15);

  return start;
}

// @param duration - integer in minutes
// duration is in minutes, 4 slots per hour in 15 minute increments
function convertDurationToGrid(duration) {
  return Math.floor(duration / 15);
}

function calculateGridRow(datetime, duration) {
  const d = new Date(datetime);

  const start = convertTimeToGrid(d);

  const span = convertDurationToGrid(duration);

  return `${start} / span ${span}`;
}

export { formatDate, calculateFutureDate, calculateGridRow, convertTimeToGrid, convertDurationToGrid };
