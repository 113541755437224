import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { ApiContext } from "../../../providers/ApiProvider";

import RichEditor from "../../UI/Editor/RichEditor";
import ActionList from "./ActionList";

const Note = (props) => {
  const { note, onSave, patientId, onChange } = props;

  const api = useContext(ApiContext);

  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(note?.content || "");
  }, [note]);

  const handleSaveContent = (content) => {
    onSave(true);
    api.client
      .put(`patients/${patientId}/notes/${note.id}`, { note: { content: content } })
      .then((resp) => {
        onChange(resp.data.note);
        toast.success("Note updated.");
        onSave(false);
      })
      .catch((resp) => {
        onSave(false);
      });
  };

  return (
    <>
      <div className="m-5 border rounded-md border-slate-300 min-h-96 max-h-64 sm:w-[800px] 2xl:w-[1000px]">
        <RichEditor content={content} onChange={handleSaveContent} />
      </div>
      <ActionList patientId={patientId} note={note} />
    </>
  );
};

export default Note;
