import { useContext, useEffect, useRef, useState } from "react";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { format } from "date-fns";

import { toast } from "sonner";

import { Tooltip } from "react-tooltip";

import {
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiSearchLine,
  RiTimeFill,
  RiTimeLine,
} from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";

import Input from "../../UI/Input";
import LoadingSpinner from "../../UI/LoadingSpinner";

import Note from "./Note";
import NoteListItem from "./NoteListItem";

import "./NotesList.css";

const noteDate = (note) => {
  if (!note || note.completed_at === null) {
    return "Current Note";
  }

  return format(new Date(note.completed_at), "MMM dd, yyyy");
};

const NotesList = (props) => {
  const { patientId } = props;

  const pageSize = 10;

  const api = useContext(ApiContext);

  const [curNote, setCurNote] = useState();
  const [hoverTime, setHoverTime] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState([]);
  const [noteQuery, setNoteQuery] = useState("");
  const [page, setPage] = useState(1);
  const [showNotesMenu, setShowNotesMenu] = useState(false);
  const [totalNotes, setTotalNotes] = useState(0);

  const searchRef = useRef();
  const timeRef = useRef();

  useEffect(() => {
    api.client
      .get(`/patients/${patientId}/notes`, { params: { per_page: pageSize } })
      .then((resp) => {
        setNotes(resp.data.notes);
        setCurNote(resp.data.notes[0]);
        setTotalNotes(resp.data.meta?.total || 0);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, patientId]);

  useEffect(() => {
    setIsNotesLoading(true);

    api.client
      .get(`/patients/${patientId}/notes`, { params: { per_page: pageSize, query: noteQuery } })
      .then((resp) => {
        if (noteQuery.length > 0) {
          setShowNotesMenu(true);
        }
        setNotes(resp.data.notes);
        setTotalNotes(resp.data.meta?.total || 0);
        setIsNotesLoading(false);
      })
      .catch((resp) => {
        setIsNotesLoading(false);
      });
  }, [api.client, noteQuery, patientId]);

  useEffect(() => {
    if (showNotesMenu) return;

    setNoteQuery("");
    !!searchRef.current && (searchRef.current.value = "");
  }, [showNotesMenu]);

  const handleOnDelete = (id) => {
    api.client
      .delete(`/patients/${patientId}/notes/${id}`)
      .then((resp) => {
        setNotes((prev) => {
          const result = prev.filter((n) => n.id !== id);

          return [...result];
        });
        setCurNote(notes[0]);
        setTotalNotes((prev) => prev - 1);
        toast.success("Note deleted successfully.");
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleSelect = (id) => {
    const note = notes.find((n) => n.id === id);
    if (!!note) {
      setCurNote(note);
      setShowNotesMenu(false);
    }
  };

  const handleNoteChange = (note) => {
    setCurNote(note);
    setNotes((prev) => {
      const res = prev.map((n) => {
        if (n.id === note.id) {
          return note;
        }

        return n;
      });

      return [...res];
    });
  };

  const handleLoadMore = () => {
    setIsLoadingMore(true);

    api.client
      .get(`/patients/${patientId}/notes`, { params: { page: page + 1, per_page: pageSize, query: noteQuery } })
      .then((resp) => {
        setPage((prev) => prev + 1);

        setNotes((prev) => {
          const res = prev.concat(resp.data.notes);

          return [...res];
        });
        setIsLoadingMore(false);
      })
      .catch((resp) => {
        setIsLoadingMore(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const val = e.target.value;

    if (val.trim() !== "" && val.length < 2) {
      return;
    }

    if (val === "" && noteQuery !== "") {
      setPage(1);
    }

    setNoteQuery(val);
  };

  const handleTimeEntry = (close, val) => {
    setIsSaving(true);
    api.client
      .put(`patients/${patientId}/notes/${curNote.id}`, { note: { visit_minutes: val } })
      .then((resp) => {
        handleNoteChange(resp.data.note);
        setIsSaving(false);
        close();
        toast.success("Updates time spent.");
      })
      .catch((resp) => {
        toast.error("Failed to update time spent.");
        setIsSaving(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="grid grid-cols-3 gap-x-3 items-center justify-between min-h-16 px-5 pt-5 pb-3 bg-white border-b border-slate-200">
            <div className="flex items-center gap-x-1">
              <h2 className="text-lg font-medium text-slate-900">{noteDate(curNote)}</h2>
              <Popover className="relative items-center">
                {({ open }) => (
                  <>
                    <PopoverButton className="inline-flex bg-white px-2 py-2 text-sm font-semibold">
                      {open ? (
                        <RiTimeFill className="h-6 w-6 text-indigo-800" />
                      ) : (
                        <div
                          data-tooltip-id="time-entry"
                          data-tooltip-content="Record visit time"
                          className="flex items-center space-x-2"
                        >
                          <RiTimeLine className="h-6 w-6 text-indigo-800" />
                          {!!curNote.visit_minutes && <span> {curNote.visit_minutes} (min)</span>}
                        </div>
                      )}
                    </PopoverButton>

                    <PopoverPanel
                      transition
                      className="absolute left-10 z-10 flex w-screen max-w-min -translate-x-1/2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      {({ close }) => (
                        <div className="w-80 p-3 rounded-xl leading-6 shadow-lg ring-1 ring-slate-300 bg-slate-100">
                          <Input
                            ref={timeRef}
                            type="number"
                            name="timeEntry"
                            defaultValue={curNote.visit_minutes}
                            placeholder="Enter time spent on the visit"
                            postFix={
                              <div className="flex gap-x-2 items-center">
                                (min)
                                <span
                                  className="cursor-pointer z-30 text-emerald-600"
                                  onMouseEnter={() => setHoverTime(true)}
                                  onMouseLeave={() => setHoverTime(false)}
                                  onClick={() => handleTimeEntry(close, timeRef.current.value)}
                                >
                                  {hoverTime ? <RiCheckboxCircleFill size={20} /> : <RiCheckboxCircleLine size={20} />}
                                </span>
                              </div>
                            }
                            // onChange={(val) => handleTimeEntry(close, val)}
                            onBlur={(val) => handleTimeEntry(close, val)}
                          />
                        </div>
                      )}
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            </div>
            <div>
              <div className="relative w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <RiSearchLine className="h-5 w-5 text-slate-400" />
                </div>
                <input
                  id="search"
                  ref={searchRef}
                  name="search"
                  type="search"
                  placeholder="Search Notes"
                  onChange={handleSearch}
                  className="block w-full rounded-md border-0 py-1.5 text-slate-900 pl-10 pr-3 placeholder:text-slate-400 focus:bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6 shadow-sm ring-1 ring-slate-300"
                />
              </div>
            </div>
            <div>
              <div className="flex justify-end">
                {isSaving && <LoadingSpinner small color="text-indigo-800" showLoadingText={false} />}
                <button
                  type="button"
                  onClick={() => setShowNotesMenu((prev) => !prev)}
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 hover:disabled:bg-slate-100 disabled:bg-slate-100 disabled:cursor-not-allowed"
                >
                  Previous Notes
                </button>
              </div>
            </div>
          </div>
          <div className="relative flex flex-1 bg-white max-h-[600px] h-full">
            <section className="h-full">
              <Note
                note={curNote}
                patientId={patientId}
                onSave={(val) => setIsSaving(val)}
                onChange={handleNoteChange}
              />
            </section>
            {showNotesMenu && (
              <div className="absolute inset-y-0 right-0 border-l border-slate-200 focus:outline-none">
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={() => setShowNotesMenu(false)}
                    className="relative rounded-md text-indigo-500 hover:text-indigo-700 hover:ring-2 hover:ring-indigo-400 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  >
                    <RiCloseCircleLine aria-hidden="true" className="h-8 w-8" />
                  </button>
                </div>
                <aside className="relative bg-white h-full overflow-y-scroll transform -auto ease-in-out transition-all duration-10">
                  <div className="relative flex h-full w-96 flex-col overflow-y-scroll border-r border-slate-200">
                    {isNotesLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <ul className="space-y-3 mt-5">
                        {notes.map((note) => (
                          <li key={note.id}>
                            <NoteListItem
                              note={note}
                              selected={note.id === curNote?.id}
                              onDelete={handleOnDelete}
                              onSelect={handleSelect}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className="px-4 py-4 sm:px-6">
                      <button
                        type="button"
                        onClick={handleLoadMore}
                        disabled={isLoadingMore || totalNotes === 0 || notes.length === totalNotes}
                        className="w-full rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 hover:disabled:bg-slate-100 disabled:bg-slate-100 disabled:cursor-not-allowed"
                      >
                        {isLoadingMore ? "Load More..." : "Loading More"}
                      </button>
                    </div>
                  </div>
                </aside>
              </div>
            )}
          </div>
          <Tooltip id="time-entry" />
        </>
      )}
    </>
  );
};

export default NotesList;
