import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import Steps from "../components/UI/Steps";

import PaymentForm from "../components/Registration/PaymentForm";
import Confirmation from "../components/Registration/Confirmation";
import UserInfoForm from "../components/Registration/UserInfoForm";
import Stripe from "../components/Registration/Stripe";

const registrationSteps = [
  { id: "1", name: "Your Information", status: "current" },
  { id: "2", name: "Setup Subscription", status: "upcoming" },
  { id: "3", name: "Membership Setup", status: "upcoming" },
  { id: "4", name: "Confirmation", status: "upcoming" },
];

const RegisterPage = () => {
  const navigate = useNavigate();

  const api = useContext(ApiContext);

  const [curStep, setCurStep] = useState(registrationSteps[0].id);
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState(registrationSteps);

  const [accountData, setAccountData] = useState({
    coupon: null,
    email: "",
    first_name: "",
    last_name: "",
    nonce: null,
    organization: "",
    password: "",
    password_confirmation: "",
    zip: null,
  });

  const createSubscription = (accountId) => {
    setIsLoading(true);

    api.client
      .post(`/subscriptions`, { ...accountData, account_id: accountId })
      .then((resp) => {
        setIsLoading(false);
        // login & redirect
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleStepOne = (data) => {
    setAccountData((prev) => ({
      ...prev,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      organization: data.organization,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    }));

    setSteps((prev) => {
      return [{ ...steps[0], status: "complete" }, { ...steps[1], status: "current" }, steps[2], steps[3]];
    });
    setCurStep(steps[1].id);
  };

  const handleStepTwo = (data) => {
    setAccountData((prev) => ({ ...prev, coupon: data.coupon, zip: data.zip, nonce: data.nonce }));

    setSteps((prev) => {
      return [steps[0], { ...steps[1], status: "complete" }, { ...steps[2], status: "current" }, steps[3]];
    });
    setCurStep(steps[2].id);
  };

  const handleStepThree = (accountId) => {
    createSubscription(accountId);

    setSteps((prev) => {
      return [steps[0], steps[1], { ...steps[2], status: "complete" }, { ...steps[3], status: "current" }];
    });
    setCurStep(steps[3].id);
  };

  const handleStepFour = () => {
    navigate("/dashboard");
  };

  return (
    <div className="flex flex-col justify-items-center mx-auto mt-5 max-w-7xl rounded-lg bg-white border border-slate-100 shadow">
      <div className="px-4 py-5 w-full">
        <Steps steps={steps} />
      </div>
      <div className="px-4 py-5 w-full">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {curStep === steps[0].id && <UserInfoForm onSubmit={handleStepOne} />}
                {curStep === steps[1].id && <PaymentForm onSubmit={handleStepTwo} />}
                {curStep === steps[2].id && <Stripe onSubmit={handleStepThree} />}
                {curStep === steps[3].id && (
                  <Confirmation
                    firstName={accountData.first_name}
                    lastName={accountData.last_name}
                    onSubmit={handleStepFour}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
