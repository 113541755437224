import { useState } from "react";

import EditHealthGoal from "./EditHealthGoal";

const CustomizeTemplateHealthGoal = (props) => {
  const { goal, onChange } = props;

  const content = goal.content;

  const [showCustomForm, setShowCustomForm] = useState(false);

  return (
    <div className="block hover:bg-slate-100">
      <div className="flex items-center px-4 py-4">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="px-4">
            <p className="text-sm font-medium text-slate-600">{content}</p>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent opacity-80 bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setShowCustomForm(true)}
          >
            Customize
          </button>
          <button
            type="button"
            className="ml-5 inline-flex items-center rounded-md border border-transparent opacity-80 bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={() => onChange({ health_goal_id: goal.id })}
          >
            Add
          </button>
        </div>
      </div>
      {showCustomForm && <EditHealthGoal goal={goal} onChange={onChange} onClose={() => setShowCustomForm(false)} />}
    </div>
  );
};

export default CustomizeTemplateHealthGoal;
