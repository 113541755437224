import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../../../providers/ApiProvider";

import { classNames } from "../../../utils/cssUtils";

import LoadingSpinner from "../../UI/LoadingSpinner";

import Answers from "./Answers";
import Recommendations from "./Recommendations";
import { copyQuestionsToClipboard } from "../../../utils/questionUtils";
import DividerWithLabel from "../../UI/DividerWIthLabel";

const Review = (props) => {
  const { toolId, patientId, onComplete } = props;

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tool, setTool] = useState({});
  const [questions, setQuestions] = useState([]);
  const [recommendedScreeningTools, setRecommendedScreeningTools] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/screening_tools/${toolId}`)
      .then((resp) => {
        const data = resp.data.patient_screening_tool;
        setTool(data);
        setQuestions(data.questions_answers);
        setRecommendedScreeningTools(data.recommended_screening_tools || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, patientId, toolId]);

  const handleComplete = () => {
    setIsLoading(true);
    api.client
      .put(`/patients/${patientId}/screening_tools/${toolId}`, {
        state: "review",
      })
      .then((resp) => {
        const data = resp.data.patient_screening_tool;
        setTool(data.screening_tool);
        setIsLoading(false);
        onComplete();
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleCopy = () => {
    copyQuestionsToClipboard(tool.screening_tool, questions);
    toast.success("Copied the answers to your clipboard", { description: "You can paste the answers to your EHR now" });
  };

  const buildScore = (item) => {
    if (item.score === undefined || item.score === null) {
      return "";
    }

    let bgColor = null;
    let textColor = null;
    let fillColor = null;
    if (item.score_status === "green") {
      bgColor = "bg-green-100";
      textColor = "text-green-600";
      fillColor = "fill-green-400";
    } else if (item.score_status === "yellow") {
      bgColor = "bg-yellow-100";
      textColor = "text-yellow-600";
      fillColor = "fill-yellow-400";
    } else if (item.score_status === "red") {
      bgColor = "bg-red-100";
      textColor = "text-red-600";
      fillColor = "fill-red-400";
    } else {
      bgColor = "bg-slate-100";
      textColor = "text-slate-600";
      fillColor = "fill-slate-400";
    }

    return (
      <span
        className={classNames(
          "ml-4 inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium",
          bgColor,
          textColor
        )}
      >
        <svg className={classNames("h-1.5 w-1.5", fillColor)} viewBox="0 0 6 6" aria-hidden="true">
          <circle cx={3} cy={3} r={3} />
        </svg>
        <span className="px-3">{item.score}</span>
      </span>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="my-3 mx-4 overflow-x-hidden space-y-5">
            {!!onComplete && (
              <div className="mx-5">
                <button
                  type="button"
                  onClick={handleComplete}
                  className="w-full rounded-md bg-emerald-50 px-3.5 py-2.5 text-sm font-semibold text-emerald-900 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-100"
                >
                  Complete
                </button>
              </div>
            )}

            <DividerWithLabel label="Recommendations" />

            <Recommendations
              patientId={patientId}
              recommendations={tool.recommendations}
              tools={recommendedScreeningTools}
            />

            <DividerWithLabel label="Results" />

            {tool.screening_tool.show_score && (
              <div className="px-4 py-5">
                <div className="flex flex-row items-center">Score: {buildScore(tool)}</div>
                <div className="mt-3 text-slate-500">{tool.score_info}</div>
              </div>
            )}

            <DividerWithLabel
              label={
                <div className="flex items-center">
                  <span>Answers</span>
                  <button
                    type="button"
                    onClick={handleCopy}
                    className="ml-2 inline-flex items-center rounded-md border border-slate-300 bg-white px-4 py-2 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <ClipboardDocumentListIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
                    <span className="ml-3">Copy</span>
                  </button>
                </div>
              }
            />

            <Answers questions={questions} />

            {onComplete && (
              <div className="mx-5">
                <button
                  type="button"
                  onClick={handleComplete}
                  className="w-full rounded-md bg-emerald-50 px-3.5 py-2.5 text-sm font-semibold text-emerald-900 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-100"
                >
                  Complete
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Review;
