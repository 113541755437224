import { createContext, useCallback, useState } from "react";

const HealthGoalContext = createContext({
  recommendations: [],
  addGoal: (recommendation) => {},
  removeGoal: (recommendationId) => {},
  updateGoal: (recommendation) => {},
  bulkAddGoals: (data) => {},
});

const HealthGoalProvider = ({ children }) => {
  const [goals, setGoals] = useState([]);

  const addGoal = useCallback((goal) => {
    setGoals((prev) => {
      return [...prev, goal];
    });
  }, []);

  const removeGoal = useCallback((goalId) => {
    setGoals((prev) => {
      const res = prev.filter((goal) => goal.id !== goalId);
      return [...res];
    });
  }, []);

  const updateGoal = useCallback((goal) => {
    setGoals((prev) => {
      const res = prev.map((g) => {
        if (g.id === goal.id) {
          return goal;
        } else {
          return g;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAddGoals = useCallback((data) => {
    setGoals(data);
  }, []);

  const contextValue = {
    goals,
    addGoal: useCallback((goal) => addGoal(goal), [addGoal]),
    removeGoal: useCallback((goalId) => removeGoal(goalId), [removeGoal]),
    updateGoal: useCallback((goal) => updateGoal(goal), [updateGoal]),
    bulkAddGoals: useCallback((data) => bulkAddGoals(data), [bulkAddGoals]),
  };

  return <HealthGoalContext.Provider value={contextValue}>{children}</HealthGoalContext.Provider>;
};

export { HealthGoalContext, HealthGoalProvider };
