const DividerWithLabel = (props) => {
  const { label } = props;

  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-slate-400" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-base text-slate-600">{label}</span>
      </div>
    </div>
  );
};

export default DividerWithLabel;
