import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { ApiContext } from "../../../providers/ApiProvider";
import { ScreeningToolContext } from "../../../providers/ScreeningToolProvider";

import { classNames } from "../../../utils/cssUtils";
import { calculateFutureDate, formatDate } from "../../../utils/date";

import DateInput from "../../UI/DateInput";
import LoadingSpinner from "../../UI/LoadingSpinner";
import Paginator from "../../UI/Paginator";
import SearchCardHeader from "../../UI/SearchCardHeader";
import Table from "../../UI/Table";

const AddScreeningTool = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const { addTool } = useContext(ScreeningToolContext);

  const pageSize = 10;
  const tableHeaders = ["name", "due by", ""];

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [tools, setTools] = useState([]);
  const [toolsDueDate, setToolsDueDate] = useState({});

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/screening_tools`, {
        params: {
          patient_id: patientId,
          page: page,
          per_page: pageSize,
          query: searchQuery,
        },
      })
      .then((resp) => {
        setTools(resp.data.screening_tools);
        setTotalItems(resp.data.meta.total);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, page, patientId, searchQuery]);

  const handleGoToPage = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (val) => {
    setPage(1);
    setSearchQuery(val);
  };

  const handleAddScreeningTool = (tool) => {
    setIsLoading(true);

    const dueBy = toolsDueDate[tool.id] || formatDate(calculateFutureDate(2, "weeks"));

    api.client
      .post(`/patients/${patientId}/screening_tools`, {
        screening_tool_id: tool.id,
        due_by: dueBy,
      })
      .then((resp) => {
        setTools((prev) => {
          const res = prev.filter((t) => t.id !== tool.id);
          return [...res];
        });
        addTool(resp.data.patient_screening_tool);
        setIsLoading(false);
        toast.success(`${tool.name} assigned.`);
      })
      .catch((resp) => {
        toast.error("Failed to assign screening tool.");
        setIsLoading(false);
      });
  };

  const handleToolDueByChange = (e, item) => {
    setToolsDueDate((prev) => {
      prev[`${item.id}`] = e.target.value;

      return { ...prev };
    });
  };

  const buildRow = (item) => {
    const classes = "capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell";

    return (
      <>
        <td className={classNames("text-left", classes)}>{item.name}</td>
        <td className={classNames("text-right space-x-2 justify-items-end", classes)}>
          <div className="flex justify-center">
            <div>
              <DateInput
                value={toolsDueDate[item.id] || formatDate(calculateFutureDate(2, "weeks"))}
                onChange={(e) => handleToolDueByChange(e, item)}
              />
            </div>
          </div>
        </td>
        <td className={classNames("text-right space-x-2", classes)}>
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => handleAddScreeningTool(item)}
          >
            Add
          </button>
        </td>
      </>
    );
  };

  return (
    <div>
      <div className="px-4 py-5 sm:px-6 flex">
        <SearchCardHeader onSearch={handleSearch} query={searchQuery} showButton={false} />
      </div>
      <div className="px-4 py-5 sm:p-6">
        {isLoading ? <LoadingSpinner /> : <Table headers={tableHeaders} items={tools} buildRow={buildRow} />}
      </div>
      <div className="px-4 py-4 sm:px-6">
        <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} pageSize={pageSize} />
      </div>
    </div>
  );
};

export default AddScreeningTool;
