import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../utils/cssUtils";

const NoteListItem = (props) => {
  const { note, selected, onDelete, onSelect } = props;

  const noteDate = () => {
    if (note.completed_at === null) {
      return "Current";
    }

    return new Date(note.created_at).toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    });
  };

  return (
    <>
      <div
        onClick={() => onSelect(note.id)}
        className={classNames(
          "flex rounded-md mx-4 px-3 py-3 shadow border cursor-pointer",
          selected ? "bg-indigo-100 border-indigo-300" : "border-slate-200"
        )}
      >
        <div className="flex items-center justify-start w-full text-sm">{noteDate()}</div>
        <div className="flex items-center justify-end w-full">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="flex items-center text-slate-400 hover:text-slate-600 focus:outline-none">
                <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem>
                  <span
                    onClick={() => onDelete(note.id)}
                    className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100 data-[focus]:text-slate-900"
                  >
                    Delete
                  </span>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default NoteListItem;
