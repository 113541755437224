/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState } from "react";

import Tabs from "../../UI/Tabs";
import EditRecommendationForm from "./EditRecommendationForm";
import AddRecommendationFromTemplate from "./AddRecommendationFromTemplate";
import EditRecommendationList from "./EditRecommendationList";

const RecommendationDetails = (props) => {
  const { recommendations, onClose, onAdd, onUpdate, onDelete, patientId } = props;

  const tabs = {
    edit: { name: "Edit", value: 0 },
    template: { name: "Add From Template", value: 1 },
    custom: { name: "Add Custom", value: 2 },
  };

  const [curTab, setCurTab] = useState(tabs.edit);

  const handleChangeTab = (tab) => {
    setCurTab(tab);
  };

  return (
    <div className="mx-5 mt-6">
      <Tabs tabs={Object.values(tabs)} curTab={curTab} onChange={handleChangeTab} />
      <>
        {curTab.value === tabs.edit.value && (
          <EditRecommendationList
            recommendations={recommendations}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onClose={onClose}
          />
        )}
        {curTab.value === tabs.custom.value && <EditRecommendationForm onClose={onClose} onChange={onAdd} />}
        {curTab.value === tabs.template.value && <AddRecommendationFromTemplate onAdd={onAdd} patientId={patientId} />}
      </>
    </div>
  );
};

export default RecommendationDetails;
