import { createContext, useCallback, useState } from "react";

const MedicationContext = createContext({
  medications: [],
  addMedication: (medication) => {},
  removeMedication: (medicationId) => {},
  updateMedication: (medication) => {},
  bulkAddMedications: (data) => {},
});

const MedicationProvider = ({ children }) => {
  const [medications, setMedications] = useState([]);

  const addMedication = useCallback((medication) => {
    setMedications((prev) => {
      return [...prev, medication];
    });
  }, []);

  const removeMedication = useCallback((medicationId) => {
    setMedications((prev) => {
      const res = prev.filter((medication) => medication.id !== medicationId);
      return [...res];
    });
  }, []);

  const updateMedication = useCallback((medication) => {
    setMedications((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === medication.id) {
          return medication;
        } else {
          return rec;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAddMedications = useCallback((data) => {
    setMedications(data);
  }, []);

  const contextValue = {
    medications,
    addMedication: useCallback((medication) => addMedication(medication), [addMedication]),
    removeMedication: useCallback((medicationId) => removeMedication(medicationId), [removeMedication]),
    updateMedication: useCallback((medication) => updateMedication(medication), [updateMedication]),
    bulkAddMedications: useCallback((data) => bulkAddMedications(data), [bulkAddMedications]),
  };

  return <MedicationContext.Provider value={contextValue}>{children}</MedicationContext.Provider>;
};

export { MedicationContext, MedicationProvider };
