import MedicationList from "../Medications/MedicationList";
import RecommendationList from "../Recommendations/RecommendationList";

const ActionsRecommendations = (props) => {
  const { patientId } = props;

  return (
    <>
      <RecommendationList patientId={patientId} />
      <MedicationList patientId={patientId} />
    </>
  );
};

export default ActionsRecommendations;
