import { useState } from "react";

import Tabs from "../../UI/Tabs";
import EditHealthGoal from "./EditHealthGoal";
import AddHealthGoalFromTemplate from "./AddHealthGoalFromTemplate";

const HealthGoalsDetails = (props) => {
  const { onClose, onAdd } = props;

  const tabs = {
    template: { name: "Add From Template", value: 1 },
    custom: { name: "Add Custom", value: 2 },
  };

  const [curTab, setCurTab] = useState(tabs.template);

  const handleChangeTab = (tab) => {
    setCurTab(tab);
  };

  return (
    <div className="mx-5 mt-6">
      <Tabs tabs={Object.values(tabs)} curTab={curTab} onChange={handleChangeTab} />
      <>
        {curTab.value === tabs.custom.value && <EditHealthGoal onClose={onClose} onChange={onAdd} />}
        {curTab.value === tabs.template.value && <AddHealthGoalFromTemplate onAdd={onAdd} />}
      </>
    </div>
  );
};

export default HealthGoalsDetails;
