import { useContext, useEffect, useState } from "react";

import { RiSearchLine } from "@remixicon/react";

import { classNames } from "../../../utils/cssUtils";

import { ApiContext } from "../../../providers/ApiProvider";

import AllDoneCard from "../../UI/AllDoneCard";
import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";
import Paginator from "../../UI/Paginator";
import Table from "../../UI/Table";

import Review from "../ScreeningTools/Review";
import OnboardingSuggestions from "./OnboardingSuggestions";

const Todo = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const pageSize = 10;
  const tableHeaders = ["name", "score", ""];

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showReview, setShowReview] = useState({ open: false, tool: null });
  const [tools, setTools] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [showFirstAddScreen, setShowFirstAddScreen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/todo`, {
        params: { page: page, per_page: pageSize, query: searchQuery },
      })
      .then((resp) => {
        setTools(resp.data.data[0].patient_screening_tools);
        setTotalItems(resp.data.meta.total);
        setShowFirstAddScreen(resp.data.meta.total_tools <= 1);

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, page, patientId, searchQuery]);

  const handleGoToPage = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setPage(1);
    setSearchQuery(e.target.value);
  };

  const handleCompleteTool = (tool) => {
    setTools((prev) => {
      const res = prev.filter((t) => t.id !== tool.id);
      return [...res];
    });
    setShowReview({ open: false, tool: null });
  };

  const buildScore = (item) => {
    if (item.score === undefined || item.score === null) {
      return "";
    }

    let bgColor = null;
    let textColor = null;
    let fillColor = null;
    if (item.score_status === "green") {
      bgColor = "bg-green-100";
      textColor = "text-green-600";
      fillColor = "fill-green-400";
    } else if (item.score_status === "yellow") {
      bgColor = "bg-yellow-100";
      textColor = "text-yellow-600";
      fillColor = "fill-yellow-400";
    } else if (item.score_status === "red") {
      bgColor = "bg-red-100";
      textColor = "text-red-600";
      fillColor = "fill-red-400";
    } else {
      bgColor = "bg-gray-100";
      textColor = "text-gray-600";
      fillColor = "fill-gray-400";
    }

    return (
      <span
        className={classNames(
          "ml-4 inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium",
          bgColor,
          textColor
        )}
      >
        <svg className={classNames("h-1.5 w-1.5", fillColor)} viewBox="0 0 6 6" aria-hidden="true">
          <circle cx={3} cy={3} r={3} />
        </svg>
        <span className="px-3">{item.score}</span>
      </span>
    );
  };

  const buildRow = (item) => {
    const classes = "capitalize px-3 py-4 text-sm text-gray-500 hidden lg:table-cell";

    return (
      <>
        <td className={classNames("text-left", classes)}>{item?.screening_tool?.name}</td>
        <td className={classNames("text-center", classes)}>{item.screening_tool.show_score && buildScore(item)}</td>
        <td className={classNames("text-right space-x-2", classes)}>
          <button
            type="button"
            onClick={() => setShowReview({ open: true, tool: item })}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
          >
            Review
          </button>
        </td>
      </>
    );
  };

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="grid grid-cols-3 gap-x-3 items-center justify-between min-h-16 px-5 pt-5 pb-3 border-b border-slate-200">
          <div>
            <h2 className="text-lg font-medium text-slate-900">To Do</h2>
          </div>
          <div className="flex items-center justify-center">
            <div className="relative w-full">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <RiSearchLine className="h-5 w-5 text-slate-400" />
              </div>
              <input
                id="search"
                name="search"
                type="search"
                placeholder="Search"
                onChange={handleSearch}
                className="block w-full rounded-md border-0 py-1.5 text-slate-900 pl-10 pr-3 placeholder:text-slate-400 focus:bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6 shadow-sm ring-1 ring-slate-300"
              />
            </div>
          </div>
          <div></div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          {isLoading ? (
            <LoadingSpinner />
          ) : tools.length > 0 ? (
            <Table headers={tableHeaders} items={tools} buildRow={buildRow} />
          ) : showFirstAddScreen ? (
            <OnboardingSuggestions patientId={patientId} />
          ) : (
            <AllDoneCard />
          )}
        </div>
        <div className="px-4 py-4 sm:px-6">
          <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} pageSize={pageSize} />
        </div>
      </div>
      {showReview.open && (
        <DetailsSlideOver
          open={showReview.open}
          onClose={() => setShowReview({ open: false, tool: null })}
          title={`Review ${showReview.tool?.screening_tool?.name || ""} Results`}
        >
          <Review
            toolId={showReview.tool.id}
            patientId={patientId}
            onComplete={() => handleCompleteTool(showReview.tool)}
          />
        </DetailsSlideOver>
      )}
    </>
  );
};

export default Todo;
