const version = process.env.REACT_APP_VERSION;

const DebugPage = () => {
  return (
    <>
      <ul>
        <li>version: {version}</li>
      </ul>
    </>
  );
};

export default DebugPage;
