import { XMarkIcon } from "@heroicons/react/24/outline";

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";

const Modal = (props) => {
  const { open, onClose, title, children } = props;

  return (
    <Dialog open={open} className="relative z-10" onClose={onClose}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full justify-center p-0 text-center items-center">
          <DialogPanel
            transition
            className="w-full max-w-4xl relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in my-8 p-6 data-[closed]:translate-y-0 data-[closed]:scale-95"
          >
            <div className="absolute top-0 right-0 pt-4 pr-4 block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex items-start">
              <div className="text-left w-full">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </DialogTitle>
                <div className="relative mt-6 flex-1">{children}</div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
