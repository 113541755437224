/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useRef } from "react";

import { classNames } from "../../utils/cssUtils";
import { calculateGridRow } from "../../utils/date";
import SmallMonth from "./SmallMonth";

const hours = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
];

const DailyCalendar = (props) => {
  const { selectedDay, onNextMonth, onPrevMonth, onDateSelect, appointments, showAppointment } = props;

  const container = useRef(null);
  const containerOffset = useRef(null);

  useEffect(() => {
    // Set the container scroll position based on the current time.
    // const currentMinute = new Date().getHours() * 60;

    // set container to 7am
    const currentMinute = 7 * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight - containerOffset.current.offsetHeight) * currentMinute) / 1440;
  }, []);

  return (
    <div className="isolate flex flex-auto overflow-hidden bg-white">
      <div ref={container} className="flex flex-auto flex-col overflow-auto">
        <div className="flex w-full flex-auto">
          <div className="w-14 flex-none bg-white ring-1 ring-slate-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-slate-100"
              style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}
            >
              <div ref={containerOffset} className="row-end-1 h-7"></div>
              {hours.map((hour) => (
                <Fragment key={hour}>
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-slate-400">
                      {hour}
                    </div>
                  </div>
                  <div />
                </Fragment>
              ))}
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
              style={{ gridTemplateRows: "1.75rem repeat(96, minmax(0, 1fr)) auto" }}
            >
              {appointments.map((appointment) => (
                <li
                  key={appointment.id}
                  className="relative flex"
                  style={{ gridRow: calculateGridRow(appointment.datetime, appointment.duration) }}
                  onClick={() => showAppointment(appointment)}
                >
                  <span
                    className={classNames(
                      "group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 px-2 text-xs leading-5 hover:bg-blue-100",
                      appointment.duration > 15 ? "pt-3" : ""
                    )}
                  >
                    <p className="font-semibold text-blue-500 group-hover:text-blue-700">
                      <time dateTime={appointment.time}>{appointment.time}</time> {appointment.type} with{" "}
                      {appointment.first_name} {appointment.last_name}
                    </p>
                  </span>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
      <div className="w-1/2 max-w-md flex-none border-l border-slate-100 px-8 py-10 md:block">
        <SmallMonth
          selectedDay={selectedDay}
          onNextMonth={onNextMonth}
          onPrevMonth={onPrevMonth}
          onDateSelect={onDateSelect}
        />
      </div>
    </div>
  );
};

export default DailyCalendar;
