import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../utils/cssUtils";

const SearchCardHeader = (props) => {
  const { onSearch, query, showButton = true, onButtonClick, buttonTitle } = props;

  const handleSearch = (e) => {
    const val = e.target.value;

    if (val.trim() !== "" && val.length < 3) {
      return;
    }

    onSearch(val);
  };

  const handleButtonClick = () => {
    if (onButtonClick !== undefined) {
      onButtonClick();
    }
  };

  return (
    <>
      <div className={classNames("relative flex items-center", showButton ? "w-4/5" : " w-full")}>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          placeholder="Search"
          type="search"
          onChange={handleSearch}
          defaultValue={query}
        />
      </div>
      {showButton && (
        <div className="w-1/5 flex justify-end">
          <button
            type="button"
            onClick={handleButtonClick}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            {buttonTitle}
          </button>
        </div>
      )}
    </>
  );
};

export default SearchCardHeader;
