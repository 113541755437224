import { useEffect, useRef, useState } from "react";

import { classNames } from "../../../utils/cssUtils";
import {
  buildDob,
  availableGenders,
  availablePronouns,
  availableSexes,
  availableEthnicities,
} from "../../../utils/patientUtils";
import { isPhoneValid, isEmailValid, isDobValid } from "../../../utils/validators";

import Input from "../../UI/Input";
import SelectBox from "../../UI/SelectBox";
import DividerWithLabel from "../../UI/DividerWIthLabel";
import LoadingSpinner from "../../UI/LoadingSpinner";

import "./PatientDetails.css";

const PatientDetails = (props) => {
  const { onChange, onClose, patient, isLoading = false } = props;

  const [firstName, setFirstName] = useState({ value: patient?.first_name || "", error: false, touched: false });
  const [lastName, setLastName] = useState({ value: patient?.last_name || "", error: false, touched: false });
  const [preferredName, setPreferredName] = useState({
    value: patient?.preferred_name || "",
    error: false,
    touched: false,
  });
  const [sex, setSex] = useState({
    value: availableSexes[patient?.sex] || availableSexes[""],
    error: false,
    touched: false,
  });
  const [gender, setGender] = useState({
    value: availableGenders[patient?.gender] || availableGenders[""],
    error: false,
    touched: false,
  });
  const [dob, setDob] = useState({ value: buildDob(patient?.dob) || "", error: false, touched: false });
  const [email, setEmail] = useState({ value: patient?.email || "", error: false, touched: false });
  const [phone, setPhone] = useState({ value: patient?.phone || "", error: false, touched: false });
  const [address, setAddress] = useState({ value: patient?.address || "", error: false, touched: false });
  const [ethnicity, setEthnicity] = useState({
    value: availableEthnicities[patient?.ethnicity] || availableEthnicities[""],
    error: false,
    touched: false,
  });
  const [pronouns, setPronouns] = useState({
    value: availablePronouns[patient?.pronouns] || availablePronouns[""],
    error: false,
    touched: false,
  });

  // Used for managing uploaded file preview
  const [photoFile, setPhotoFile] = useState(null);

  // Existing photo of patient
  const [photo, setPhoto] = useState(patient?.photo || null);

  const [isFormValid, setIsFormValid] = useState(false);

  const photoRef = useRef();

  useEffect(() => {
    if (!firstName.touched) return;

    setFirstName((prev) => {
      return {
        ...prev,
        error: firstName.value.trim().length < 1,
      };
    });
  }, [firstName.touched, firstName.value]);

  useEffect(() => {
    if (!lastName.touched) return;

    setLastName((prev) => {
      return {
        ...prev,
        error: lastName.value.trim().length < 1,
      };
    });
  }, [lastName.touched, lastName.value]);

  useEffect(() => {
    if (!dob.touched) return;

    setDob((prev) => {
      return {
        ...prev,
        error: !isDobValid(dob.value),
      };
    });
  }, [dob.touched, dob.value]);

  useEffect(() => {
    if (!email.touched) return;

    setEmail((prev) => {
      return {
        ...prev,
        error: !isEmailValid(email.value),
      };
    });
  }, [email.touched, email.value]);

  useEffect(() => {
    if (!phone.touched) return;

    setPhone((prev) => {
      return {
        ...prev,
        error: phone.value.length > 0 && !isPhoneValid(phone.value),
      };
    });
  }, [phone]);

  useEffect(() => {
    setIsFormValid(!firstName.error && !lastName.error && !dob.error && !email.error && !phone.error);
  }, [dob.error, email.error, phone.error, firstName.error, lastName.error]);

  const handleRemovePhoto = () => {
    setPhoto(null);
    setPhotoFile(undefined);
  };

  const handleChangePhoto = () => {
    photoRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setPhotoFile(file);

      let reader = new FileReader();
      reader.onload = (e) => {
        setPhoto(URL.createObjectURL(file));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    const data = {
      address: address.value,
      dob: dob.value,
      email: email.value,
      ethnicity: ethnicity.value.value,
      first_name: firstName.value,
      gender: gender.value.value,
      last_name: lastName.value,
      phone: phone.value,
      photo: photoFile,
      preferred_name: preferredName.value,
      pronouns: pronouns.value.value,
      sex: sex.value.value,
    };
    onChange(data);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="divide-y divide-gray-200">
          <div className="pb-6">
            <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
              <div className="-m-1 flex">
                <div className="group inline-flex overflow-hidden rounded-lg border-4 border-white">
                  <input
                    type="file"
                    onChange={onPhotoChange}
                    id="photo-file"
                    name="photo-file"
                    ref={photoRef}
                    className="hidden"
                  />
                  <div className="relative">
                    {photo ? (
                      <img
                        className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                        src={photo}
                        alt={`${firstName} ${lastName}`}
                      />
                    ) : (
                      <span className="inline-block overflow-hidden h-24 w-24 sm:h-40 sm:w-40 lg:h-48 lg:w-48 bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                    <div className="group-hover:block hidden absolute top-14 left-14">
                      <button
                        onClick={handleChangePhoto}
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent opacity-80 bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Change
                      </button>
                      {photo && (
                        <button
                          onClick={handleRemovePhoto}
                          type="button"
                          className="mt-5 opacity-80 inline-flex items-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:ml-6 w-full flex justify-end">
                <button
                  onClick={onClose}
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                >
                  Cancel
                </button>
                <button
                  disabled={!isFormValid}
                  type="button"
                  onClick={handleSubmit}
                  className={classNames(
                    "ml-3 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm",
                    isFormValid
                      ? "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                      : "bg-indigo-400"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-0 sm:py-0">
              <div className="mx-5 my-5 space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 sm:space-y-5">
                  <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                    <div className="space-y-6 sm:space-y-5">
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        label="First Name (required)"
                        value={firstName.value}
                        isValid={!firstName.error}
                        onChange={(val) => setFirstName((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setFirstName((prev) => ({ ...prev, touched: true }))}
                        errorMessage="First name cannot be blank!"
                      />

                      <Input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        label="Last Name (required)"
                        value={lastName.value}
                        isValid={!lastName.error}
                        onChange={(val) => setLastName((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setLastName((prev) => ({ ...prev, touched: true }))}
                        errorMessage="Last name cannot be blank!"
                      />

                      <Input
                        type="email"
                        name="email"
                        placeholder="patient@email.com"
                        label="Email (required)"
                        value={email.value}
                        required={true}
                        isValid={!email.error}
                        onChange={(val) => setEmail((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setEmail((prev) => ({ ...prev, touched: true }))}
                        errorMessage="Email is required!"
                      />

                      {!!patient && (
                        <>
                          <DividerWithLabel label="Patient's Membership" />

                          <div className="grid grid-cols-2 items-center">
                            <span className={classNames("flex items-center", patient.subscription_status || "unknown")}>
                              Membership Status:
                              <span className="inline-flex mx-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium">
                                <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5">
                                  <circle r={3} cx={3} cy={3} />
                                </svg>
                                {patient.subscription_status || "No Subscription"}
                              </span>
                            </span>

                            <div className="w-full">
                              <button
                                type="button"
                                onClick={() =>
                                  window.open("https://dashboard.stripe.com/customers", "_blank", "noopener,noreferrer")
                                }
                                className="w-full rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                              >
                                Manage
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      <DividerWithLabel label="Optional Information" />

                      <Input
                        type="text"
                        name="preferredName"
                        placeholder="Patient's Preferred Name"
                        label="Preferred Name"
                        value={preferredName.value}
                        isValid={true}
                        onChange={(val) => setPreferredName((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setPreferredName((prev) => ({ ...prev, touched: true }))}
                        errorMessage=""
                      />

                      <Input
                        type="date"
                        name="dob"
                        placeholder=""
                        label="Date of Birth"
                        value={dob.value}
                        isValid={!dob.error}
                        onChange={(val) => setDob((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setDob((prev) => ({ ...prev, touched: true }))}
                        errorMessage="Date of birth must be a valid date"
                      />

                      <div className="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="sex" className="block leading-6 text-sm font-medium text-gray-600 mb-2">
                          Sex at Birth
                        </label>
                        <div className="mt-2">
                          <SelectBox
                            activeValue={sex.value}
                            values={Object.values(availableSexes)}
                            onChange={(val) => setSex((prev) => ({ ...prev, value: val, touched: true }))}
                            onBlur={() => setSex((prev) => ({ ...prev, touched: true }))}
                          />
                        </div>
                      </div>

                      <div className="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="sex" className="block leading-6 text-sm font-medium text-gray-600 mb-2">
                          Gender
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <SelectBox
                            activeValue={gender.value}
                            values={Object.values(availableGenders)}
                            onChange={(val) => setGender((prev) => ({ ...prev, value: val, touched: true }))}
                            onBlur={() => setGender((prev) => ({ ...prev, touched: true }))}
                          />
                        </div>
                      </div>

                      <div className="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="sex" className="block leading-6 text-sm font-medium text-gray-600 mb-2">
                          Pronouns
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <SelectBox
                            activeValue={pronouns.value}
                            values={Object.values(availablePronouns)}
                            onChange={(val) => setPronouns((prev) => ({ ...prev, value: val, touched: true }))}
                            onBlur={() => setPronouns((prev) => ({ ...prev, touched: true }))}
                          />
                        </div>
                      </div>

                      <Input
                        type="text"
                        name="phone"
                        placeholder="555-555-5555"
                        label="Phone"
                        value={phone.value}
                        isValid={!phone.error}
                        onChange={(val) => setPhone((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setPhone((prev) => ({ ...prev, touched: true }))}
                        errorMessage="Phone format is incorrect! Ex. 555-555-5555"
                      />

                      <Input
                        type="text"
                        name="address"
                        placeholder="123 Main St. Your Town, CT 010101"
                        label="Address"
                        value={address.value}
                        isValid={true}
                        onChange={(val) => setAddress((prev) => ({ ...prev, value: val, touched: true }))}
                        onBlur={() => setAddress((prev) => ({ ...prev, touched: true }))}
                        errorMessage=""
                      />

                      <div className="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="ethnicity" className="block leading-6 text-sm font-medium text-gray-600 mb-2">
                          Ethnicity
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <SelectBox
                            activeValue={ethnicity.value}
                            values={Object.values(availableEthnicities)}
                            onChange={(val) => setEthnicity((prev) => ({ ...prev, value: val, touched: true }))}
                            onBlur={() => setEthnicity((prev) => ({ ...prev, touched: true }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 px-5 pt-5 grid grid-flow-col grid-cols-2">
            <button
              onClick={onClose}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
              Cancel
            </button>
            <button
              disabled={!isFormValid}
              type="button"
              onClick={handleSubmit}
              className={classNames(
                "ml-3 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm",
                isFormValid
                  ? "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  : "bg-indigo-400"
              )}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientDetails;
