import { useState } from "react";

import { RiDeleteBinFill, RiDeleteBinLine, RiEdit2Fill, RiEdit2Line } from "@remixicon/react";

import { classNames } from "../../../utils/cssUtils";
import { formatDate } from "../../../utils/date";

const MedicationListItem = (props) => {
  const { medication, onDelete, onShowEdit, onLoading } = props;

  const [hoverDelete, setHoverDelete] = useState(false);
  const [hoverEdit, setHoverEdit] = useState(false);

  return (
    <li className="relative flex items-center justify-between px-2 py-1 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200">
      {onLoading && (
        <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
          <div className="flex items-center">
            <span className="text-base mr-4 -ml-24">Loading</span>
            <svg
              className="animate-spin h-8 w-8 text-slate-800"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      )}
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <div className="flex text-sm font-semibold leading-6 text-slate-900 items-center space-x-2">
            <div>{medication.name}</div>
            {!!medication.dose && (
              <>
                <div>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                </div>
                <div>{medication.dose}</div>
              </>
            )}
          </div>
          <p
            className={classNames(
              medication.active
                ? "text-green-700 bg-green-50 ring-green-600/20"
                : "text-slate-600 bg-slate-50 ring-slate-500/10",
              "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
            )}
          >
            {medication.active ? "Active" : "Inactive"}
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-slate-500">
          <p className="whitespace-nowrap">
            Started on <time dateTime={medication.started_at}>{formatDate(new Date(medication.started_at), true)}</time>
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="whitespace-nowrap">
            Ending on <time dateTime={medication.ended_at}>{formatDate(new Date(medication.ended_at), true)}</time>
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <span
          onMouseEnter={() => setHoverDelete(true)}
          onMouseLeave={() => setHoverDelete(false)}
          onClick={() => onDelete(medication.id)}
          className="cursor-pointer text-red-800"
        >
          {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
        </span>
        <span
          onMouseEnter={() => setHoverEdit(true)}
          onMouseLeave={() => setHoverEdit(false)}
          onClick={() => onShowEdit({ show: true, medication })}
          className="cursor-pointer text-indigo-800"
        >
          {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
        </span>
      </div>
    </li>
  );
};

export default MedicationListItem;
