import { useEffect, useState } from "react";

import Paginator from "../../UI/Paginator";
import EditRecommendationForm from "./EditRecommendationForm";

const EditRecommendationList = (props) => {
  const { recommendations, onUpdate, onDelete, onClose } = props;

  const pageSize = 5;

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(recommendations.length);
  const [rawRecommendations, setRawRecommendations] = useState(recommendations);
  const [showRecommendations, setShowRecommendations] = useState(recommendations.slice(0, pageSize));

  useEffect(() => {
    setRawRecommendations(recommendations);
    setShowRecommendations(recommendations.slice(0, pageSize));
    setPage(1);
    setTotalItems(recommendations.length);
  }, [recommendations]);

  const handleGoToPage = (newPage) => {
    setPage(newPage);

    const start = (newPage - 1) * pageSize;
    const end = start + pageSize;
    setShowRecommendations(rawRecommendations.slice(start, end));
  };

  return (
    <div className="mx-auto max-w-7xl px-2">
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5">
          {showRecommendations.map((r) => (
            <EditRecommendationForm
              key={r.id}
              recommendation={r}
              onChange={onUpdate}
              onClose={onClose}
              onDelete={onDelete}
            />
          ))}
        </div>
        <div className="px-4 py-4 sm:px-6">
          <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} pageSize={pageSize} />
        </div>
      </div>
    </div>
  );
};

export default EditRecommendationList;
