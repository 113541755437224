import { useEffect, useState } from "react";

import { classNames } from "../../../utils/cssUtils";

import Input from "../../UI/Input";

const EditHealthGoal = (props) => {
  const { goal, onChange, onClose, onDelete } = props;

  const [content, setContent] = useState({
    value: goal?.content || goal?.health_goal?.content || "",
    error: false,
    touched: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!content.touched) return;

    setContent((prev) => {
      return {
        ...prev,
        error: prev.value.trim().length < 2,
      };
    });
  }, [content.touched, content.value]);

  useEffect(() => {
    setIsFormValid(!content.error);
  }, [content.error]);

  const handleSubmit = () => {
    if (content.value?.length > 0) {
      onChange({ ...goal, content: content.value });
    } else {
      onChange({ ...goal });
    }

    if (goal == null) {
      setContent((prev) => ({ ...prev, value: "", touched: false }));
    }
  };

  const handleDelete = () => {
    onDelete(goal.id);
  };

  return (
    <div className="px-4">
      <div className="mx-5 my-5">
        <div className="mt-3">
          <Input
            type="text"
            name="content"
            value={content.value}
            placeholder="Goal content..."
            onChange={(val) => setContent((prev) => ({ ...prev, value: val, touched: true }))}
            onBlur={() => setContent((prev) => ({ ...prev, touched: true }))}
            isValid={!content.error}
            errorMessage="Goal is required!"
          />
        </div>
        <div className="px-4 py-4">
          <div className="flex justify-end space-x-3">
            {onDelete !== undefined && onDelete !== null && (
              <button
                type="button"
                className="rounded-md border bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={handleDelete}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={!isFormValid}
              className={classNames(
                "inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm bg-indigo-600",
                "focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                isFormValid ? "hover:bg-indigo-700" : "disabled:opacity-50"
              )}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHealthGoal;
