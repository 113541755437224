/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../../providers/ApiProvider";
import { AuthContext } from "../../providers/AuthProvider";

import LoadingSpinner from "../UI/LoadingSpinner";
import RadioGroupList from "../UI/RadioGroupList";

const reasons = [
  { name: "Too expensive" },
  { name: "Hard to use" },
  { name: "Missing features" },
  { name: "Going with different solution" },
  { name: "Didn't meet our expectations" },
];

const CancelSubscription = (props) => {
  const { renews_on, onCancel } = props;

  const navigate = useNavigate();

  const api = useContext(ApiContext);
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState(null);

  const otherRef = useRef();

  const handleSubmit = () => {
    const r = otherRef.current.value === "" ? reason.name : otherRef.current.value;

    setIsLoading(true);
    api.client
      .delete(`/subscriptions?reason=${r}`)
      .then((resp) => {
        setIsLoading(false);
        auth.logout().then(() => navigate("/"));
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleOtherClick = () => {
    if (otherRef.current.rows === 1) {
      otherRef.current.rows = 3;
    }
  };

  const handleOtherBlur = () => {
    if (otherRef.current.value === "") {
      otherRef.current.rows = 1;
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex flex-col items-start">
            <div className="mt-3 ml-4 text-left">
              <div className="mt-2 border-b border-b-slate-400 pb-3">
                {/* <h1 className="text-xl font-bold text-gray-900">We're sorry to see you go</h1> */}
                <p className="text-lg font-medium text-gray-800 mt-2">
                  We're sorry to see you go! Once your subscription expires on {renews_on}, you'll lose all access.
                </p>
              </div>
            </div>
            <div className="mt-3 ml-4 border-b border-b-slate-400 pb-3">
              <ul role="list" className="list-none">
                <li className="flex flex-row space-x-2 my-2">
                  <XCircleIcon className="h-6 w-6 text-red-500"></XCircleIcon>
                  <span className="text-slate-900">Your patients will loose access to their dashboard</span>
                </li>
                <li className="flex flex-row space-x-2 my-2">
                  <XCircleIcon className="h-6 w-6 text-red-500"></XCircleIcon>
                  <span className="text-slate-900">You will loose access to the platform and the tools</span>
                </li>
                <li className="flex flex-row space-x-2 my-2">
                  <CheckCircleIcon className="h-6 w-6 text-green-500"></CheckCircleIcon>
                  <span className="text-slate-900">You will get your patients data as a PDF</span>
                </li>
              </ul>
            </div>
            <div className="mt-3 ml-4 px-4 py-5 overflow-hidden rounded-lg bg-slate-100 shadow">
              <h1 className="mb-2 text-base font-medium text-gray-900">
                Why have you decided to cancel your subscription?
              </h1>
              <RadioGroupList list={reasons} selected={reason} onChange={(val) => setReason(val)} />
              <textarea
                id="other"
                name="other"
                ref={otherRef}
                rows={1}
                placeholder="Other reason..."
                onClick={handleOtherClick}
                onBlur={handleOtherBlur}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-5 flex flex-row-reverse gap-5">
            <button
              type="button"
              className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
              onClick={handleSubmit}
            >
              Continue
            </button>
            <button
              type="button"
              className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={onCancel}
            >
              Don't Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default CancelSubscription;
