import { useContext, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";

const ForgotPasswordPage = () => {
  let api = useContext(ApiContext);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  // eslint-disable-next-line no-unused-vars
  const [email, __] = useState(searchParams.get("email") || "");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  let emailRef = useRef();

  const handleSubmit = () => {
    setIsLoading(true);

    const url = `${window.location.protocol}//${window.location.host}/reset_password`;

    api.client
      .post(`/auth/password`, {
        email: emailRef.current.value,
        redirect_url: url,
      })
      .then((resp) => {
        setSuccessMessage(resp.data.message);
        setIsLoading(false);
        setShowConfirmMessage(true);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {showConfirmMessage ? (
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Check your Email</h2>
              </div>

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">{successMessage}</div>
            </div>
          ) : (
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  Reset Your Password
                </h2>
              </div>

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex min-h-full flex-1 items-center justify-center px-4 py-4 sm:px-6 lg:px-8">
                  <div className="w-full max-w-sm space-y-10">
                    <form className="space-y-3" onSubmit={handleSubmit}>
                      <div className="relative -space-y-px rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            ref={emailRef}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Email address"
                            defaultValue={email}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ForgotPasswordPage;
