function itemType(item) {
  if (item.patient_screening_tool_id) {
    return "screeningTool";
  } else if (item.patients_medication_id) {
    return "medication";
  } else if (item.medical_problem_id) {
    return "medicalProblem";
  } else if (item.patients_health_goal_id) {
    return "healthGoal";
  } else if (item.patients_recommendation_id) {
    return "recommendation";
  } else {
    console.error("Invalid item type.", item);
    return null;
  }
}

function itemId(item) {
  return (
    item.patient_screening_tool_id ||
    item.patients_medication_id ||
    item.medical_problem_id ||
    item.patients_health_goal_id ||
    item.patients_recommendation_id
  );
}

export { itemType, itemId };
