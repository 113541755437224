const DeleteButton = (props) => {
  const { onClick, label = "Delete" } = props;

  return (
    <button
      onClick={onClick}
      type="button"
      className="
      inline-flex px-4 py-2 text-sm items-center justify-center font-medium shadow-sm rounded-md
      text-red-800 ring-1 ring-red-400 hover:bg-red-50 focus:outline-none focus:ring-2
      focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-100
      "
    >
      {label}
    </button>
  );
};

export default DeleteButton;
