/* eslint-disable jsx-a11y/no-redundant-roles */
import AnonTopNav from "../components/Layout/AnonTopNav";
import Footer from "../components/UI/Footer";

const TermsPage = () => {
  return (
    <div className="bg-white">
      <AnonTopNav />
      <main>
        <div className="mx-10">
          <h1 className="text-3xl font-semibold w-full text-center my-5">WEBSITE TERMS OF USE</h1>

          <p className="px-5 py-6 text-sm">
            <strong>Last Modified:</strong> February 1, 2024
          </p>

          <h2 className="text-lg font-medium w-full">Acceptance of the Terms of Use</h2>

          <p className="px-5 py-6 text-sm">
            These terms of use are entered into by and between any user that access the Website ("You") and Pro-Patient
            Tech, Inc. ("Company", "we" or "us"). The following terms and conditions, together with any documents they
            expressly incorporate by reference (collectively, these "Terms of Use"), govern Your access to and use of
            propatienttech.com, including any content, functionality, and services offered on or through
            propatienttech.com (the "Website").
          </p>

          <p className="px-5 py-6 text-sm">
            Please read the Terms of Use carefully before You start to use the Website. By using the Website, You accept
            and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at
            <a href="http://propatienttech.com/terms">http://propatienttech.com/terms</a>, incorporated herein by
            reference. If You do not want to agree to these Terms of Use or the Privacy Policy, You must not access or
            use the Website.
          </p>

          <h2 className="text-lg font-medium w-full">Changes to the Terms of Use</h2>

          <p className="px-5 py-6 text-sm">
            We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
            effective immediately when we post them, and apply to all access to and use of the Website thereafter.
            However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not
            apply to any disputes for which the parties have actual notice on or before the date the change is posted on
            the Website.
          </p>

          <p className="px-5 py-6 text-sm">
            Your continued use of the Website following the posting of revised Terms of Use means that You accept and
            agree to the changes. You are expected to check this page from time to time so You are aware of any changes,
            as they are binding on You.
          </p>

          <h2 className="text-lg font-medium w-full">Accessing the Website and Account Security</h2>

          <p className="px-5 py-6 text-sm">
            We reserve the right to withdraw or modify this Website, and any service or material we provide on the
            Website, in our sole and absolute discretion, without notice. We will not be liable if for any reason all or
            any part of the Website is unavailable at any time or for any period. From time to time, we may restrict
            access to some parts of the Website, or the entire Website, to certain users, including registered users.
          </p>

          <p className="px-5 py-6 text-sm">
            You are responsible for making all arrangements necessary for You to have access to the Website and ensuring
            that all persons who access the Website through Your internet connection are aware of these Terms of Use and
            comply with them.
          </p>

          <p className="px-5 py-6 text-sm">
            To access the Website or some of the resources it offers, You may be asked to provide certain registration
            details or other information. It is a condition of Your use of the Website that all the information You
            provide on the Website is correct, current, and complete. You agree that all information You provide to
            register with this Website or otherwise, including but not limited to through the use of any interactive
            features on the Website, is governed by our Privacy Policy, located at{" "}
            <a href="http://propatienttech.com/privacy">http://propatienttech.com/privacy</a>, and You consent to all
            actions we take with respect to Your information consistent with our Privacy Policy.
          </p>

          <p className="px-5 py-6 text-sm">
            If You choose, or are provided with, a user name, password, or any other piece of information as part of our
            security procedures, You must treat such information as confidential, and You must not disclose it to any
            other person or entity. You also acknowledge that Your account is personal to You and agree not to provide
            any other person with access to this Website or portions of it using Your user name, password, or other
            security information. You agree to notify us immediately of any unauthorized access to or use of Your user
            name or password or any other breach of security. You also agree to ensure that You exit from Your account
            at the end of each session. You should use particular caution when accessing Your account from a public or
            shared computer so that others are not able to view or record Your password or other personal information.
          </p>

          <p className="px-5 py-6 text-sm">
            We have the right to disable any user name, password, or other identifier, whether chosen by You or provided
            by us, at any time in our sole discretion for any or no reason, including if, in our opinion, You have
            violated any provision of these Terms of Use.
          </p>

          <h2 className="text-lg font-medium w-full">Intellectual Property Rights</h2>

          <p className="px-5 py-6 text-sm">
            The Website and all of its content, features, and functionality (including but not limited to all
            information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
            thereof), are owned by the Company, its licensors, or other providers of such material and are protected by
            United States and international copyright, trademark, patent, trade secret, and other intellectual property
            or proprietary rights laws.
          </p>

          <p className="px-5 py-6 text-sm">
            These Terms of Use permit You to use the Website for Your personal, non-commercial use only. You must not
            reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
            download, store, or transmit any of the material on our Website, except as follows:
          </p>

          <ul role="list" className="list-disc px-5 py-3">
            <li>
              Your computer may temporarily store copies of such materials in RAM incidental to Your accessing and
              viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by Your Web browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages of the Website for Your own personal,
              non-commercial use and not for further reproduction, publication, or distribution.
            </li>
          </ul>

          <p className="px-5 py-6 text-sm">You must not:</p>

          <ul role="list" className="list-disc px-5 py-3">
            <li>Modify copies of any materials from this site.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or any graphics separately from the
              accompanying text.
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials
              from this site.
            </li>
          </ul>

          <p className="px-5 py-6 text-sm">
            You must not access or use for any commercial purposes any part of the Website or any services or materials
            available through the Website.
          </p>

          <p className="px-5 py-6 text-sm">
            If You print, copy, modify, download, or otherwise use or provide any other person with access to any part
            of the Website in breach of the Terms of Use, Your right to use the Website will end immediately and You
            must, at our option, return or destroy any copies of the materials You have made. No right, title, or
            interest in or to the Website or any content on the Website is transferred to You, and all rights not
            expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms
            of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
          </p>

          <h2 className="text-lg font-medium w-full">Trademarks</h2>

          <p className="px-5 py-6 text-sm">
            The Company name, the term "Pro-Patient Tech, Inc." "Caspia Health," and all related names, logos, product
            and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You
            must not use such marks without the prior written permission of the Company. All other names, logos, product
            and service names, designs, and slogans on this Website are the trademarks of their respective owners.
          </p>

          <h2 className="text-lg font-medium w-full">Prohibited Uses</h2>

          <p className="px-5 py-6 text-sm">
            You may use the Website only for lawful purposes and in accordance with these Terms of Use.
          </p>

          <p className="px-5 py-6 text-sm">You agree not to use the Website:</p>

          <ul role="list" className="list-disc px-5 py-3">
            <li>
              In any way that violates any applicable federal, state, local, or international law or regulation
              (including, without limitation, any laws regarding the export of data or software to and from the US or
              other countries).
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing
              them to inappropriate content, asking for personally identifiable information, or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or promotional material, including any "junk
              mail", "chain letter", "spam", or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other
              person or entity (including, without limitation, by using email addresses associated with any of the
              foregoing or the name "Pro-Patient Tech" in an e-mail address).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or
              which, as determined by us, may harm the Company or users of the Website or expose them to liability.
            </li>
          </ul>

          <p className="px-5 py-6 text-sm">Additionally, You agree not to:</p>

          <ul role="list" className="list-disc px-5 py-3">
            <li>
              Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with
              any other party's use of the Website, including their ability to engage in real time activities through
              the Website.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose,
              including monitoring or copying any of the material on the Website.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized
              purpose without our prior written consent.
            </li>
            <li>Use any device, software, or routine that interferes with the proper working of the Website.</li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
              technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the
              server on which the Website is stored, or any server, computer, or database connected to the Website.
            </li>
            <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Otherwise attempt to interfere with the proper working of the Website.</li>
          </ul>

          <h2 className="text-lg font-medium w-full">Reliance on Information Posted</h2>

          <p className="px-5 py-6 text-sm">
            The information presented on or through the Website is made available solely for general information
            purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance You
            place on such information is strictly at Your own risk. We disclaim all liability and responsibility arising
            from any reliance placed on such materials by You or any other visitor to the Website, or by anyone who may
            be informed of any of its contents.
          </p>

          <h2 className="text-lg font-medium w-full">Changes to the Website</h2>

          <p className="px-5 py-6 text-sm">
            We may update the content on this Website from time to time, but its content is not necessarily complete or
            up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no
            obligation to update such material.
          </p>

          <h2 className="text-lg font-medium w-full">Information About You and Your Visits to the Website</h2>

          <p className="px-5 py-6 text-sm">
            All information we collect on this Website is subject to our Privacy Policy, located at{" "}
            <a href="http://propatienttech.com/privacy">http://propatienttech.com/privacy</a>. By using the Website, You
            consent to all actions taken by us with respect to Your information in compliance with the Privacy Policy.
          </p>

          <h2 className="text-lg font-medium w-full">Linking to the Website</h2>

          <p className="px-5 py-6 text-sm">
            You may link to our homepage, provided You do so in a way that is fair and legal and does not damage our
            reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form
            of association, approval, or endorsement on our part. Furthermore, You must not:
          </p>

          <ul role="list" className="list-disc px-5 py-3">
            <li>Establish a link from any website that is not owned by You.</li>
            <li>
              Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for
              example, framing, deep linking, or in-line linking.
            </li>
            <li>Link to any part of the Website other than the homepage.</li>
            <li>
              Otherwise take any action with respect to the materials on this Website that is inconsistent with any
              other provision of these Terms of Use.
            </li>
          </ul>

          <p className="px-5 py-6 text-sm">
            You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We
            reserve the right to withdraw linking permission without notice.
          </p>

          <h2 className="text-lg font-medium w-full">Links from the Website</h2>

          <p className="px-5 py-6 text-sm">
            If the Website contains links to other sites and resources provided by third parties, these links are
            provided for Your convenience only. This includes links contained in advertisements, including banner
            advertisements and sponsored links. We have no control over the contents of those sites or resources, and
            accept no responsibility for them or for any loss or damage that may arise from Your use of them. If You
            decide to access any of the third-party websites linked to this Website, You do so entirely at Your own risk
            and subject to the terms and conditions of use for such websites.
          </p>

          <h2 className="text-lg font-medium w-full">Geographic Restrictions</h2>

          <p className="px-5 py-6 text-sm">
            The corporation of the Website is based in the state of Delaware in the United States. We provide this
            Website for use only by persons located in the United States. We make no claims that the Website or any of
            its content is accessible or appropriate outside of the United States. Access to the Website may not be
            legal by certain persons or in certain countries. If You access the Website from outside the United States,
            You do so on Your own initiative and are responsible for compliance with local laws.
          </p>

          <h2 className="text-lg font-medium w-full">Disclaimer of Warranties</h2>

          <p className="px-5 py-6 text-sm">
            You understand that we cannot and do not guarantee or warrant that files available for downloading from the
            internet or the Website will be free of viruses or other destructive code. You are responsible for
            implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus
            protection and accuracy of data input and output, and for maintaining a means external to our site for any
            reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
            OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
            TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
            ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
          </p>

          <p className="px-5 py-6 text-sm">
            YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN
            RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN
            "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
            COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE
            FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
            WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
            ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>

          <p className="px-5 py-6 text-sm">
            TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
            NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
          </p>

          <p className="px-5 py-6 text-sm">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>

          <h2 className="text-lg font-medium w-full">Limitation of Liability</h2>

          <p className="px-5 py-6 text-sm">
            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
            LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES
            LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
            CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <h2 className="text-lg font-medium w-full">Indemnification</h2>

          <p className="px-5 py-6 text-sm">
            You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service
            providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
            suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
            losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to Your
            violation of these Terms of Use or Your use of the Website, including, but not limited to, Your use of the
            Website's content, services, and products other than as expressly authorized in these Terms of Use or Your
            use of any information obtained from the Website.
          </p>

          <h2 className="text-lg font-medium w-full">Governing Law and Jurisdiction</h2>

          <p className="px-5 py-6 text-sm">
            All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or
            related thereto (in each case, including non-contractual disputes or claims), shall be governed by and
            construed in accordance with the internal laws of the State of Delaware without giving effect to any choice
            or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).
          </p>

          <h2 className="text-lg font-medium w-full">Arbitration</h2>

          <p className="px-5 py-6 text-sm">
            You and the Company shall submit any disputes arising from these Terms of Use or the use of the Website,
            including disputes arising from or concerning their interpretation, violation, invalidity, non-performance,
            or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration
            Association applying Delaware law.
          </p>

          <h2 className="text-lg font-medium w-full">Waiver and Severability</h2>

          <p className="px-5 py-6 text-sm">
            No waiver of by the Company of any term or condition set out in these Terms of Use shall be deemed a further
            or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
            of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such
            right or provision.
          </p>

          <p className="px-5 py-6 text-sm">
            If any provision of these Terms of Use is held by a court or arbitrator to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that
            the remaining provisions of the Terms of Use will continue in full force and effect.
          </p>

          <h2 className="text-lg font-medium w-full">Entire Agreement</h2>

          <p className="px-5 py-6 text-sm">
            These Terms of Use and our Privacy Policy constitute the sole and entire agreement between You and the
            Company regarding the Website and supersede all prior and contemporaneous understandings, agreements,
            representations, and warranties, both written and oral, regarding the Website.
          </p>

          <h2 className="text-lg font-medium w-full">Your Comments and Concerns</h2>

          <p className="px-5 py-6 text-sm">This website is operated by Pro-Patient Tech, Inc.</p>

          <p className="px-5 py-6 text-sm">
            All feedback, comments, requests for technical support, and other communications relating to the Website
            should be directed to: <a href="mailto:info@propatienttech.com">info@propatienttech.com</a>.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TermsPage;
