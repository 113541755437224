function truncateString(str, len = 30) {
  if (str.length > len) {
    return str.substring(0, len - 1) + "...";
  }

  return str;
}

function toSnakeCase(str) {
  str = str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  str = str.replaceAll(" _", "_");

  return str.replaceAll(" ", "_").replace(/(^_*|_*$)/g, "");
}

export { truncateString, toSnakeCase };
