import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parse,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import { classNames } from "../../utils/cssUtils";

function formatAppointments(appts) {
  return appts.reduce((acc, cur) => {
    const date = parse(cur.date, "yyyy-MM-dd", new Date());
    acc[date] ||= [];
    acc[date].push(cur);

    return acc;
  }, {});
}

const MonthlyCalendar = (props) => {
  const { today, showAppointment } = props;

  const appointments = formatAppointments(props.appointments);

  let firstDayOfCurrentMonth = startOfMonth(today);
  let days = eachDayOfInterval({
    start: startOfWeek(firstDayOfCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayOfCurrentMonth)),
  }).map((date) => ({
    date,
  }));

  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 flex flex-auto flex-col">
      <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
        <div className="bg-white py-2">Sun</div>
        <div className="bg-white py-2">Mon</div>
        <div className="bg-white py-2">Tue</div>
        <div className="bg-white py-2">Wed</div>
        <div className="bg-white py-2">Thu</div>
        <div className="bg-white py-2">Fri</div>
        <div className="bg-white py-2">Sat</div>
      </div>
      <div className="flex flex-auto bg-gray-200 text-xs leading-6 text-gray-700">
        <div
          className={classNames(
            "w-full grid grid-cols-7 gap-px",
            days.length % 5 === 0 ? "grid-rows-5" : "grid-rows-6"
          )}
        >
          {days.map((day) => (
            <div
              key={day.date}
              className={classNames(isSameMonth(day) ? "bg-white" : "bg-gray-50 text-gray-500", "relative px-3 py-2")}
            >
              <time
                dateTime={day.date}
                className={
                  isSameDay(day.date, today)
                    ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                    : undefined
                }
              >
                {format(day.date, "d")}
              </time>
              {!!appointments[day.date] && appointments[day.date].length > 0 && (
                <ol className="mt-2">
                  {appointments[day.date].slice(0, 2).map((appointment) => (
                    <li key={appointment.id} className="cursor-pointer" onClick={() => showAppointment(appointment)}>
                      <span className="group flex">
                        <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                          {appointment.type} with {appointment.first_name} {appointment.last_name}
                        </p>
                        <time
                          dateTime={appointment.date}
                          className="ml-3 flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                        >
                          {appointment.time}
                        </time>
                      </span>
                    </li>
                  ))}
                  {appointments[day.date].length > 2 && (
                    <li className="text-gray-500">+ {appointments[day.date].length - 2} more</li>
                  )}
                </ol>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MonthlyCalendar;
