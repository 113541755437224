/* eslint-disable jsx-a11y/no-redundant-roles */
import { CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const includedFeatures = [
  "All Screening Tools",
  ">200 Recommendation Library",
  "Automatic Scoring",
  "100 Patients",
  "$5/month for each additional 100 patients",
  "You own your data",
];

const disablePricing = true;

const Pricing = () => {
  return (
    <div className="bg-white py-24" id="pricing-block">
      <div className="mx-auto max-w-7xl px-6">
        {disablePricing ? (
          <div className="mx-auto max-w-3xl sm:text-center">
            <h2 className="text-3xl font-medium tracking-wide text-slate-900">
              Pro-Patient Tech FREE through the rest of 2024.
              <br />
              Click{" "}
              <Link to="/register" className="font-bold hover:underline">
                here
              </Link>{" "}
              to Register, and use the coupon code LIFESTYLEMEDICINE24.
              <br />
              Starting in 2025, we will charge $150/month for all the basic
              features. An AI scribe add-on will also be available for
              $20/month.
            </h2>
          </div>
        ) : (
          <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 mt-10 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Affordable Monthly Price
              </h3>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                  What's included
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Monthly Subscription
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      $10
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      USD
                    </span>
                  </p>
                  <a
                    href="/register"
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get access
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    No hidden fees. Cancel anytime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;