/* eslint-disable jsx-a11y/anchor-is-valid */
import { classNames } from "../../utils/cssUtils";

const Tabs = (props) => {
  const { tabs, onChange, curTab } = props;

  return (
    <div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              className={classNames(
                tab.value === curTab.value ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              onClick={() => onChange(tab)}
            >
              {tab.icon && (
                <tab.icon
                  className={classNames(
                    curTab.value === tab.value ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                />
              )}
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.value === curTab.value ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
