import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import {
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiArrowDownSLine,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiFileList3Line,
  RiFontColor,
  RiH1,
  RiH2,
  RiH3,
  RiIndentDecrease,
  RiIndentIncrease,
  RiItalic,
  RiLink,
  RiListOrdered2,
  RiListUnordered,
  RiPaintFill,
  RiStrikethrough,
  RiUnderline,
} from "@remixicon/react";

import { classNames } from "../../../utils/cssUtils";

import SelectBox from "../SelectBox";
import ColorPicker from "../ColorPicker";
import LinkInput from "../LinkInput";

const availableFormatOptions = {
  normal: {
    id: 0,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiFileList3Line size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Normal</span>
      </span>
    ),
    value: "normal",
  },
  heading1: {
    id: 1,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiH1 size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Heading 1</span>
      </span>
    ),
    value: "heading1",
  },
  heading2: {
    id: 2,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiH2 size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Heading 2</span>
      </span>
    ),
    value: "heading2",
  },
  heading3: {
    id: 3,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiH3 size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Heading 3</span>
      </span>
    ),
    value: "heading3",
  },
  bullet: {
    id: 4,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiListUnordered size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Bullet List</span>
      </span>
    ),
    value: "bullet",
  },
  ordered: {
    id: 5,
    name: (
      <span className="flex items-center">
        <span className="inline-block flex-shrink-0">
          <RiListOrdered2 size={12} />{" "}
        </span>
        <span className="ml-3 block truncate">Numbered List</span>
      </span>
    ),
    value: "ordered",
  },
};

function getFormatOption(editor) {
  if (editor.isActive("heading", { level: 1 })) {
    return availableFormatOptions.heading1;
  } else if (editor.isActive("heading", { level: 2 })) {
    return availableFormatOptions.heading2;
  } else if (editor.isActive("heading", { level: 3 })) {
    return availableFormatOptions.heading3;
  } else if (editor.isActive("bulletList")) {
    return availableFormatOptions.bullet;
  } else if (editor.isActive("orderedList")) {
    return availableFormatOptions.ordered;
  } else {
    return availableFormatOptions.normal;
  }
}

const TopMenu = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const color = editor.getAttributes("textStyle").color;
  const highlight = editor.getAttributes("highlight").color?.hex;
  const link = editor.getAttributes("link").href;

  const handleChangeFormat = (formatOption) => {
    if (formatOption.value === "heading1") {
      editor.chain().focus().toggleHeading({ level: 1 }).run();
    } else if (formatOption.value === "heading2") {
      editor.chain().focus().toggleHeading({ level: 2 }).run();
    } else if (formatOption.value === "heading3") {
      editor.chain().focus().toggleHeading({ level: 3 }).run();
    } else if (formatOption.value === "bullet") {
      editor.chain().focus().toggleBulletList().run();
    } else if (formatOption.value === "ordered") {
      editor.chain().focus().toggleOrderedList().run();
    } else {
      editor.chain().focus().clearNodes().run();
    }
  };

  return (
    <div className="flex border-b border-slate-200 divide-x py-2 z-20">
      <div className="flex gap-x-2 px-2">
        <button
          type="button"
          className="rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-xl hover:bg-slate-100 disabled:opacity-50"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <RiArrowGoBackLine size={12} />
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-xl hover:bg-slate-100"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <RiArrowGoForwardLine size={12} />
        </button>
      </div>
      <div className="flex gap-x-2 px-2 min-w-40">
        <SelectBox
          activeValue={getFormatOption(editor)}
          values={Object.values(availableFormatOptions)}
          onChange={handleChangeFormat}
        />
      </div>
      <div className="flex gap-x-2 px-2">
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.isActive("bold") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          <RiBold size={12} />
        </button>
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.isActive("italic") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          <RiItalic size={12} />
        </button>
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.isActive("underline") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        >
          <RiUnderline size={12} />
        </button>
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.isActive("strike") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
        >
          <RiStrikethrough size={12} />
        </button>
      </div>
      <div className="flex gap-x-2 px-2 items-center">
        <Popover className="relative items-center">
          <PopoverButton className="inline-flex rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400">
            <RiFontColor size={12} />
          </PopoverButton>

          <PopoverPanel
            transition
            className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="w-64 rounded-xl leading-6 shadow-lg ring-1 ring-slate-300 bg-slate-50">
              <ColorPicker active={color} onChange={(color) => editor.chain().focus().setColor(color.hex).run()} />
            </div>
          </PopoverPanel>
        </Popover>

        <Popover className="relative items-center">
          <PopoverButton className="inline-flex rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400">
            <RiPaintFill size={12} />
          </PopoverButton>

          <PopoverPanel
            transition
            className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="w-64 rounded-xl leading-6 shadow-lg ring-1 ring-slate-300 bg-slate-50">
              <ColorPicker
                active={highlight}
                reset={true}
                onChange={(h) => editor.chain().focus().setHighlight({ color: h }).run()}
                onReset={() => editor.chain().focus().unsetHighlight().run()}
              />
            </div>
          </PopoverPanel>
        </Popover>
      </div>
      <div className="flex gap-x-2 px-2">
        <button
          type="button"
          className="rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <RiListUnordered size={12} />
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <RiListOrdered2 size={12} />
        </button>
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.can().sinkListItem("listItem") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().sinkListItem("listItem").run()}
          disabled={!editor.can().sinkListItem("listItem")}
        >
          <RiIndentIncrease size={12} />
        </button>
        <button
          type="button"
          className={classNames(
            "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
            editor.can().liftListItem("listItem") ? "text-slate-900" : "text-slate-400"
          )}
          onClick={() => editor.chain().focus().liftListItem("listItem").run()}
          disabled={!editor.can().liftListItem("listItem")}
        >
          <RiIndentDecrease size={12} />
        </button>
      </div>
      <div className="flex gap-x-2 px-2 items-center">
        <Popover className="relative items-center">
          <PopoverButton className="inline-flex rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400">
            <RiLink size={12} />
          </PopoverButton>

          <PopoverPanel
            transition
            className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="w-96 rounded-xl leading-6 shadow-lg ring-1 ring-slate-300 bg-slate-50">
              <LinkInput
                link={link}
                onChange={(href) => editor.chain().focus().extendMarkRange("link").setLink({ href: href }).run()}
                onRemove={() => editor.chain().focus().extendMarkRange("link").unsetLink().run()}
              />
            </div>
          </PopoverPanel>
        </Popover>
      </div>
      <div className="flex gap-x-2 px-2 items-center">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <MenuButton className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
              {editor.isActive({ textAlign: "left" }) && <RiAlignLeft size={12} />}
              {editor.isActive({ textAlign: "center" }) && <RiAlignCenter size={12} />}
              {editor.isActive({ textAlign: "right" }) && <RiAlignRight size={12} />}
              {editor.isActive({ textAlign: "justify" }) && <RiAlignJustify size={12} />}
              <RiArrowDownSLine className="-mr-1 h-5 w-5 text-slate-400" />
            </MenuButton>
          </div>

          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <MenuItem>
              <button
                type="button"
                className={classNames(
                  "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
                  editor.isActive({ textAlign: "left" }) ? "text-slate-900" : "text-slate-400"
                )}
                onClick={() => editor.chain().focus().setTextAlign("left").run()}
              >
                <RiAlignLeft size={12} />
              </button>
            </MenuItem>
            <MenuItem>
              <button
                type="button"
                className={classNames(
                  "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
                  editor.isActive({ textAlign: "center" }) ? "text-slate-900" : "text-slate-400"
                )}
                onClick={() => editor.chain().focus().setTextAlign("center").run()}
              >
                <RiAlignCenter size={12} />
              </button>
            </MenuItem>
            <MenuItem>
              <button
                type="button"
                className={classNames(
                  "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
                  editor.isActive({ textAlign: "right" }) ? "text-slate-900" : "text-slate-400"
                )}
                onClick={() => editor.chain().focus().setTextAlign("right").run()}
              >
                <RiAlignRight size={12} />
              </button>
            </MenuItem>
            <MenuItem>
              <button
                type="button"
                className={classNames(
                  "rounded-md bg-white px-2 py-2 text-sm font-semibold hover:rounded-md hover:ring-1 hover:ring-slate-400",
                  editor.isActive({ textAlign: "justify" }) ? "text-slate-900" : "text-slate-400"
                )}
                onClick={() => editor.chain().focus().setTextAlign("justify").run()}
              >
                <RiAlignJustify size={12} />
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </div>
  );
};

export default TopMenu;
