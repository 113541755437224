import { useContext, useEffect, useState } from "react";

import { useDebounce } from "@uidotdev/usehooks";

import {
  RiStickyNoteFill,
  RiStickyNoteLine,
  RiDeleteBinLine,
  RiEdit2Line,
  RiEdit2Fill,
  RiDeleteBinFill,
} from "@remixicon/react";

import { classNames } from "../../../utils/cssUtils";

import { ApiContext } from "../../../providers/ApiProvider";

import LoadingSpinner from "../../UI/LoadingSpinner";
import TextArea from "../../UI/TextArea";

const statuses = {
  active: "text-indigo-700 bg-indigo-50 ring-indigo-600/20",
  inactive: "text-slate-600 bg-slate-50 ring-slate-500/10",
  inactive_relevant: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
  negative: "text-red-700 bg-red-50 ring-red-600/20",
};

const MedicalProblemItem = (props) => {
  const { patientId, problem, onDelete, onShowEdit, onUpdate, isDeleting } = props;

  const api = useContext(ApiContext);

  const [firstRender, setFirstRender] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [notes, setNotes] = useState(problem.notes);
  const [hoverEdit, setHoverEdit] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);

  const debounce = useDebounce(notes, 500);

  useEffect(() => {
    if (firstRender || notes === problem.notes) {
      setFirstRender(false);
      return;
    }

    if (debounce) {
      setIsSaving(true);
      api.client
        .put(`/patients/${patientId}/medical_history/${problem.id}`, {
          medical_problem: { notes: debounce },
        })
        .then((resp) => {
          setNotes(resp.data.medical_problem.notes);
          onUpdate(resp.data.medical_problem);
          setIsSaving(false);
        })
        .catch((resp) => {
          setIsSaving(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  return (
    <li className="relative flex flex-col px-2 py-1 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200">
      <div className="flex items-center justify-between w-full">
        {isDeleting && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div className="flex items-center">
              <span className="text-base mr-4 -ml-24">Loading</span>
              <svg
                className="animate-spin h-8 w-8 text-slate-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        )}
        <div className="min-w-0">
          <div className="flex items-start gap-x-3">
            <div className="flex text-sm font-semibold leading-6 text-slate-900 items-center space-x-2">
              <div>{problem.name}</div>
              <div className="text-sm text-slate-500">
                {!!problem.diagnostic_code && `[${problem.diagnostic_code}]`}
              </div>
            </div>
            <p
              className={classNames(
                statuses[problem.status],
                "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
              )}
            >
              {problem.status === "inactive_relevant" ? "inactive (relevant)" : problem.status}
            </p>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <span
            onClick={() => setShowNote((prev) => !prev)}
            className="cursor-pointer text-indigo-800"
            data-tooltip-id="med-problem"
            data-tooltip-content={showNote ? "Click to hide the note" : "Click to see note"}
          >
            {showNote ? <RiStickyNoteFill size={20} /> : <RiStickyNoteLine size={20} />}
          </span>
          <span
            onMouseEnter={() => setHoverDelete(true)}
            onMouseLeave={() => setHoverDelete(false)}
            onClick={() => onDelete(problem.id)}
            className="cursor-pointer text-red-800"
          >
            {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
          </span>
          <span
            onMouseEnter={() => setHoverEdit(true)}
            onMouseLeave={() => setHoverEdit(false)}
            onClick={() => onShowEdit({ show: true, problem })}
            className="cursor-pointer text-indigo-800"
          >
            {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
          </span>
        </div>{" "}
      </div>
      {showNote && (
        <div className="relative flex my-2 text-sm text-slate-500 w-full px-5">
          <TextArea
            name="notes"
            value={problem.notes}
            rows={3}
            wFull={true}
            onChange={(val) => setNotes(val)}
            onBlur={(val) => setNotes(val)}
          />
          <div className="absolute top-3 right-3">
            {isSaving && <LoadingSpinner small color="text-indigo-400" showLoadingText={false} />}
          </div>
        </div>
      )}
    </li>
  );
};

export default MedicalProblemItem;
