import { useContext, useEffect, useState } from "react";

import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";

import { ApiContext } from "../../providers/ApiProvider";

import { useStripeConnect } from "../../hooks/useStripeConnect";

import LoadingSpinner from "../UI/LoadingSpinner";

const Stripe = (props) => {
  const { onSubmit } = props;

  const api = useContext(ApiContext);

  const [connectedAccountId, setConnectedAccountId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { stripeConnectInstance, isLoading: stripeScreenLoading } = useStripeConnect(connectedAccountId);

  useEffect(() => {
    setIsLoading(true);

    api.client
      .post("/stripe/accounts")
      .then((response) => {
        setConnectedAccountId(response.data.account);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [api.client]);

  const handleOnboardingExit = () => {
    onSubmit(connectedAccountId);
  };

  return (
    <>
      {isLoading || stripeScreenLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="content">
          {stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding onExit={handleOnboardingExit} />
            </ConnectComponentsProvider>
          )}
        </div>
      )}
    </>
  );
};

export default Stripe;
