import { useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "sonner";

import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../providers/ApiProvider";

import Pricing from "../components/Static/Pricing";
import ForClinicians from "../components/Static/ForClinicians";

export default function LandingPage(props) {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const notifyBottomRef = useRef();

  function handleSubmit(e, email) {
    e.preventDefault();

    api.client.post("/email_signups", { email: email }).then(() => {
      toast.success("Thanks for your interest. We'll follow up soon with more details.");
    });
  }

  const features = [
    {
      name: "Smart Questionnaires",
      description: "asynchronously collect health lifestyle information through smart, user-friendly questionnaires.",
    },
    {
      name: "Automatic Scoring",
      description:
        "and analysis of health and lifestyle information, producing scores for screening tools, a score for each pillar, and overall Lifestyle Score.",
    },
    {
      name: "Smart Action Plan.",
      description:
        "Explainable AI-generated lifestyle change recommendation suggestions for the physician to assign to the patient's action plan.",
    },
    {
      name: "Resource Library.",
      description:
        "Automatic resources for each of the >200 built-in recommendations and the ability to create custom recommendations.",
    },
    {
      name: "Co-Owned Action Plan.",
      description:
        "Co-ownership of the patient's action plan, including patient's ability to track certain assigned action items to help patients change habits and meet goals.",
    },
    {
      name: "Integrations",
      description:
        "with wearable technology, and app integrations, e.g. MyFitnessPal, FitBit, and Apple Health (MyChart)",
    },
  ];

  return (
    <>
      {/* Hero section */}
      <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div>
            <div>
              <img className="h-auto w-25" src="images/logo_text_transparent.png" alt="Pro-Patient Tech" />
            </div>
            <div className="mt-20">
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Patients over technology
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Integrate Lifestyle Medicine care seamlessly into your practice with our patient-first platform.
                </p>
              </div>
              <div className="mt-12 sm:flex sm:w-full sm:max-w-lg">
                <button
                  type="button"
                  onClick={() => navigate("/register")}
                  className="block w-full sm:h-20 rounded-md border border-transparent bg-teal-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:px-10"
                >
                  <div className="flex items-center justify-center">
                    Get Started
                    <ChevronRightIcon className="w-5 h-5 ml-3 -mr-1" aria-hidden="true" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Image of the app screen */}
        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 lg:mt-36 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div className="relative lg:-mr-40 pr-4 md:pr-0 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:!pl-12">
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-auto lg:w-[28rem] xl:w-[36rem] lg:max-w-none"
                src="images/app-landing-page3.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 mt-6">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">{feature.name}</dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>

      <Pricing />
      <ForClinicians />

      {/* CTA section */}
      <div className="relative sm:py-16 mt-10">
        <div aria-hidden="true" className="hidden lg:block">
          <div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
          <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative overflow-hidden rounded-2xl bg-blue-500 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-blue-400 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-blue-600 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative" id="signup-block">
              <div className="sm:text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Sign up for our occasional updates.
                </h2>
              </div>
              <form
                onSubmit={(e) => handleSubmit(e, notifyBottomRef.current.value)}
                className="mt-12 sm:mx-auto sm:flex sm:max-w-lg"
              >
                <div className="min-w-0 flex-1">
                  <label htmlFor="cta-email" className="sr-only">
                    Email address
                  </label>
                  <input
                    ref={notifyBottomRef}
                    id="cta-email"
                    type="email"
                    className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent bg-gray-900 px-5 py-3 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
                  >
                    Notify me
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
