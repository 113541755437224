import axios from "axios";

import { createContext } from "react";

import { toast } from "sonner";

const LOCAL_STORAGE_KEY = "physicianTokens";

const ApiContext = createContext({
  client: {},
});

const ApiProvider = ({ baseURL, children }) => {
  const client = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  client.interceptors.request.use((config) => {
    const tokenData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (tokenData !== null) {
      config.headers["access-token"] = tokenData.accessToken;
      config.headers["client"] = tokenData.clientToken;
      config.headers["uid"] = tokenData.uidToken;
    }

    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (response.headers.get("access-token")) {
        const tokenData = {
          accessToken: response.headers["access-token"],
          clientToken: response.headers["client"],
          uidToken: response.headers["uid"],
        };

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokenData));
      }

      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        const message = "That didn't work.";
        const subMessage = "Please try again!";
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 500) {
        const message = "We encountered an error. Please reload the page.";
        const subMessage = "Our team has been notified and will investigate!";
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 404) {
        const message = "We couldn't find that.";
        const subMessage = "Please try again";
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 402) {
        const message = error.response.data.errors[0];
        const subMessage = (
          <>
            Please email us <a href="mailto:help@propatienttech.com">help@propatienttech.com</a> to reactivate your
            account
          </>
        );
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 406) {
        const errors = error.response.data.errors || [];
        toast.error("Failed to save note.", { description: errors.join("\n") });
      } else {
        const message = "We encountered an error. Please reload the page.";
        const subMessage = error?.response?.data?.errors;
        toast.error(message, { description: subMessage });
      }

      return Promise.reject(error);
    }
  );

  const context = {
    client,
  };

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider, LOCAL_STORAGE_KEY };
