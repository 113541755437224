import { useContext, useEffect, useState } from "react";

import { TrashIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../../providers/ApiProvider";

import DetailsSlideOver from "../UI/DetailsSlideOver";
import LoadingSpinner from "../UI/LoadingSpinner";
import Paginator from "../UI/Paginator";
import SearchCardHeader from "../UI/SearchCardHeader";
import Table from "../UI/Table";

import ClinicUserDetails from "./ClinicUserDetails";

const ClinicUsers = () => {
  const tableHeader = ["photo", "name", "phone", "email", ""];

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState({ open: false, user: null });

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.client
      .get("/users", { params: { page: page, query: searchQuery } })
      .then((resp) => {
        setUsers(resp.data.users);
        setTotalItems(resp.data.meta.total);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, page, searchQuery]);

  const handleGoToPage = (newPage) => {
    setIsLoading(true);
    setPage(newPage);
  };

  const handleSearch = (val) => {
    setIsLoading(true);
    setPage(1);
    setSearchQuery(val);
  };

  const handleAdd = (data) => {
    setIsLoading(true);
    api.client
      .post(
        "/users",
        { user: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setShowDetails(false);
        setUsers((prev) => [...prev, resp.data.user]);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    api.client
      .delete(`/users/${id}`)
      .then((resp) => {
        setUsers((prev) => {
          const res = prev.filter((t) => t.id !== id);
          return [...res];
        });
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (data) => {
    setIsLoading(true);
    api.client
      .put(
        `/users/${data.id}`,
        {
          user: data,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setShowDetails(false);
        setUsers((prev) => {
          const res = prev.map((user) => {
            if (user.id === data.id) {
              return resp.data.user;
            } else {
              return user;
            }
          });

          return [...res];
        });
        setIsLoading(false);
        // NOTE don't hide the details screen to allow editing multiple
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleAddOrUpdate = (data) => {
    data.id ? handleUpdate(data) : handleAdd(data);
  };

  const getDetailsTitle = () => {
    return showDetails.user ? `Edit ${showDetails.user.first_name} ${showDetails.user.last_name}` : "Add User";
  };

  const buildUserRow = (user) => {
    return (
      <>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
          {user.photo ? (
            <img className="h-8 w-8 rounded-full" src={user.photo} alt={`${user.first_name} ${user.last_name}`} />
          ) : (
            <span className="inline-block overflow-hidden h-8 w-8 rounded-full bg-gray-100">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </td>
        <td className="text-center">
          {user.title} {user.first_name} {user.last_name}
        </td>
        <td className="text-center">{user.phone}</td>
        <td className="text-center">{user.email}</td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            type="button"
            onClick={(e) => handleDelete(e, user.id)}
            className="z-90 inline-flex items-center rounded-md border bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <TrashIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </td>
      </>
    );
  };

  return (
    <>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6 flex">
            <SearchCardHeader
              onSearch={handleSearch}
              query={searchQuery}
              onButtonClick={() => setShowDetails({ open: true, user: null })}
              buttonTitle="Add User"
            />
          </div>
          <div className="px-4 py-5 sm:p-6">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Table
                headers={tableHeader}
                items={users}
                buildRow={buildUserRow}
                onClick={(user) => setShowDetails({ open: true, user: user })}
              />
            )}
          </div>
          <div className="px-4 py-4 sm:px-6">
            <Paginator totalItems={totalItems} onGoToPage={handleGoToPage} page={page} />
          </div>
        </div>
      </div>

      {showDetails.open && (
        <DetailsSlideOver
          open={showDetails.open}
          onClose={() => setShowDetails({ open: false, user: null })}
          title={getDetailsTitle}
        >
          <ClinicUserDetails
            user={showDetails.user}
            onClose={() => setShowDetails({ open: false, user: null })}
            onChange={handleAddOrUpdate}
          />
        </DetailsSlideOver>
      )}
    </>
  );
};

export default ClinicUsers;
