import { RiBlurOffLine } from "@remixicon/react";
import { classNames } from "../../utils/cssUtils";

const swatches = [
  [
    { className: "bg-white", rgb: "rgba(255, 255, 255, 1)", hex: "#FFFFFF" },
    { className: "bg-black", rgb: "rgba(0, 0, 0, 1)", hex: "#000000" },
  ],
  [
    { className: "bg-gray-400", rgb: "rgba(156, 163, 175, 0.7)", hex: "#9CA3AF" },
    { className: "bg-gray-500", rgb: "rgba(107, 114, 128, 0.7)", hex: "#6B7280" },
    { className: "bg-gray-600", rgb: "rgba(75, 85, 99, 0.7)", hex: "#4B5563" },
    { className: "bg-gray-700", rgb: "rgba(55, 65, 81, 0.7)", hex: "#374151" },
  ],
  [
    { className: "bg-red-400", rgb: "rgba(248, 113, 113, 0.7)", hex: "#F87171" },
    { className: "bg-red-500", rgb: "rgba(239, 68, 68, 0.7)", hex: "#EF4444" },
    { className: "bg-red-600", rgb: "rgba(220, 38, 38, 0.7)", hex: "#DC2626" },
    { className: "bg-red-700", rgb: "rgba(185, 28, 28, 0.7)", hex: "#B91C1C" },
  ],
  [
    { className: "bg-yellow-400", rgb: "rgba(250, 204, 21, 0.7)", hex: "#FACC15" },
    { className: "bg-yellow-500", rgb: "rgba(234, 179, 8, 0.7)", hex: "#EAB308" },
    { className: "bg-yellow-600", rgb: "rgba(202, 138, 4, 0.7)", hex: "#CA8A04" },
    { className: "bg-yellow-700", rgb: "rgba(161, 98, 7, 0.7)", hex: "#A16207" },
  ],
  [
    { className: "bg-green-400", rgb: "rgba(74, 222, 128, 0.7)", hex: "#4ADE80" },
    { className: "bg-green-500", rgb: "rgba(34, 197, 94, 0.7)", hex: "#22C55E" },
    { className: "bg-green-600", rgb: "rgba(22, 163, 74, 0.7)", hex: "#16A34A" },
    { className: "bg-green-700", rgb: "rgba(21, 128, 61, 0.7)", hex: "#15803D" },
  ],
  [
    { className: "bg-blue-400", rgb: "rgba(96, 165, 250, 0.7)", hex: "#60A5FA" },
    { className: "bg-blue-500", rgb: "rgba(59, 130, 246, 0.7)", hex: "#3B82F6" },
    { className: "bg-blue-600", rgb: "rgba(37, 99, 235, 0.7)", hex: "#2563EB" },
    { className: "bg-blue-700", rgb: "rgba(29, 78, 216, 0.7)", hex: "#1D4ED8" },
  ],
  [
    { className: "bg-purple-400", rgb: "rgba(192, 132, 252, 0.7)", hex: "#C084FC" },
    { className: "bg-purple-500", rgb: "rgba(168, 85, 247, 0.7)", hex: "#A855F7" },
    { className: "bg-purple-600", rgb: "rgba(147, 51, 234, 0.7)", hex: "#9333EA" },
    { className: "bg-purple-700", rgb: "rgba(126, 34, 206, 0.7)", hex: "#7E22CE" },
  ],
];

const ColorPicker = (props) => {
  const { onChange, active = "#FFFFFF", showReset = false, onReset } = props;

  return (
    <>
      {showReset && (
        <div className="w-full flex items-center pt-3 px-5">
          <div
            className="w-full flex items-center px-2 py-1 text-md font-semibold cursor-pointer hover:ring-2 hover:ring-slate-400"
            onClick={onReset}
          >
            <RiBlurOffLine size={18} />
            <div className="ml-5">None</div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-7 p-2">
        {swatches.map((colorSwatch, idx) => (
          <div key={idx} className="">
            {colorSwatch.map((color) => (
              <div
                key={color.className}
                onClick={() => onChange(color)}
                className={classNames(
                  "cursor-pointer w-6 h-6 rounded-full mx-2 my-2 ring-1 ring-slate-400 hover:ring-2 hover:ring-offset-2 hover:ring-slate-400 hover:shadow-md",
                  color.className
                )}
              >
                {active.toUpperCase() === color.hex && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 absolute text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ColorPicker;
