import { useEffect, useState } from "react";

import { isEmailValid } from "../../utils/validators";

import Input from "../UI/Input";
import PasswordInput from "../UI/PasswordInput";

const UserInfoForm = (props) => {
  const { onSubmit } = props;

  const [email, setEmail] = useState({ value: "", error: false, touched: false });
  const [firstName, setFirstName] = useState({ value: "", error: false, touched: false });
  const [lastName, setLastName] = useState({ value: "", error: false, touched: false });
  const [organization, setOrganization] = useState({ value: "", error: false, touched: false });
  const [password, setPassword] = useState({ value: "", error: false, touched: false });
  const [passwordConfirmation, setPasswordConfirmation] = useState({ value: "", error: false, touched: false });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!organization.touched) return;

    setOrganization((prev) => {
      return {
        ...prev,
        error: prev.value.trim().length < 2,
      };
    });
  }, [organization.touched, organization.value]);

  useEffect(() => {
    if (!firstName.touched) return;

    setFirstName((prev) => {
      return {
        ...prev,
        error: firstName.value.trim().length < 1,
      };
    });
  }, [firstName.touched, firstName.value]);

  useEffect(() => {
    if (!lastName.touched) return;

    setLastName((prev) => {
      return {
        ...prev,
        error: lastName.value.trim().length < 1,
      };
    });
  }, [lastName.touched, lastName.value]);

  useEffect(() => {
    if (!email.touched) return;

    setEmail((prev) => {
      return {
        ...prev,
        error: email.value.trim().length < 1 || !isEmailValid(email.value),
      };
    });
  }, [email.touched, email.value]);

  useEffect(() => {
    if (!password.touched) return;

    setPassword((prev) => {
      return {
        ...prev,
        error: password.value.trim().length < 6 || password.value !== passwordConfirmation.value,
      };
    });
  }, [password.value, password.touched, passwordConfirmation.value]);

  useEffect(() => {
    if (!passwordConfirmation.touched) return;

    setPasswordConfirmation((prev) => {
      return {
        ...prev,
        error: passwordConfirmation.value.trim().length < 6 || password.value !== passwordConfirmation.value,
      };
    });
  }, [password.value, passwordConfirmation.value, passwordConfirmation.touched]);

  useEffect(() => {
    if (
      !organization.touched &&
      !firstName.touched &&
      !lastName.touched &&
      !email.touched &&
      !password.touched &&
      !passwordConfirmation.touched
    ) {
      return;
    }

    setIsFormValid(
      !organization.error &&
        !firstName.error &&
        !lastName.error &&
        !email.error &&
        !password.error &&
        !passwordConfirmation.error
    );
  }, [
    email.error,
    firstName.error,
    lastName.error,
    organization.error,
    passwordConfirmation.error,
    password.error,
    organization.touched,
    firstName.touched,
    lastName.touched,
    email.touched,
    password.touched,
    passwordConfirmation.touched,
  ]);

  const handleSubmit = (response) => {
    onSubmit({
      organization: organization.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      passwordConfirmation: passwordConfirmation.value,
    });
  };

  return (
    <>
      <div className="space-y-6 sm:space-y-5">
        <Input
          type="text"
          name="organization"
          placeholder="Name of your business/practice"
          label="Your business/practice name"
          autoComplete="organization"
          value={organization.value}
          isValid={!organization.error}
          onChange={(val) => setOrganization((prev) => ({ ...prev, value: val }))}
          onBlur={() => setOrganization((prev) => ({ ...prev, touched: true }))}
          errorMessage="Your business/practice name is required"
        />

        <Input
          type="text"
          name="firstName"
          placeholder="First Name"
          label="First Name"
          autoComplete="given-name"
          value={firstName.value}
          isValid={!firstName.error}
          onChange={(val) => setFirstName((prev) => ({ ...prev, value: val }))}
          onBlur={() => setFirstName((prev) => ({ ...prev, touched: true }))}
          errorMessage="First name cannot be blank!"
        />

        <Input
          type="text"
          name="lastName"
          placeholder="Last Name"
          label="Last Name"
          autoComplete="family-name"
          value={lastName.value}
          isValid={!lastName.error}
          onChange={(val) => setLastName((prev) => ({ ...prev, value: val }))}
          onBlur={() => setLastName((prev) => ({ ...prev, touched: true }))}
          errorMessage="Last name cannot be blank!"
        />

        <Input
          type="email"
          name="email"
          placeholder="you@your-clinic.com"
          label="Your Email"
          autoComplete="email"
          value={email.value}
          isValid={!email.error}
          onChange={(val) => setEmail((prev) => ({ ...prev, value: val }))}
          onBlur={() => setEmail((prev) => ({ ...prev, touched: true }))}
          errorMessage="Please provide valid email!"
        />

        <PasswordInput
          name="password"
          label="Password (min 6 characters)"
          autoComplete="new-password"
          value={password.value}
          isValid={!password.error}
          onChange={(val) => setPassword((prev) => ({ ...prev, value: val }))}
          onBlur={() => setPassword((prev) => ({ ...prev, touched: true }))}
          errorMessage="Password has to be at least 6 characters and must match confirmation password!"
        />

        <PasswordInput
          name="passwordConfirm"
          label="Confirm Password"
          autoComplete="new-password"
          value={passwordConfirmation.value}
          isValid={!passwordConfirmation.error}
          onChange={(val) => setPasswordConfirmation((prev) => ({ ...prev, value: val }))}
          onBlur={() => setPasswordConfirmation((prev) => ({ ...prev, touched: true }))}
          errorMessage="Password confirmation has to be at least 6 characters and must match password!"
        />
      </div>
      <div className="flex">
        By clicking Setup Subscription, you agree to our{" "}
        <a href="/terms" className="mx-2 hover:underline text-indigo-800">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="/privacy" className="mx-2 hover:underline text-indigo-800">
          Privacy Statement
        </a>
        .
      </div>
      <div>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={!isFormValid}
          className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 disabled:cursor-not-allowed enabled:text-white disabled:text-slate-500 enabled:bg-indigo-600 disabled:bg-indigo-300 enabled:hover:bg-indigo-500 enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2 enabled:focus-visible:outline-indigo-600"
        >
          Setup Subscription
        </button>
      </div>
    </>
  );
};

export default UserInfoForm;
