/* eslint-disable jsx-a11y/no-redundant-roles */

import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { RiAddCircleLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";
import { HealthGoalContext } from "../../../providers/HealthGoalProvider";

import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";

import EditHealthGoal from "./EditHealthGoal";
import HealthGoalsDetails from "./HealthGoalsDetails";
import HealthGoalListItem from "./HealthGoalListItem";

const HealthGoalList = (props) => {
  const { patientId } = props;

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ show: false, goal: null });

  const { goals, addGoal, removeGoal, updateGoal, bulkAddGoals } = useContext(HealthGoalContext);

  useEffect(() => {
    setIsLoading(true);

    api.client
      .get(`/patients/${patientId}/health_goals`)
      .then((resp) => {
        bulkAddGoals(resp.data.patients_health_goals || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleAdd = (data) => {
    const params = {};
    if (data.health_goal_id) {
      params.health_goal_id = data.health_goal_id;
    } else {
      params.health_goal_id = data.id;
      params.content = data.content;
    }

    api.client
      .post(`/patients/${patientId}/health_goals`, params)
      .then((resp) => {
        if (resp.status === 204) {
          toast.warning("Health goal already added to the patient.");
        } else {
          addGoal(resp.data.patients_health_goal);
          toast.success("Health goal added.");
          // NOTE don't hide the details screen to allow editing multiple
        }
      })
      .catch((resp) => {
        toast.error("Failed to add health goal.");
      });
  };

  const handleUpdate = (data) => {
    api.client
      .put(`/patients/${patientId}/health_goals/${data.id}`, {
        content: data.content,
      })
      .then((resp) => {
        updateGoal(resp.data.patients_health_goal);
        toast.success("Health goal updated.");
        setShowEdit({ show: false, goal: null });
      })
      .catch((resp) => {
        toast.error("Failed to update health goal.");
        setShowEdit({ show: false, goal: null });
      });
  };

  const handleDelete = (id) => {
    api.client
      .delete(`/patients/${patientId}/health_goals/${id}`)
      .then((resp) => {
        removeGoal(id);
        setShowEdit({ show: false, goal: null });
        toast.success("Health goal deleted.");
      })
      .catch((resp) => {
        toast.error("Failed to delete health goal.");
      });
  };

  return (
    <>
      <div className="rounded-lg  overflow-hidden shadow">
        <div className="border-b border-slate-200 py-3 px-5 bg-slate-50">
          <div className="flex items-center justify-between flex-nowrap">
            <div>
              <h3 className="text-lg font-medium leading-6 text-slate-900">Health Goals</h3>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowAdd(true)}
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset hover:ring-2 ring-indigo-300 hover:bg-indigo-50"
              >
                <RiAddCircleLine className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <ul role="list" className="bg-white p-3 gap-x-2 gap-y-2 grid grid-cols-3 overflow-y-auto">
              {goals.map((goal) => (
                <HealthGoalListItem
                  key={goal.id}
                  goal={goal}
                  onDelete={handleDelete}
                  onShowEdit={(goal) => setShowEdit({ show: true, goal })}
                  isDeleting={false}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
      {showAdd && (
        <DetailsSlideOver open={showAdd} onClose={() => setShowAdd(false)} title="Add Health Goal">
          <HealthGoalsDetails onClose={() => setShowAdd(false)} onAdd={handleAdd} />
        </DetailsSlideOver>
      )}
      {showEdit.show && (
        <DetailsSlideOver
          open={showEdit.show}
          onClose={() => setShowEdit({ show: false, goal: null })}
          title="Edit Health Goal"
        >
          <EditHealthGoal
            goal={showEdit.goal}
            onClose={() => setShowEdit({ show: false, goal: null })}
            onChange={handleUpdate}
            onDelete={handleDelete}
          />
        </DetailsSlideOver>
      )}
    </>
  );
};

export default HealthGoalList;
