import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";

import { HealthGoalContext } from "../providers/HealthGoalProvider";

import ActionsRecommendations from "../components/Patients/Dashboard/ActionsRecommendations";
import ScreeningTools from "../components/Patients/Dashboard/ScreeningTools";
import HealthGoalList from "../components/Patients/HealthGoals/HealthGoalList";
import LifestyleScores from "../components/Patients/LifestyleScores/LifestyleScores";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import NotesList from "../components/Patients/Notes/NotesList";
import PatientDetailsHeader from "../components/Patients/Details/PatientDetailsHeader";
import Todo from "../components/Patients/Dashboard/Todo";
import MedicalProblemList from "../components/Patients/MedicalProblems/MedicalProblemList";

const PatientDetailsPage = () => {
  const api = useContext(ApiContext);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState();

  const { bulkAddGoals } = useContext(HealthGoalContext);

  useEffect(() => {
    api.client
      .get(`/patients/${id}`)
      .then((resp) => {
        setPatient(resp.data.patient);
        bulkAddGoals(resp.data.patient.health_goals || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.client, id]);

  const handleUpdateDetails = (details) => {
    setPatient(details);
  };

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="mt-5 mx-5 border rounded-lg shadow bg-white">
            <PatientDetailsHeader patient={patient} onUpdateDetails={handleUpdateDetails} />
          </div>

          <div className="mt-5 mx-5">
            <HealthGoalList patientId={id} />
          </div>

          <div className="mt-5 mx-5">
            <MedicalProblemList patientId={id} />
          </div>

          <div className="mt-5 mx-5">
            <LifestyleScores patientId={id} scores={patient.lifestyle_scores} />
          </div>

          <div className="mt-5 mx-5 flex space-x-5">
            <div className="sm:min-w-[500px] w-full">
              <div className="border rounded-lg shadow bg-white">
                <ActionsRecommendations patientId={id} />
              </div>
              <div className="mt-5 border rounded-lg shadow bg-white">
                <ScreeningTools patientId={id} />
              </div>
            </div>
            <div className="">
              <div className="border rounded-lg shadow bg-white">
                <Todo patientId={id} />
              </div>
              <div className="mt-5 border rounded-lg shadow bg-white">
                <NotesList patientId={id} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientDetailsPage;
