import { useContext, useState } from "react";

import { toast } from "sonner";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { AuthContext } from "../../providers/AuthProvider";
import { ApiContext } from "../../providers/ApiProvider";

import DetailsSlideOver from "../UI/DetailsSlideOver";

import PaymentForm from "../Registration/PaymentForm";
import Modal from "../UI/Modal";
import CancelSubscription from "./CancelSubscription";
import LoadingSpinner from "../UI/LoadingSpinner";

const Subscription = (props) => {
  const { clinic } = props;

  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const handleUpdatePayment = (data) => {
    setIsLoading(true);

    api.client
      .put(`/subscriptions`, { user_id: auth.currentUser().id, zip: data.zip, nonce: data.nonce })
      .then((resp) => {
        setIsLoading(false);
        toast.success("Payment Details Updated. Your next scheduled payment will use the new payment details.");
        setShowPayment(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-5 text-xl font-semibold text-slate-600">Your Plan</div>
        <div className="flex flex-nowrap flex-row space-x-10">
          <div className="w-full overflow-hidden rounded-lg bg-white shadow">
            <div className="flex flex-row px-4 py-5 sm:p-6">
              <div className="flex flex-col w-full">
                <div className="flex flex-row">
                  <div className="w-full text-sm text-slate-600">Monthly Plan</div>
                  <div className="w-full flex place-content-end">
                    {clinic.active ? (
                      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                        <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-rose-100 px-2 py-1 text-xs font-medium text-rose-700">
                        <svg className="h-1.5 w-1.5 fill-rose-500" viewBox="0 0 6 6" aria-hidden="true">
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Canceled
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-5 text-2xl text-slate-900">$10/month</div>
              </div>
            </div>
          </div>

          <div className="w-full overflow-hidden rounded-lg bg-white shadow">
            <div className="flex flex-row px-4 py-5 sm:p-6">
              <div className="flex flex-col w-full">
                <div className="text-sm text-slate-600">Renew On</div>
                <div className="mt-5 text-2xl text-slate-900">{clinic.renews_on}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-10 place-content-end space-x-10">
        <button
          type="button"
          onClick={() => setShowCancel(true)}
          className="rounded-md bg-rose-50 px-3.5 py-2.5 text-sm font-semibold text-rose-600 shadow-sm hover:bg-rose-100"
        >
          Cancel Subscription
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setShowPayment(true)}
        >
          Update Payment Method
        </button>
      </div>
      {showPayment && (
        <DetailsSlideOver
          open={showPayment}
          onClose={() => setShowPayment(false)}
          title="Update Subscription Payment Method"
        >
          {isLoading ? <LoadingSpinner /> : <PaymentForm onSubmit={handleUpdatePayment} />}
        </DetailsSlideOver>
      )}
      {showCancel && (
        <Modal
          open={showCancel}
          onClose={() => setShowCancel(false)}
          title={
            <div className="flex flex-row space-x-3 items-center">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className=" text-red-600">Cancel Subscription</div>
            </div>
          }
        >
          <CancelSubscription renews_on={clinic.renews_on} onCancel={() => setShowCancel(false)} />
        </Modal>
      )}
    </>
  );
};

export default Subscription;
