/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useRef, useState } from "react";

import { toast } from "sonner";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../../providers/ApiProvider";

import Panel from "../UI/Panel/Panel";
import PanelBody from "../UI/Panel/PanelBody";
import LoadingSpinner from "../UI/LoadingSpinner";
import Modal from "../UI/Modal";

const actions = [
  {
    id: 1,
    name: "Bulk Import Patients from CSV",
    label: "Import",
    helpTitle: "CSV File Format",
    help: (
      <>
        <a
          href="/Template_Patient_Import.csv"
          className=" text-lg text-indigo-500 hover:underline hover:text-indigo-800"
        >
          Download template
        </a>
        <div className="mt-5 w-full gap-y-5 divide-slate-200 divide-y prose text-slate-900">
          <div className="px-4 py-5">
            <div>Use the template linked above to fill in the patient details. There are 3 required fields:</div>
            <ul role="list" className="list-disc mt-3">
              <li>First Name</li>
              <li>Last Name</li>
              <li>Email</li>
            </ul>
            <div className="mt-2">
              The rest of the fields are optional. The system will ask the patient to fill in the missing information as
              part of their on-boarding.
            </div>
          </div>

          <div className="px-4 py-5">
            Possible options for the following fields are as follows:
            <div>
              Ethnicity:
              <ul>
                <li>Black</li>
                <li>Asian</li>
                <li>Latino</li>
                <li>White</li>
                <li>Other</li>
              </ul>
            </div>
            <div>
              Sex At Birth:
              <ul>
                <li>Female</li>
                <li>Male</li>
              </ul>
            </div>
            <div>
              Gender:
              <ul>
                <li>Female</li>
                <li>Male</li>
                <li>Non-Binary</li>
                <li>Transgender</li>
                <li>Declined to answer</li>
              </ul>
            </div>
            <div>
              Pronouns:
              <ul>
                <li>She/Her</li>
                <li>He/Him</li>
                <li>They/Them</li>
                <li>He/They</li>
                <li>She/They</li>
                <li>Declined to answer</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    ),
  },
];

const Data = (props) => {
  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, title: "", message: "" });

  const patientFileRef = useRef();

  const onBulkImportPatients = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setIsLoading(true);
      api.client
        .post(
          "/patients",
          { patients: file },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          setIsLoading(false);
          toast.success("We started patient import. You'll get an email when the import is completed");
        })
        .catch(() => {
          setIsLoading(false);
          toast.error("Failed to import patients. Please try again or contact support");
        });
    }
  };

  const handleShowHelp = (item) => {
    setShowModal({ show: true, title: item.helpTitle, message: item.help });
  };

  return (
    <>
      <Panel>
        <PanelBody>
          <ul role="list" className="divide-y divide-gray-100 space-y-3">
            {actions.map((action) => (
              <li key={action.id} className="flex items-center justify-between gap-x-6 py-5 px-5 bg-white rounded-lg">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <span className="text-sm font-semibold leading-6 text-gray-900">{action.name}</span>
                        <QuestionMarkCircleIcon
                          onClick={() => handleShowHelp(action)}
                          className="h-5 w-5 text-slate-700 cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <input
                        type="file"
                        // accept=".csv"
                        onChange={onBulkImportPatients}
                        id="logo-file"
                        name="logo-file"
                        ref={patientFileRef}
                        className="hidden"
                      />
                      <button
                        type="button"
                        onClick={() => patientFileRef.current.click()}
                        className="rounded-md bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {action.label}
                      </button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </PanelBody>
      </Panel>

      {showModal.show && (
        <Modal
          open={showModal.show}
          onClose={() => setShowModal({ show: false, title: "", message: "" })}
          title={showModal.title}
        >
          <div>{showModal.message}</div>
        </Modal>
      )}
    </>
  );
};

export default Data;
