import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";
import { AuthContext } from "../providers/AuthProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import LoginForm from "../components/Auth/LoginForm";
import PasswordInput from "../components/UI/PasswordInput";

const ResetPasswordPage = () => {
  const api = useContext(ApiContext);
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState({ value: "", error: false, touched: false });
  const [passwordConfirmation, setPasswordConfirmation] = useState({ value: "", error: false, touched: false });
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  useEffect(() => {
    if (!password.touched) return;

    setPassword((prev) => {
      return {
        ...prev,
        error: password.value.trim().length < 6 || password.value !== passwordConfirmation.value,
      };
    });
  }, [password.value, password.touched, passwordConfirmation.value]);

  useEffect(() => {
    if (!passwordConfirmation.touched) return;

    setPasswordConfirmation((prev) => {
      return {
        ...prev,
        error: passwordConfirmation.value.trim().length < 6 || password.value !== passwordConfirmation.value,
      };
    });
  }, [password.value, passwordConfirmation.value, passwordConfirmation.touched]);

  useEffect(() => {
    setIsFormValid(!password.error && !passwordConfirmation.error);
  }, [password.error, passwordConfirmation.error]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const accessToken = searchParams.get("access-token");
    const client = searchParams.get("client");
    const uid = searchParams.get("uid");

    api.client
      .put(
        "/auth/password",
        {
          password: passwordRef.current.value,
          password_confirmation: passwordConfirmRef.current.value,
        },
        {
          headers: {
            "access-token": accessToken,
            client: client,
            uid: uid,
          },
        }
      )
      .then((resp) => {
        setShowConfirmMessage(true);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleLogin = (email, pwd) => {
    auth
      .login(email, pwd)
      .then((resp) => {
        navigate("/dashboard", { replace: true });
      })
      .catch((resp) => setIsLoading(false));
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            {showConfirmMessage ? (
              <>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Password changed successfully. Please login to continue.
                  </h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                  <LoginForm onLogin={handleLogin} skipForgotPassword={true} />
                </div>
              </>
            ) : (
              <>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Enter New Password
                  </h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                  <div className="flex min-h-full flex-1 items-center justify-center px-4 py-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-sm space-y-10">
                      <form className="space-y-3" onSubmit={handleSubmit}>
                        <div className="relative -space-y-px rounded-md shadow-sm">
                          <PasswordInput
                            ref={passwordRef}
                            placeholder="New Password"
                            value={password.value}
                            isValid={!password.error}
                            onChange={(val) => setPassword((prev) => ({ ...prev, value: val }))}
                            onBlur={() => setPassword((prev) => ({ ...prev, touched: true }))}
                            errorMessage="Password has to be at least 6 characters and must match confirmation password!"
                          />
                          <PasswordInput
                            ref={passwordConfirmRef}
                            name="passwordConfirmation"
                            placeholder="Confirm Password"
                            value={passwordConfirmation.value}
                            isValid={!passwordConfirmation.error}
                            onChange={(val) => setPasswordConfirmation((prev) => ({ ...prev, value: val }))}
                            onBlur={() => setPasswordConfirmation((prev) => ({ ...prev, touched: true }))}
                            errorMessage="Password confirmation has to be at least 6 characters and must match password!"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ResetPasswordPage;
