import { useState, useEffect } from "react";

import { useAcceptJs } from "react-acceptjs";

import Input from "../UI/Input";
import SelectBox from "../UI/SelectBox";

const authData = {
  apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID,
  clientKey: process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_CLIENT_KEY,
};

const months = [
  { id: 0, name: "--", value: "--" },
  { id: 1, name: "01", value: "01" },
  { id: 2, name: "02", value: "02" },
  { id: 3, name: "03", value: "03" },
  { id: 4, name: "04", value: "04" },
  { id: 5, name: "05", value: "05" },
  { id: 6, name: "06", value: "06" },
  { id: 7, name: "07", value: "07" },
  { id: 8, name: "08", value: "08" },
  { id: 9, name: "09", value: "09" },
  { id: 10, name: "10", value: "10" },
  { id: 11, name: "11", value: "11" },
  { id: 12, name: "12", value: "12" },
];

const years = [
  { id: 0, name: "--", value: "--" },
  { id: 1, name: "2024", value: "24" },
  { id: 2, name: "2025", value: "25" },
  { id: 3, name: "2026", value: "26" },
  { id: 4, name: "2027", value: "27" },
  { id: 5, name: "2028", value: "28" },
  { id: 6, name: "2029", value: "29" },
  { id: 7, name: "2030", value: "30" },
  { id: 8, name: "2031", value: "31" },
  { id: 9, name: "2032", value: "32" },
  { id: 10, name: "2033", value: "33" },
];

const PaymentForm = (props) => {
  const { onSubmit } = props;

  const auth_net_env = process.env.NODE_ENV === "production" ? "PRODUCTION" : "SANDBOX";
  // TODO: check if error and log it, notify user
  const { dispatchData, loading } = useAcceptJs({ environment: auth_net_env, authData });

  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState({ value: "", error: false, touched: false });
  const [cardNumber, setCardNumber] = useState({ value: "", error: false, touched: false });
  const [month, setMonth] = useState(months[0]);
  const [year, setYear] = useState(years[0]);
  const [cvv, setCvv] = useState({ value: "", error: false, touched: false });
  const [zipCode, setZipCode] = useState({ value: "", error: false, touched: false });
  const [showCoupon, setShowCoupon] = useState(false);
  const [coupon, setCoupon] = useState({ value: "", error: false, touched: false });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleCCSubmit = (e) => {
    if (e) e.preventDefault();

    dispatchData({
      cardData: { cardNumber: cardNumber.value, month: month.value, year: year.value, cvv: cvv.value },
    })
      .then((data) => {
        onSubmit({ zip: zipCode.value, nonce: data.opaqueData.dataValue });
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleCouponSubmit = (e) => {
    if (e) e.preventDefault();

    onSubmit({ coupon: coupon.value });
  };

  const handleCoupon = () => {
    setShowCoupon((prev) => !prev);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="mx-5 min-h-10">
            <button
              type="button"
              onClick={handleCoupon}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {showCoupon ? "Pay with Credit Card" : "Use Coupon Code"}
            </button>
          </div>
          <div className="rounded-lg bg-white shadow">
            <div className="px-4 py-5 space-y-5">
              {showCoupon ? (
                <>
                  <Input
                    type="text"
                    name="coupon"
                    placeholder="Coupon Code"
                    label="Coupon Code"
                    value={coupon.value}
                    isValid={!coupon.error}
                    onChange={(val) => setCoupon((prev) => ({ ...prev, value: val }))}
                    onBlur={() => setCoupon((prev) => ({ ...prev, touched: true }))}
                    errorMessage="Coupon cannot be blank!"
                  />

                  <button
                    type="button"
                    onClick={handleCouponSubmit}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Complete Setup
                  </button>
                </>
              ) : (
                <>
                  <Input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    label="Full Name"
                    value={fullName.value}
                    isValid={!fullName.error}
                    onChange={(val) => setFullName((prev) => ({ ...prev, value: val }))}
                    onBlur={() => setFullName((prev) => ({ ...prev, touched: true }))}
                    errorMessage="Full name cannot be blank!"
                  />
                  <Input
                    type="text"
                    name="cardNumber"
                    placeholder="Card Number"
                    label="Card Number"
                    value={cardNumber.value}
                    isValid={!cardNumber.error}
                    onChange={(val) => setCardNumber((prev) => ({ ...prev, value: val }))}
                    onBlur={() => setCardNumber((prev) => ({ ...prev, touched: true }))}
                    errorMessage="Card number cannot be blank!"
                  />

                  <div className="sm:items-start">
                    <label className="block leading-6 text-sm font-medium text-gray-600 mb-2">Expiry Month</label>
                    <div className="mt-2">
                      <SelectBox activeValue={month} values={months} onChange={(val) => setMonth(val)} />
                    </div>
                  </div>

                  <div className="sm:items-start">
                    <label className="block leading-6 text-sm font-medium text-gray-600 mb-2">Expiry Year</label>
                    <div className="mt-2">
                      <SelectBox activeValue={year} values={years} onChange={(val) => setYear(val)} />
                    </div>
                  </div>

                  <Input
                    type="text"
                    name="cvv"
                    placeholder="CVV"
                    label="CVV"
                    value={cvv.value}
                    isValid={!cvv.error}
                    onChange={(val) => setCvv((prev) => ({ ...prev, value: val }))}
                    onBlur={() => setCvv((prev) => ({ ...prev, touched: true }))}
                    errorMessage="CVV cannot be blank!"
                  />

                  <Input
                    type="text"
                    name="zip"
                    placeholder="Zip Code"
                    label="Zip Code"
                    value={zipCode.value}
                    isValid={!zipCode.error}
                    onChange={(val) => setZipCode((prev) => ({ ...prev, value: val }))}
                    onBlur={() => setZipCode((prev) => ({ ...prev, touched: true }))}
                    errorMessage="Zip code cannot be blank!"
                  />

                  <button
                    type="button"
                    onClick={handleCCSubmit}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Complete Setup
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentForm;
