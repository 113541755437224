import { useState, useEffect, useContext } from "react";

import { loadConnectAndInitialize } from "@stripe/connect-js";

import { ApiContext } from "../providers/ApiProvider";

export const useStripeConnect = (connectedAccountId) => {
  const api = useContext(ApiContext);

  const [stripeConnectInstance, setStripeConnectInstance] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!connectedAccountId) return;

    const fetchClientSecret = async () => {
      setIsLoading(true);

      const res = await api.client
        .post("stripe/account_sessions", { account: connectedAccountId })
        .then((resp) => {
          setIsLoading(false);
          return resp.data.client_secret;
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          throw ("An error occurred: ", error);
        });

      return res;
    };

    const instance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#20639b",
        },
      },
    });

    setStripeConnectInstance(instance);
  }, [api.client, connectedAccountId]);

  return { stripeConnectInstance, isLoading };
};

export default useStripeConnect;
