/* eslint-disable jsx-a11y/no-redundant-roles */
const StackedList = (props) => {
  const { items, buildItem } = props;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {items.map((item) => (
          <li key={item.id}>{buildItem(item)}</li>
        ))}
      </ul>
    </div>
  );
};

export default StackedList;
