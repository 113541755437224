import { useEffect, useState } from "react";

import { Field, Label, Switch } from "@headlessui/react";

import Input from "../../UI/Input";
import TextArea from "../../UI/TextArea";
import DateInput from "../../UI/DateInput";
import DividerWithLabel from "../../UI/DividerWIthLabel";
import CancelButton from "../../UI/CancelButton";
import SubmitButton from "../../UI/SubmitButton";

const MedicationDetails = (props) => {
  const { medication, onClose, onSubmit } = props;

  const [form, setForm] = useState({
    name: { value: medication?.name || "", touched: false, valid: true },
    dose: { value: medication?.dose || "", touched: false, valid: true },
    directions: { value: medication?.directions || "", touched: false, valid: true },
    notes: { value: medication?.notes || "", touched: false, valid: true },
    startDate: { value: medication?.started_at || "", touched: false, valid: true },
    endDate: { value: medication?.ended_at || "", touched: false, valid: true },
    active: { value: medication?.active, touched: false, valid: true },
  });
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    if (form.name.touched && form.name.value.trim() === "" && form.name.valid) {
      setForm((prev) => ({ ...prev, name: { ...prev.name, valid: false } }));
      setIsFormValid(false);
    }
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: medication?.id,
      name: form.name.value,
      dose: form.dose.value,
      directions: form.directions.value,
      notes: form.notes.value,
      started_at: form.startDate.value,
      ended_at: form.endDate.value,
      active: form.active.value,
    };

    onSubmit(data);
  };

  return (
    <div className="mx-5 mt-6 space-y-5">
      <Input
        type="text"
        name="name"
        placeholder="Type to search..."
        label="Medication Name (required)"
        value={form.name.value}
        isValid={form.name.valid}
        onChange={(val) => setForm((prev) => ({ ...prev, name: { ...prev.name, value: val, touched: true } }))}
        onBlur={() => setForm((prev) => ({ ...prev, name: { ...prev.name, touched: true } }))}
        errorMessage="Medication name cannot be blank!"
      />

      <Field className="flex items-center">
        <Label as="span" className="mr-3 text-sm">
          <span className="font-medium text-slate-900">Active Rx?</span>
        </Label>
        <Switch
          checked={form.active.value}
          onChange={(val) => setForm((prev) => ({ ...prev, active: { ...prev.active, value: val, touched: true } }))}
          className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-slate-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
          />
        </Switch>
      </Field>

      <Input
        type="text"
        name="dose"
        placeholder="Enter dosage"
        label="Dosage"
        value={form.dose.value}
        onChange={(val) => setForm((prev) => ({ ...prev, dose: { ...prev.dose, value: val, touched: true } }))}
        onBlur={() => setForm((prev) => ({ ...prev, dose: { ...prev.dose, touched: true } }))}
      />

      <TextArea
        name="directions"
        label="Directions"
        value={form.directions.value}
        rows={5}
        onChange={(val) =>
          setForm((prev) => ({ ...prev, directions: { ...prev.directions, value: val, touched: true } }))
        }
      />

      <div className="flex items-center gap-x-10">
        <Field className="flex items-center space-x-3">
          <div className="w-full">Start Date:</div>
          <DateInput
            value={form.startDate.value}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, startDate: { ...prev.startDate, value: e.target.value, touched: true } }))
            }
          />
        </Field>

        <Field className="flex items-center space-x-3">
          <div className="w-full">End Date:</div>
          <DateInput
            value={form.endDate.value}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, endDate: { ...prev.endDate, value: e.target.value, touched: true } }))
            }
          />
        </Field>
      </div>

      <TextArea
        name="notes"
        label="Notes"
        value={form.notes.value}
        rows={5}
        onChange={(val) => setForm((prev) => ({ ...prev, notes: { ...prev.notes, value: val, touched: true } }))}
      />

      <DividerWithLabel />

      <div className="grid grid-flow-col grid-cols-2 gap-x-3">
        <CancelButton onClick={onClose} />
        <SubmitButton isValid={isFormValid} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default MedicationDetails;
