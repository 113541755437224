import { useEffect, useState } from "react";

import Input from "../UI/Input";

const PasswordTab = (props) => {
  const { onCancel, onUpdate } = props;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    setCurrentPasswordError(currentPassword.length < 1);
  }, [currentPassword]);

  useEffect(() => {
    setNewPasswordError(newPassword.length < 1);
  }, [newPassword]);

  useEffect(() => {
    setConfirmPasswordError(
      confirmPassword.length < 1 || confirmPassword !== newPassword
    );
  }, [confirmPassword, newPassword]);

  useEffect(() => {
    setIsFormValid(
      !currentPasswordError && !newPasswordError && !confirmPasswordError
    );
  }, [confirmPasswordError, currentPasswordError, newPasswordError]);

  const handleUpdate = () => {
    const cur = currentPassword;
    const newPwd = newPassword;
    const confPwd = confirmPassword;

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");

    onUpdate(cur, newPwd, confPwd);
  };

  return (
    <>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="mt-6">
          <Input
            type="password"
            name="currentPassword"
            placeholder="Enter current password"
            label="Current Password"
            value={currentPassword}
            isValid={!currentPasswordError}
            onChange={(val) => setCurrentPassword(val)}
            errorMessage="Current password is required!"
          />

          <Input
            type="password"
            name="newPassword"
            placeholder="Enter new password"
            label="New Password"
            value={newPassword}
            isValid={!newPasswordError}
            onChange={(val) => setNewPassword(val)}
            errorMessage="New password is required!"
          />

          <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirm new password"
            label="Confirm New Password"
            value={confirmPassword}
            isValid={!confirmPasswordError}
            onChange={(val) => setConfirmPassword(val)}
            errorMessage="Please enter the new password a second time!"
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onCancel}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleUpdate}
            disabled={!isFormValid}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default PasswordTab;
