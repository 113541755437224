import { useState } from "react";

import { RiDeleteBinFill, RiDeleteBinLine, RiEdit2Fill, RiEdit2Line } from "@remixicon/react";

import { classNames } from "../../../utils/cssUtils";
import { formatDate } from "../../../utils/date";

import DateInput from "../../UI/DateInput";

const ScreeningToolListItem = (props) => {
  const { tool, onDelete, onShowEdit, onLoading, onChangeDueBy } = props;

  const [hoverEdit, setHoverEdit] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);

  const calcProgress = (tool) => {
    const progress = Number(tool.progress);

    if (isNaN(progress)) return 0;

    return progress >= 100 ? 100 : progress;
  };

  const statusBarColor = (tool) => {
    return calcProgress(tool) >= 100 ? "bg-green-600" : "bg-indigo-600";
  };

  return (
    <li className="relative flex flex-col px-2 items-left justify-between hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200">
      {onLoading && (
        <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
          <div className="flex items-center">
            <span className="text-base mr-4 -ml-24">Loading</span>
            <svg
              className="animate-spin h-8 w-8 text-slate-800"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      )}
      <div className="mb-2 text-sm font-semibold leading-6 text-slate-900">{tool.screening_tool.name}</div>
      <div className="flex mb-2 items-center justify-between">
        <div className="min-w-0 space-y-2">
          <div className="max-w-[200px] flex items-center gap-x-2 text-xs leading-5 text-slate-500">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className={classNames(statusBarColor(tool), "w-full h-2.5 rounded-full")}
                style={{ width: `${calcProgress(tool)}%` }}
              ></div>
            </div>
          </div>
          <div className="flex items-center gap-x-2 text-xs leading-5 text-slate-500">
            Due by:
            <DateInput value={formatDate(new Date(tool.due_by))} onChange={(e) => onChangeDueBy(e, tool)} />
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <span
            onMouseEnter={() => setHoverDelete(true)}
            onMouseLeave={() => setHoverDelete(false)}
            onClick={() => onDelete(tool)}
            className="cursor-pointer text-red-800"
          >
            {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
          </span>
          <span
            onMouseEnter={() => setHoverEdit(true)}
            onMouseLeave={() => setHoverEdit(false)}
            onClick={() => onShowEdit(tool)}
            className="cursor-pointer text-indigo-800"
          >
            {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
          </span>
        </div>
      </div>
    </li>
  );
};

export default ScreeningToolListItem;
