const Footer = () => {
  return (
    <footer className="bg-slate-900 mt-5 flex flex-shrink-0 h-24">
      <div className="mx-auto overflow-hidden py-8 px-4 max-w-7xl">
        <p className="mt-3 text-center text-base text-slate-400">
          &copy; 2024 Pro Patient Tech, Inc. All rights reserved. &middot;{" "}
          <a href="/privacy" className="hover:underline">
            Privacy Policy
          </a>{" "}
          &middot;{" "}
          <a href="/terms" className="hover:underline">
            Terms of Service
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
