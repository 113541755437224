/* eslint-disable jsx-a11y/no-redundant-roles */
const ForClinicians = () => {
  return (
    <div className="bg-white pt-12 pb-24" id="clinicians-block">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-3xl sm:text-center">
          <h2 className="text-3xl font-medium tracking-wide text-slate-900">
            Not ready to sign up yet? Here are a few FREE ways you can still benefit from Pro-Patient Tech's offerings:
          </h2>
          <ul className="mt-6 text-lg">
            <li>
              1. List your practice on{" "}
              <a
                href="https://mapper.caspiahealth.com/"
                target="_blank"
                className="font-medium hover:underline"
                rel="noreferrer"
              >
                this mapper
              </a>{" "}
              (for free!).
            </li>
            <li>
              2. Sign your patients up for the{" "}
              <a
                href="https://caspiahealth.com/newsletter"
                target="_blank"
                className="font-medium hover:underline"
                rel="noreferrer"
              >
                Healthy Today Newsletter
              </a>{" "}
              (for free!).
            </li>
            <li>
              3. Stay updated on Lifestyle Medicine topics with a succinct, quarterly email by{" "}
              <a href="#signup-block" className="font-medium hover:underline">
                adding your email here
              </a>{" "}
              (for free!).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ForClinicians;
