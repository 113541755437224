import { useContext, useEffect, useState } from "react";

import { Tooltip } from "react-tooltip";

import { ApiContext } from "../../../providers/ApiProvider";
import { HealthGoalContext } from "../../../providers/HealthGoalProvider";
import { MedicationContext } from "../../../providers/MedicationProvider";
import { MedicalProblemContext } from "../../../providers/MedicalProblemProvider";
import { RecommendationContext } from "../../../providers/RecommendationProvider";
import { ScreeningToolContext } from "../../../providers/ScreeningToolProvider";

import { itemType } from "../../../utils/noteUtils";

import LoadingSpinner from "../../UI/LoadingSpinner";

import ActionListItem from "./ActionListItem";

const ActionList = (props) => {
  const { patientId, note } = props;

  const api = useContext(ApiContext);

  const { goals } = useContext(HealthGoalContext);
  const { recommendations: recommendationData } = useContext(RecommendationContext);
  const { medications: medicationData } = useContext(MedicationContext);
  const { problems: medicalProblemData } = useContext(MedicalProblemContext);
  const { tools } = useContext(ScreeningToolContext);

  const [isLoading, setIsLoading] = useState(true);
  const [healthGoals, setHealthGoals] = useState([]);
  const [medicalProblems, setMedicalProblems] = useState([]);
  const [medications, setMedications] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [screeningTools, setScreeningTools] = useState([]);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!note) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/notes/${note.id}/actions`)
      .then((resp) => {
        setHealthGoals(resp.data.health_goals || []);
        setMedicalProblems(resp.data.medical_problems || []);
        setMedications(resp.data.medications || []);
        setRecommendations(resp.data.recommendations || []);
        setScreeningTools(resp.data.screening_tools || []);

        setShouldRender(
          resp.data.health_goals.length > 0 ||
            resp.data.medical_problems.length > 0 ||
            resp.data.medications.length > 0 ||
            resp.data.recommendations.length > 0 ||
            resp.data.screening_tools.length > 0
        );

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, note, patientId, goals, recommendationData, medicationData, medicalProblemData, tools]);

  const handleDelete = (item) => {
    const type = itemType(item);

    if (type === "screeningTool") {
      setScreeningTools((prev) => prev.filter((i) => i.patient_screening_tool_id !== item.patient_screening_tool_id));
    } else if (type === "medication") {
      setMedications((prev) => prev.filter((i) => i.patients_medication_id !== item.patients_medication_id));
    } else if (type === "medicalProblem") {
      setMedicalProblems((prev) => prev.filter((i) => i.medical_problem_id !== item.medical_problem_id));
    } else if (type === "healthGoal") {
      setHealthGoals((prev) => prev.filter((i) => i.patients_health_goal_id !== item.patients_health_goal_id));
    } else if (type === "recommendation") {
      setRecommendations((prev) =>
        prev.filter((i) => i.patients_recommendation_id !== item.patients_recommendation_id)
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {shouldRender ? (
            <div className="m-5 p-2 border rounded-md border-slate-300 max-h-40 overflow-y-auto">
              <ul className="grid grid-cols-3 gap-2 bg-white">
                {screeningTools.concat(recommendations, medicalProblems, medications, healthGoals).map((item, idx) => (
                  <ActionListItem
                    key={idx}
                    patientId={patientId}
                    noteId={note.id}
                    item={item}
                    onDelete={handleDelete}
                  />
                ))}
              </ul>
              <Tooltip id="action-list-item" />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default ActionList;
