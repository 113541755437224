import { createContext, useCallback, useState } from "react";

const ScreeningToolContext = createContext({
  tools: [],
  addTool: (tool) => {},
  removeTool: (toolId) => {},
  updateTool: (tool) => {},
  bulkAddTools: (data) => {},
});

const ScreeningToolProvider = ({ children }) => {
  const [tools, setTools] = useState([]);

  const addTool = useCallback((tool) => {
    setTools((prev) => {
      return [...prev, tool];
    });
  }, []);

  const removeTool = useCallback((toolId) => {
    setTools((prev) => {
      const res = prev.filter((tool) => tool.id !== toolId);
      return [...res];
    });
  }, []);

  const updateTool = useCallback((tool) => {
    setTools((prev) => {
      const res = prev.map((g) => {
        if (g.id === tool.id) {
          return tool;
        } else {
          return g;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAddTools = useCallback((data) => {
    setTools(data);
  }, []);

  const contextValue = {
    tools,
    addTool: useCallback((tool) => addTool(tool), [addTool]),
    removeTool: useCallback((toolId) => removeTool(toolId), [removeTool]),
    updateTool: useCallback((tool) => updateTool(tool), [updateTool]),
    bulkAddTools: useCallback((data) => bulkAddTools(data), [bulkAddTools]),
  };

  return <ScreeningToolContext.Provider value={contextValue}>{children}</ScreeningToolContext.Provider>;
};

export { ScreeningToolContext, ScreeningToolProvider };
