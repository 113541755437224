import { useContext, useEffect, useState } from "react";

import { RiAddCircleLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";
import { ScreeningToolContext } from "../../../providers/ScreeningToolProvider";

import { classNames } from "../../../utils/cssUtils";

import DetailsSlideOver from "../../UI/DetailsSlideOver";
import LoadingSpinner from "../../UI/LoadingSpinner";
import Modal from "../../UI/Modal";

import AddScreeningTool from "../ScreeningTools/AddScreeningTool";
import Review from "../ScreeningTools/Review";
import ScreeningToolListItem from "../ScreeningTools/ScreeningToolListItem";

const ScreeningTools = (props) => {
  const { patientId } = props;

  const tabs = ["assigned", "completed"];

  const api = useContext(ApiContext);

  const [curTab, setCurTab] = useState(tabs[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [showReview, setShowReview] = useState({ open: false, tool: null });
  const [showAddTool, setShowAddTool] = useState(false);
  const [tools, setTools] = useState([]);
  const [toolLoading, setToolLoading] = useState({});

  const { removeTool, updateTool, bulkAddTools } = useContext(ScreeningToolContext);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/screening_tools`, { params: { status: curTab } })
      .then((resp) => {
        setTools(resp.data.patient_screening_tools);
        bulkAddTools(resp.data.patient_screening_tools);
        resp.data.patient_screening_tools.forEach((tool) => {
          setToolLoading((prev) => ({ ...prev, [tool.id]: false }));
        });
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curTab]);

  const handleCancelTool = (tool) => {
    setIsLoading(true);
    api.client
      .delete(`/patients/${patientId}/screening_tools/${tool.id}`)
      .then((resp) => {
        setTools(tools.filter((t) => t.id !== tool.id));
        removeTool(tool.id);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleToolDueByChange = (e, tool) => {
    setToolLoading((prev) => ({ ...prev, [tool.id]: true }));
    api.client
      .put(`/patients/${patientId}/screening_tools/${tool.id}`, { due_by: e.target.value })
      .then((resp) => {
        setTools(tools.map((t) => (t.id === tool.id ? resp.data.patient_screening_tool : t)));
        updateTool(resp.data.patient_screening_tool);
        setToolLoading((prev) => ({ ...prev, [tool.id]: false }));
      })
      .catch((resp) => {
        setToolLoading((prev) => ({ ...prev, [tool.id]: false }));
      });
  };

  const handleCompleteTool = (tool) => {
    setTools((prev) => {
      const res = prev.filter((t) => t.id !== tool.id);
      return [...res];
    });
    updateTool({ ...tool, status: "completed" });
    setShowReview({ open: false, tool: null });
  };

  return (
    <>
      <div className="flex px-5 py-3 items-center justify-between border-b border-slate-200">
        <div className="flex items-center space-x-3">
          <div>
            <h2 className="text-lg font-medium text-gray-900">Questionnaires</h2>
          </div>
          <div>
            <nav className="flex space-x-2">
              {tabs.map((tab) => (
                <span
                  key={tab}
                  onClick={() => setCurTab(tab)}
                  className={classNames(
                    curTab === tab
                      ? "ring-1 ring-indigo-300 text-indigo-700"
                      : "text-gray-500 hover:ring-1 hover:ring-slate-300",
                    "cursor-pointer rounded-md px-2 py-1 text-sm"
                  )}
                >
                  {tab}
                </span>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="button"
            onClick={() => setShowAddTool(true)}
            className="relative inline-flex items-center gap-x-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
          >
            <RiAddCircleLine className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="m-5">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ul className="divide-y divide-gray-100 max-h-[500px] overflow-y-auto">
            {tools.map((tool) => (
              <ScreeningToolListItem
                tool={tool}
                onLoading={toolLoading[tool.id]}
                key={tool.id}
                onDelete={handleCancelTool}
                onShowEdit={(tool) => setShowReview({ open: true, tool: tool })}
                onChangeDueBy={(e) => handleToolDueByChange(e, tool)}
              />
            ))}
          </ul>
        )}
      </div>
      {showReview.open && (
        <DetailsSlideOver
          open={showReview.open}
          onClose={() => setShowReview({ open: false, tool: null })}
          title={`Review ${showReview.tool?.screening_tool?.name || ""} Results`}
        >
          <Review
            toolId={showReview.tool.id}
            patientId={patientId}
            onComplete={() => handleCompleteTool(showReview.tool)}
          />
        </DetailsSlideOver>
      )}
      {showAddTool && (
        <Modal open={showAddTool} onClose={() => setShowAddTool(false)} title="Add New Tool">
          <AddScreeningTool patientId={patientId} />
        </Modal>
      )}
    </>
  );
};

export default ScreeningTools;
