import { useState } from "react";
import { classNames } from "../../utils/cssUtils";
import { RiCheckboxCircleLine, RiCloseCircleLine, RiLogoutCircleRLine } from "@remixicon/react";

const LinkInput = (props) => {
  const { onChange, onRemove } = props;

  const [didChange, setDidChange] = useState(false);
  const [link, setLink] = useState(props.link || "");

  return (
    <>
      <div className="flex items-center w-full px-3 py-3">
        <div className="relative flex w-full items-stretch focus-within:z-10">
          <input
            type="url"
            placeholder="https://example.com"
            value={link}
            onChange={(e) => {
              setDidChange(true);
              setLink(e.target.value);
            }}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-sm leading-6 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          />
        </div>
        <button
          type="button"
          className={classNames(
            "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-slate-300",
            !!link && !didChange ? "text-red-500" : "text-green-500"
          )}
        >
          {!!link && !didChange ? (
            <RiCloseCircleLine onClick={onRemove} className="-ml-0.5 h-5 w-5" />
          ) : (
            <RiCheckboxCircleLine onClick={() => onChange(link)} className="-ml-0.5 h-5 w-5" />
          )}
        </button>
      </div>
      <div className="flex items-center justify-center pb-2 gap-x-3">
        <RiLogoutCircleRLine className="h-5 w-5 text-slate-500" />
        <a href={link} target="_blank" rel="noreferrer" className="block mt-1 text-sm text-slate-500">
          {link}
        </a>
      </div>
    </>
  );
};

export default LinkInput;
