/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { AuthContext } from "../providers/AuthProvider";

import ErrorFlash from "../components/UI/ErrorFlash";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import LoginForm from "../components/Auth/LoginForm";

const LoginPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const auth = useContext(AuthContext);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);

  const navigateTo = location.state?.from?.pathname || "/dashboard";

  const handleSubmit = (email, password) => {
    setIsLoading(true);

    auth
      .login(email, password)
      .then((resp) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(navigateTo, { replace: true });
      })
      .catch((resp) => {
        setIsLoading(false);
        setShowLoginError(true);
      });
  };

  const handleForgotPassword = (email) => {
    if (email) {
      navigate(`/forgot_password?email=${email}`);
    } else {
      navigate("/forgot_password");
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center items-center space-y-5 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src="/images/logo_text_transparent.png" alt="Pro-Patient Tech" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="max-w-4xl flex justify-center sm:w-full">
          <div className="w-full flex flex-col justify-center">
            <div className="w-full">
              {showLoginError && <ErrorFlash errors={["Invalid email or password. Please try again."]} />}
            </div>
            <div className="w-full">
              <LoginForm
                email={searchParams.get("email")}
                onLogin={handleSubmit}
                onForgotPassword={handleForgotPassword}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
