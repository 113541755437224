import { useContext, useEffect, useState } from "react";

import { format, addMonths, isSameMonth, subMonths, parseISO } from "date-fns";

import { ApiContext } from "../../providers/ApiProvider";

import SmallMonth from "./SmallMonth";
import LoadingSpinner from "../UI/LoadingSpinner";

const RescheduleAppointment = (props) => {
  const { appointment, onClose, onReschedule } = props;

  const api = useContext(ApiContext);

  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [curDate, setCurDate] = useState(appointment?.datetime || new Date());
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(true);
  const [isLoadingTimes, setIsLoadingTimes] = useState(true);
  const [shouldLoadAvailability, setShouldLoadAvailability] = useState(true);
  const [rescheduleTime, setRescheduleTime] = useState(null);

  useEffect(() => {
    if (!shouldLoadAvailability) return;

    setIsLoadingCalendar(true);

    api.client
      .get("/appointments", {
        params: { availability: true, type: appointment?.type, month: format(curDate, "yyyy-MM") },
      })
      .then((resp) => {
        setAvailableDates(resp.data || []);
        setIsLoadingCalendar(false);
        setShouldLoadAvailability(false);
      })
      .catch((resp) => {
        setIsLoadingCalendar(false);
        setShouldLoadAvailability(false);
      });
  }, [api.client, appointment?.type, curDate, shouldLoadAvailability]);

  useEffect(() => {
    setIsLoadingTimes(true);

    api.client
      .get("/appointments", {
        params: { availability: true, type: appointment?.type, day: format(curDate, "yyyy-MM-dd") },
      })
      .then((resp) => {
        setAvailableTimes(() => {
          if (!resp.data) return [];

          return resp.data.map((time) => ({ name: format(parseISO(time), "hh:mm aa"), value: time }));
        });
        setIsLoadingTimes(false);
      })
      .catch((resp) => {
        setIsLoadingTimes(false);
      });
  }, [api.client, appointment?.type, curDate]);

  const handleReschedule = () => {
    onReschedule(rescheduleTime.value);
  };

  const handleNextMonth = () => {
    setCurDate((prev) => addMonths(prev, 1));
    setShouldLoadAvailability(true);
  };

  const handlePrevMonth = () => {
    setCurDate((prev) => subMonths(prev, 1));
    setShouldLoadAvailability(true);
  };

  const handleDateSelect = (date) => {
    setCurDate((prev) => {
      if (isSameMonth(prev, date)) {
        setShouldLoadAvailability(false);
      } else {
        setShouldLoadAvailability(true);
      }

      return date;
    });
  };

  return (
    <>
      <div className="grid grid-cols-2 divide-x divide-gray-200">
        <div className="px-5">
          {isLoadingCalendar ? (
            <LoadingSpinner />
          ) : (
            <SmallMonth
              selectedDay={curDate}
              onNextMonth={handleNextMonth}
              onPrevMonth={handlePrevMonth}
              onDateSelect={handleDateSelect}
              availableDates={availableDates}
            />
          )}
        </div>
        <section className="mt-0 pl-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Availability on <time dateTime="2022-01-21">{format(curDate, "MMMM d, yyyy", new Date())}</time>
          </h2>
          {isLoadingTimes ? (
            <LoadingSpinner />
          ) : (
            <div className="mt-5 overflow-auto max-h-64 space-y-3 p-5 border rounded-lg">
              {availableTimes.map((time) => (
                <div
                  key={time.name}
                  className="flex items-center px-3 hover:bg-indigo-100 hover:ring-1 hover:ring-indigo-200 hover:rounded-lg"
                >
                  <input
                    defaultChecked={time.value === rescheduleTime?.value}
                    id={time.name}
                    name="reschedule-time"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => setRescheduleTime(time)}
                  />
                  <label htmlFor={time.name} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {time.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>

      <div className="mt-5 flex gap-5">
        <button
          type="button"
          className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onClose}
        >
          Keep Current Time
        </button>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
          onClick={handleReschedule}
        >
          Reschedule
        </button>
      </div>
    </>
  );
};

export default RescheduleAppointment;
