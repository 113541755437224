import { getAnswer } from "../../../utils/questionUtils";

const Answers = (props) => {
  const { questions } = props;

  return (
    <ul className="divide-y divide-slate-200">
      {questions.map((question) => (
        <li key={question.id} className="relative flex flex-col items-center px-2 py-2 space-y-2">
          <div className="w-full font-semibold text-slate-900 px-2">{question.content}</div>
          <div className="w-full min-h-10 bg-slate-100 px-3 py-2 rounded-md">{getAnswer(question)}</div>
        </li>
      ))}
    </ul>
  );
};

export default Answers;
